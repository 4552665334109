import { capitalize } from 'lodash';
import moment from 'moment';
import React from 'react';
import { Clipboard } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Cell } from 'react-table';
import { DataTable } from '../../../components';
import { AuthContext } from '../../../context';
import { Booking, UserActivity } from '../../../models/response';
import { ApplicationState } from '../../../store';
import { BookingsThunk } from '../../../store/bookings/bookings.thunk';
import { UsersThunk } from '../../../store/users/users.thunk';
import { BookingStatuses } from '../../../util/constants';
import { AdminDashbboardStyle } from './admin-dashboard';

export interface TopCardLink {
    name: string;
    icon: React.FC<any>;
    count: number;
    color?: string;
}

export const AirportProtocolDashboardPage: React.FC<{}> = (props) => {
    const { users, bookings, auth } = useSelector((state: ApplicationState) => state);
    const { isInAirport } = React.useContext(AuthContext.Context);

    console.log('bookking', bookings);

    const all_bookings = bookings.bookings.filter((booking) => isInAirport(booking.airportId, 'view'));
    const all_activities = (users.userActivities && users.userActivities) || [];
    const sorted_activities = all_activities.sort((a, b) => Date.parse(b.dateCreated) - Date.parse(a.dateCreated));

    const sorted_bookings = all_bookings.sort((a, b) => Date.parse(b.dateCreated) - Date.parse(a.dateCreated));

    const pending_bookings = all_bookings.filter(
        (booking: Booking) => booking.bookingStatus === BookingStatuses.Processing
    );

    const noshow_bookings = all_bookings.filter((booking: Booking) => booking.bookingStatus === BookingStatuses.NoShow);
    const rendered_bookings = all_bookings.filter(
        (booking: Booking) => booking.bookingStatus === BookingStatuses.ServiceRendered
    );

    const latest_bookings = sorted_bookings.slice(0, 6);
    const latest_activities = sorted_activities.slice(0, 6);
    const role = (auth.authUser && auth.authUser.role) || 'Admin';

    const links = [
        {
            name: 'Bookings',
            icon: Clipboard,
            count: all_bookings.length,
            color: '#18A956',
        },
        {
            name: 'Service Bookings',
            icon: Clipboard,
            count: rendered_bookings.length,
            color: '#B1B71C',
        },
        {
            name: 'Processing Bookings',
            icon: Clipboard,
            count: pending_bookings.length,
            color: '#B1B71C',
        },
        {
            name: 'No-Show Bookings',
            icon: Clipboard,
            count: noshow_bookings.length,
            color: '#ED0000',
        },
    ];

    const cards: TopCardLink[] = links;

    const dispatch = useDispatch();
    React.useEffect(() => {
        dispatch(UsersThunk.getAllUsers());
        dispatch(UsersThunk.userActivities(role));
        dispatch(BookingsThunk.list());
    }, [dispatch, role]);

    return (
        <AdminDashbboardStyle>
            <div className="icon-row mt-5 row">
                {cards?.map(({ name, icon: Icon, count, color }, i) => (
                    <div className="icon-row__col mb-3 col" key={i}>
                        <div className="icon-row__item row">
                            <div className="d-flex justify-content-start col-md-10 pt-1">
                                <Icon size={20} className="mr-2" style={{ color }} />
                                <span>{name}</span>
                            </div>
                            <div className="d-flex flex-row-reverse text-right col-md-2">
                                <span style={{ fontSize: '20px', fontWeight: 'bold' }}>{count}</span>
                            </div>
                        </div>
                    </div>
                ))}
                <div className="w-100"></div>
                <div className="col deck-container__deck p-3">
                    <div className="row">
                        <div className="col">
                            <p>Latest Bookings</p>
                        </div>
                        <div className="col d-flex flex-row-reverse"></div>
                    </div>
                    <div>
                        <DataTable
                            hideHead
                            columns={[
                                {
                                    accessor: 'dateCreated',
                                    Cell: ({ row: { original } }: Cell<Booking>) => {
                                        return <b>{capitalize(moment(original.dateCreated).fromNow())}</b>;
                                    },
                                },
                                {
                                    Header: 'Client',
                                    Cell: (cell: Cell<Booking>) => {
                                        const user = cell.row.original.applicationUser;
                                        return user ? (
                                            <span>{user.business_Name || `${user.firstName} ${user.lastName}`}</span>
                                        ) : null;
                                    },
                                },
                                {
                                    Header: 'Status',
                                    Cell: ({ row: { original: booking } }: Cell<Booking>) => {
                                        return <span style={{ color: '#D09611' }}>{booking.bookingStatus}</span>;
                                    },
                                },
                                {
                                    Header: 'Action',
                                    Cell: ({ row: { original: booking } }: Cell<Booking>) => {
                                        return (
                                            <Link
                                                style={{ color: 'var(--primary-color)' }}
                                                to={`/app/booking/${booking.id}`}
                                            >
                                                View
                                            </Link>
                                        );
                                    },
                                },
                            ]}
                            data={latest_bookings}
                        />
                    </div>

                    <p className="text-center">
                        <Link to="/app/bookings" style={{ color: '#000' }}>
                            See all <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                        </Link>
                    </p>
                </div>
                <div className="col deck-container__deck p-3">
                    <div className="row">
                        <div className="col">
                            <p>Latest Activities</p>
                        </div>
                        <div className="col d-flex flex-row-reverse">
                            <p>
                                <Link to="/app/dashboard">
                                    View all <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                                </Link>
                            </p>
                        </div>
                    </div>
                    <div>
                        <DataTable
                            hideHead
                            columns={[
                                {
                                    accessor: 'dateCreated',
                                    Cell: ({ row: { original } }: Cell<UserActivity>) => {
                                        return <b>{capitalize(moment(original.dateCreated).fromNow())}</b>;
                                    },
                                },
                                {
                                    accessor: 'description',
                                },
                            ]}
                            data={latest_activities}
                        />
                        <p className="text-center">
                            <Link to="/app/dashboard" style={{ color: '#000' }}>
                                See all <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
        </AdminDashbboardStyle>
    );
};
