import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Col, TabContent, TabPane } from 'reactstrap';
import { AppLayout, AuthLayout, Breadcrumb, TopBar } from '../../../../components';
import { CreatePassengerBooking } from '../../../../models/request';
import { CreatePorthacourtBooking } from '../../../../models/request';
import { ApplicationState } from '../../../../store';
import { ServiceThunk } from '../../../../store/services/services.thunk';
import {
    CreateBookingInvoice,
    CreateBookingVerificationPage,
    CreatePorthacourtBookingVerificationPage,
} from './components';
import { CreateBookingForm } from './components/create-booking-form';
import { CreateBookingFormP } from './components/create-porthacourt-booking-form';

export enum ProgressSteps {
    DetailsStep,
    VerificationStep,
    invoiceStep,
}

export const CreateBookingPage: React.FC = () => {
    const { service_id, client_id } = useParams<{ service_id: string; client_id: string }>();
    const { serviceDetails: service } = useSelector((state: ApplicationState) => state.services);
    const { services: allServices } = useSelector((state: ApplicationState) => state.services);
    const [booking, setBooking] = React.useState({});

    const dispatch = useDispatch();
    const [passengerBookings, setPassengerBookings] = React.useState<CreatePassengerBooking[]>([]);
    const [porthacourtBooking, setPorthacourtBooking] = React.useState<CreatePorthacourtBooking>(
        new CreatePorthacourtBooking()
    );

    React.useEffect(() => {
        dispatch(ServiceThunk.getService(service_id));
    }, [dispatch, service_id]);

    const selectedService = allServices.find((service) => service.id === parseInt(service_id));
    const portharcourtService = selectedService ? selectedService.portharcourtService : false;
    // React.useEffect(() => {
    //     if (service && !isClientOfType(service.serviceTypeId)) {
    //         dispatch(
    //             NotificationsThunk.create(NotificationTypes.Warning, 'You are not authorized to book this service type')
    //         );

    //         dispatch(ServiceActions.clearService());
    //         history.replace('/app/services');
    //     }
    // }, [dispatch, service, isClientOfType]);

    const breadcrumbs: Breadcrumb[] = [
        { name: 'Dashboard', path: '/app/dashboard' },
        { name: 'All Services', path: '/app/services' },
        {
            name: `${(service && service.name) || ''}`,
            path: `/app/service/${service_id}`,
        },
        {
            name: `Create Booking`,
            path: window.location.href,
        },
    ];

    const [currentStep, setCurrentStep] = React.useState<ProgressSteps>(ProgressSteps.DetailsStep);

    return (
        <AuthLayout showSidebar={false}>
            <TopBar breadcrumbs={breadcrumbs} />
            {service && (
                <React.Fragment>
                    <AppLayout.Wrapper>
                        <AppLayout.Header>
                            <Col lg={8} className="d-flex align-items-center">
                                <AppLayout.HeaderBackButton
                                    showIf={() => currentStep === ProgressSteps.VerificationStep}
                                    action={() => {
                                        if (currentStep === ProgressSteps.VerificationStep) {
                                            setCurrentStep(ProgressSteps.DetailsStep);
                                            return;
                                        }
                                    }}
                                />
                                <h3 className="header__title">{service.name}</h3>
                            </Col>
                        </AppLayout.Header>
                        <div>
                            <TabContent activeTab={currentStep} className="tab__content">
                                <TabPane tabId={ProgressSteps.DetailsStep}>
                                    {portharcourtService ? (
                                        <CreateBookingFormP
                                            service={service}
                                            client={client_id}
                                            setPassengerBooking={setPorthacourtBooking}
                                            proceed={setCurrentStep.bind(undefined, ProgressSteps.VerificationStep)}
                                        />
                                    ) : (
                                        <CreateBookingForm
                                            service={service}
                                            client={client_id}
                                            setPassengerBookings={setPassengerBookings}
                                            proceed={setCurrentStep.bind(undefined, ProgressSteps.VerificationStep)}
                                        />
                                    )}
                                </TabPane>
                                <TabPane tabId={ProgressSteps.VerificationStep}>
                                    {portharcourtService ? (
                                        <CreatePorthacourtBookingVerificationPage
                                            passengerBooking={porthacourtBooking}
                                            client={client_id}
                                            goBack={setCurrentStep.bind(undefined, ProgressSteps.DetailsStep)}
                                            proceed={setCurrentStep.bind(undefined, ProgressSteps.invoiceStep)}
                                            setBooking={setBooking}
                                        />
                                    ) : (
                                        <CreateBookingVerificationPage
                                            passengerBookings={passengerBookings}
                                            client={client_id}
                                            goBack={setCurrentStep.bind(undefined, ProgressSteps.DetailsStep)}
                                            proceed={setCurrentStep.bind(undefined, ProgressSteps.invoiceStep)}
                                            setBooking={setBooking}
                                        />
                                    )}
                                </TabPane>
                                <TabPane tabId={ProgressSteps.invoiceStep}>
                                    <CreateBookingInvoice
                                        goBack={setCurrentStep.bind(undefined, ProgressSteps.DetailsStep)}
                                        booking={booking}
                                    />
                                </TabPane>
                            </TabContent>
                        </div>
                    </AppLayout.Wrapper>
                </React.Fragment>
            )}
        </AuthLayout>
    );
};
