import React from 'react';
import { TabLink } from '../../../../../components';
import { TabBody, TabsBody, TabsHeader, Title } from './tab';
import { BarChart, PieChart } from './chart';
import styled from 'styled-components';
import { SelectDropDown } from './dropdown';
import { generateChartMetadata } from '../../../../../util/misc';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState, ReportinThunk } from '../../../../../store';
import { ReportingInterval } from '../../../../../models/response';

const Pie = styled(TabBody)`
    padding-bottom: 5px;
    .title {
        font-size: 1.5em;
        font-weight: 400;
        line-height: 154.3%;
    }
`;

export const Intervals: any = {
    Daily: 0,
    Monthly: 30,
    Yearly: 365,
};

interface ChartsTableProps {
    topClientsServiceRendered: { name: string; totalRevenue: number }[];
    topClients: { name: string; totalRevenue: number }[];
    airpotIntervals?: ReportingInterval;
    dateFilter: { startDate: string; endDate: string };
}

export const ChartsTable: React.FC<ChartsTableProps> = ({
    topClients,
    topClientsServiceRendered,
    dateFilter,
    airpotIntervals,
}) => {
    const [activeTab, setActiveTab] = React.useState(2);
    const [interval, setInterval] = React.useState<any>('Yearly');

    const { reportingsIntervals } = useSelector((state: ApplicationState) => state.reportings);
    const dispatch = useDispatch();

    const { lebels: topClientsLebels, dataValue: topClientsRevenue } = generateChartMetadata(
        topClients,
        'name',
        'totalRevenue'
    );
    const { lebels: topClientsServiceRenderedLebel, dataValue: topClientsServiceRenderedRevenue } =
        generateChartMetadata(topClientsServiceRendered, 'name', 'totalRevenue');
    const { lebels: airportNames, dataValue: totalBookings } = generateChartMetadata(
        reportingsIntervals?.airportBookings,
        'airportName',
        'totalBookings',
        true
    );

    const { lebels: airportNamesPassengers, dataValue: passengers } = generateChartMetadata(
        reportingsIntervals?.airportPassengers,
        'airportName',
        'passengers',
        true
    );

    const { lebels: airportNamesRevenue, dataValue: revenue } = generateChartMetadata(
        reportingsIntervals?.airportRevenues,
        'airportName',
        'totalRevenue',
        true
    );

    const handleGetReportingStats = React.useCallback(async () => {
        await dispatch(ReportinThunk.getReportingsInterval(dateFilter));
    }, [dispatch, topClients, topClientsServiceRendered, interval]);

    React.useEffect(() => {
        handleGetReportingStats();
    }, [handleGetReportingStats, dateFilter]);

    const tabs: TabLink[] = [
        // { index: 1, name: 'Revenue' },
        { index: 2, name: 'Bookings' },
        { index: 3, name: 'Passenger' },
    ];

    const selectStyle = {
        borderRadius: '5px',
        padding: '5px',
        border: '1px solid #ccc',
        width: '200px',
        marginRight: '10px',
    };

    const options = ['Revenue Generated', 'Service Rendered Bookings'];
    const [selectedOption, setSelectedOption] = React.useState(options[0]);

    const handleOptionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedOption(event.target.value);
    };

    const topsClientLables = topClientsLebels.map((x) => x.toUpperCase());
    const topsClientServiceLebel = topClientsServiceRenderedLebel.map((y) => y.toUpperCase());

    return (
        <div>
            <TabsHeader tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />

            {/* {activeTab === Title.Revenue && (
                <TabsBody interval={interval} setInterval={setInterval}>
                    <BarChart
                        labels={firstFiveAirportNamesRevenue}
                        datas={firstFiveAirporTotalRevenue}
                        title="Revenue"
                    />
                </TabsBody>
            )} */}

            {activeTab === Title.Bookings && (
                <TabsBody interval={interval} setInterval={setInterval}>
                    <BarChart labels={airportNames} datas={totalBookings} title="Bookings" />
                </TabsBody>
            )}

            {/* {activeTab === Title.Registration && (
                <TabsBody>
                    <BarChart labels={labels} datas={[]} title="Registration" />
                </TabsBody>
            )} */}

            {activeTab === Title.Passenger && (
                <TabsBody interval={interval} setInterval={setInterval}>
                    <BarChart labels={airportNamesPassengers} datas={passengers} title="Passenger" />
                </TabsBody>
            )}

            <Pie>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="title pl-5">Top 5 Clients</div>
                    <div className="mt-3">
                        <select value={selectedOption} style={selectStyle} onChange={handleOptionChange}>
                            {options.map((option) => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                {selectedOption === 'Revenue Generated' ? (
                    // Render Pie chart based on revenue data
                    <PieChart labels={topsClientLables} datas={topClientsRevenue} />
                ) : (
                    // Render Pie chart based on service rendered data
                    <PieChart labels={topsClientServiceLebel} datas={topClientsServiceRenderedRevenue} />
                )}
            </Pie>
        </div>
    );
};
