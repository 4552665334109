export enum Roles {
    Admin = 'Admin',
    Commercial = 'Commercial',
    Auditor = 'Auditor',
    Accountant = 'Accountant',
    Client = 'Client',
    AirportManager = 'Airport Manager',
    AirportProtocolCoordinator = 'Airport Protocol Coordinator',
    Protocol = 'Protocol',
}

export const CreateUserRolesMap = {
    [Roles.Admin]: Roles.Admin,
    [Roles.Auditor]: Roles.Auditor,
    [Roles.Accountant]: Roles.Accountant,
    [Roles.AirportManager]: Roles.AirportManager,
    [Roles.AirportProtocolCoordinator]: Roles.AirportProtocolCoordinator,
    [Roles.Commercial]: Roles.Commercial,
};

export const CreateProtocolRoleMap = {
    [Roles.Protocol]: Roles.Protocol,
};

export const RolesMap = {
    ...CreateUserRolesMap,
    [Roles.Client]: Roles.Client,
};

export const NewRoles = [Roles.Accountant, Roles.Auditor, Roles.Commercial];
