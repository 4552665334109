import React, { useContext, useMemo } from 'react';
import { AuthContext } from '../../context';
import { AuthenticatedUser } from '../../models/response';
import { Roles } from '../../util/constants';
import { AccountantDashboard, AdminDashboardPage, ClientDashboardPage } from './pages';

export const DashboardPage = () => {
    const user = useContext(AuthContext.Context).user as AuthenticatedUser;

    const Page = useMemo(() => {
        switch (user.role) {
            case Roles.Accountant:
            case Roles.Auditor:
            case Roles.Commercial:
                return AccountantDashboard;
            case Roles.Admin:
            case Roles.AirportManager:
                return AdminDashboardPage;
            default:
                return ClientDashboardPage;
        }
    }, [user]);

    return <Page />;
};
