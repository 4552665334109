import { Field, useField } from 'formik';
import React from 'react';
import { Eye, EyeOff } from 'react-feather';
import { Tooltip } from 'reactstrap';
import styled from 'styled-components';

export const FormTextInputContainer = styled.label`
    color: black;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 768px) {
        width: 100% !important;
    }

    .form-text__body {
        background: white;
        padding: 5px;
        border: 1px solid #dadada;
        border-radius: 0.5rem;
        padding: calc(1rem - 4px) 1rem;
        display: flex;
        align-items: center;
        position: relative;
        font-size: 1rem;
        line-height: 1;
        width: 100%;
        transition: all 0.25s ease;

        :hover {
            border-color: #aaa;
        }
    }

    .focus {
        border-color: #aaa;
    }

    .form-text__body.valid {
        .form-text__icon {
            color: var(--primary-color);
        }
    }

    .form-text__body.invalid {
        .form-text__icon {
            color: #e03225;
        }
    }

    .form-text__label {
        display: flex;
        font-size: 14px;
        margin-bottom: 3px;
    }

    .form-text__label::after {
        content: ' *';
        color: red;
    }

    .form-text__icon-container {
        width: calc(1rem + 2px);
        height: calc(1rem + 2px);
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 1rem;
    }

    .form-text__eye-container {
        width: calc(1rem + 2px);
        height: calc(1rem + 2px);
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
        cursor: pointer;
    }

    .form-text__validation-text {
        font-size: 13px;
        padding: 5px 0;
        color: #f44336;
        width: 100%;
        display: block;
    }

    .form-text__icon {
        width: calc(1rem);
        height: calc(1rem);
        color: black;
        stroke-width: 2.25px;
    }

    .form-text__input {
        appearance: none;
        padding: 0;
        margin: 0;
        border: none;
        font-family: inherit;
        flex: 1;
        outline: none;
        height: 24px;
    }

    textarea {
        height: 86px;
        min-height: 86px;
        max-height: 86px;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        outline: none;
    }

    &.compact {
        width: 100%;
        max-width: unset;

        .form-text__body {
            max-width: 100%;
            min-height: 43px;
        }

        .form-text__input {
            height: 20px;
            font-size: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        select.form-text__input {
            width: 100%;
        }

        .form-text__body.valid {
            border-color: var(--primary-color);
        }

        .form-text__body.invalid {
            border-color: #e03225;
        }
    }

    &.wide {
        width: 300px;
    }
`;

export const SpecialInput = styled(Field)`
    background: white;
`;

export interface FormTextInputProps {
    className?: string;
    name: string;
    placeholder?: string;
    label?: string;
    type?: 'number' | 'text' | 'email' | 'password' | 'date' | 'time';
    as?: any;
    disabled?: boolean;
    icon?: React.FC<React.ComponentProps<'svg'>>;
    compact?: boolean;
    inputProps?: React.ComponentPropsWithoutRef<'input'>;
    toggleField?: boolean;
    currency?: boolean;
    handleChange?: any;
    amount?: any;
    special?: boolean;
    startDate?: any;
    value?: number;
}

export const FormTextInput: React.FC<FormTextInputProps> = ({
    name,
    placeholder,
    label,
    type,
    as,
    children,
    disabled,
    icon: Icon,
    compact,
    inputProps,
    toggleField,
    currency,
    handleChange,
    amount,
    startDate,
    special,
    value,
    ...props
}) => {
    const [field, meta] = useField({ name });
    const className = compact ? 'compact ' + (props.className || '') : props.className;
    const [fieldType, setFieldType] = React.useState(type);

    const validState = meta.touched ? (meta.error ? false : true) : null;
    const [showPassword, setShowPassword] = React.useState(false);
    const validationClass = validState === null ? '' : validState ? ' valid' : ' invalid';
    const validationProps = { [validationClass]: true };
    const EyeIcon = showPassword ? Eye : EyeOff;

    const id = name;
    const [tooltipOpen, setTooltipOpen] = React.useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);

    const togglePassword = React.useCallback(() => {
        const value = fieldType === 'password' ? 'text' : 'password';
        setFieldType(value);
        setShowPassword(!showPassword);
    }, [fieldType, showPassword]);

    const ref = React.useRef<HTMLInputElement>(null);
    const [isFocused, setIsFocused] = React.useState(false);
    const focusClass = React.useMemo(() => (isFocused ? ' focus' : ''), [isFocused]);

    React.useEffect(() => {
        if (ref.current) {
            ref.current.onfocus = () => {
                setIsFocused(true);
            };

            ref.current.onblur = () => {
                setIsFocused(false);
            };
        }
    }, [ref]);

    return (
        <FormTextInputContainer {...props} className={className}>
            {label && <span className="form-text__label">{label}</span>}

            <div className={`form-text__body` + validationClass + focusClass} id={id}>
                {Icon ? (
                    <section className="form-text__icon-container">
                        <Icon className="form-text__icon" />
                    </section>
                ) : null}

                {!currency && (
                    <SpecialInput
                        className="form-text__input"
                        placeholder={placeholder}
                        type={fieldType}
                        as={as}
                        children={children}
                        disabled={disabled}
                        {...inputProps}
                        ref={ref}
                        {...field}
                        {...validationProps}
                        value={value || field.value}
                    />
                )}
                {currency && (
                    <SpecialInput
                        className="form-text__input"
                        placeholder={0}
                        type={type}
                        as={as}
                        children={children}
                        onChange={handleChange}
                        {...inputProps}
                        ref={ref}
                        value={amount || startDate || value}
                        // {...field}
                        {...validationProps}
                    />
                )}
                {/* {as === 'select' ? (
                    <section className="form-text__eye-container">
                        <FiChevronDown />
                    </section>
                ) : null} */}

                {toggleField ? (
                    <section className="form-text__eye-container" onClick={togglePassword}>
                        <EyeIcon />
                    </section>
                ) : null}
            </div>
            {validState === false ? (
                <React.Fragment>
                    <Tooltip placement="right" isOpen={tooltipOpen} target={id} toggle={toggle}>
                        <span className="form-text__validation-text">{meta.error}</span>
                    </Tooltip>
                    <span className="form-text__validation-text">{meta.error}</span>
                </React.Fragment>
            ) : null}
        </FormTextInputContainer>
    );
};
