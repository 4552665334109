import React from 'react';
import { ArrowLeft } from 'react-feather';
import styled from 'styled-components';

interface FormBackButtonProps {
    currentStep: number;
    setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
}

export const Button = styled.button`
    border: none;
    background: transparent;
    font-size: 18px;
    outline: none !important;
    appearance: none;
    letter-spacing: 0px;
    color: #18a956;
    opacity: 1;

    &:hover {
        background-color: #edf3ed;
        border-radius: 7px;
    }

    &:disabled {
        cursor: not-allowed;
    }

    * {
        color: var(--primary-color) !important;
    }
`;

export const FormBackButton: React.FC<FormBackButtonProps> = ({ currentStep, setCurrentStep }) => {
    return currentStep > 1 ? (
        <Button className="form__back-button" type="button" onClick={setCurrentStep.bind(undefined, currentStep - 1)}>
            <ArrowLeft size={16} />
            <span>Back</span>
        </Button>
    ) : null;
};
