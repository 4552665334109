import style from 'styled-components';

export const RegistrationRequestStyleWrapper = style.div`
    .titleBox {
        button {
            background-color: #18A956;
            color: #fff;
            border: 0;
        }

        h3 {
            margin: 0;
            font-weight: bold;
        }

        p {
            color: #A5A5A5;
            margin-top: 0;
        }
    }

    .contentBox {
        background: #FFFFFF;
        border: 1px solid #DADADA;
        border-radius: 7px;
        margin-top: 1rem;
        height: 100%;
        padding: 1.5rem 2rem 1rem 2rem;

        h4 {
            font-size: 14px;
            text-transform: uppercase;
            color: #c5c5c5;
            margin: 0;
        }

        h3 {
            font-weight: bold;
            font-size: 24px;
        }

        p {
            margin-top: 1rem;
        }

        .singleBtn {
            &:hover {
                background-color: #fff;
                color: #18A956;
            }
        }

        .downloadBox {
            display: flex;
            flex-direction: row;

            .contentWrapper {
                display: flex;
                flex-direction: column;
                flex: 2;

                p {
                    margin: 0;
                    font-weight: bold;
                    font-size: 13px;
                }

                span {
                    color: #c5c5c5;
                }
            }

            .iconBox {
                flex: 0.3;
                padding-top: 7px;
            }

            .linkBox {
                flex: 0.8;
                padding-top: 12px;
            }
        }

        .btnBox {
            margin-top: 2rem;
        }
    }
`;
