import queryString from 'query-string';
import React from 'react';
import { Edit3 } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { TabContent, TabPane } from 'reactstrap';
import styled from 'styled-components';
import { AppLayout, AuthLayout, CustomPrimaryButton, TabLink, TopBar } from '../../../../components';
import { Breadcrumb } from '../../../../components/breadcrumbs/breadcrumbs';
import { AuthContext } from '../../../../context';
import { ApplicationState } from '../../../../store';
import { NotificationsThunk } from '../../../../store/notifications/notifications.thunk';
import { ServiceThunk } from '../../../../store/services/services.thunk';
import { NotificationTypes, Roles } from '../../../../util/constants';
import { history } from '../../../../util/history';
import { BookingsTable } from '../../bookings/list-bookings/bookings-table';
import { UpdateServiceModal } from '../modals/update-service-modal';
import { ServiceInformationComponent } from './view-service.style';

const HeaderStyleWrapper = styled.div`
    display: flex;
    flex-direction: row;

    .left__header {
        flex: 1;
        display: flex;
        flex-direction: row;
    }

    .right {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
`;

export const ViewServicePage: React.FC = (props) => {
    const { service_id: id } = useParams<any>();
    const {
        serviceDetails: service,
        serviceBookings: bookings,
        serviceBookingsMeta: meta,
    } = useSelector((state: ApplicationState) => state.services);
    const { canAccess, isClientOfType } = React.useContext(AuthContext.Context);

    const dispatch = useDispatch();
    const location = useLocation();
    const pageNumber = React.useMemo(() => +(queryString.parse(location.search) as any).page || 1, [location.search]);

    React.useEffect(() => {
        dispatch(ServiceThunk.getService(id));
    }, [dispatch, id]);

    React.useEffect(() => {
        if (service && !isClientOfType(service.serviceTypeId)) {
            dispatch(
                NotificationsThunk.create(
                    NotificationTypes.Warning,
                    'You are not authorized to access this service type'
                )
            );

            history.replace('/app/services');
        }
    }, [dispatch, service, isClientOfType]);

    React.useEffect(() => {
        if (service && service.id === +id) {
            dispatch(ServiceThunk.listBookings(service.id, pageNumber));
        }
    }, [service, id, pageNumber, dispatch]);

    const breadcrumbs: Breadcrumb[] = [
        { name: 'Dashboard', path: '/app/dashboard' },
        { name: 'All Services', path: '/app/services' },
        {
            name: service?.name ?? '',
            path: window.location.href,
        },
    ];

    const [updateModalOpen, setUpdateModalOpen] = React.useState(false);

    const links: TabLink[] = [
        { index: 1, name: 'Information' },
        { index: 2, name: 'Bookings' },
    ];

    const [activeTab, setActiveTab] = React.useState(location.state && location.state.intent === 'bookings' ? 2 : 1);

    return (
        <AuthLayout showSidebar={false}>
            <TopBar breadcrumbs={breadcrumbs} />
            {service && (
                <React.Fragment>
                    <UpdateServiceModal isOpen={updateModalOpen} setIsOpen={setUpdateModalOpen} service={service} />
                    <AppLayout.Wrapper>
                        <AppLayout.Header>
                            <HeaderStyleWrapper>
                                <div className="left__header">
                                    <AppLayout.HeaderBackButton />
                                    <h3 className="header__title">{service.name}</h3>
                                </div>
                                {canAccess(Roles.Admin) && (
                                    <div className="right">
                                        <CustomPrimaryButton onClick={setUpdateModalOpen.bind(undefined, true)}>
                                            <Edit3 size={14} strokeWidth={3} />
                                            <span>Edit Service</span>
                                        </CustomPrimaryButton>
                                    </div>
                                )}
                            </HeaderStyleWrapper>
                        </AppLayout.Header>
                        <AppLayout.Body>
                            <AppLayout.TabHeader links={links} activeTab={activeTab} setActiveTab={setActiveTab} />
                            <AppLayout.TabBody>
                                <TabContent activeTab={activeTab} className="tab__content">
                                    <TabPane tabId={1}>
                                        <ServiceInformationComponent service={service} />
                                    </TabPane>
                                    <TabPane tabId={2}>
                                        {bookings?.length ? (
                                            <BookingsTable
                                                bookings={bookings}
                                                meta={meta}
                                                refreshFunction={() => dispatch(ServiceThunk.getService(id))}
                                                compact
                                            />
                                        ) : (
                                            <span>This service has not been booked</span>
                                        )}
                                    </TabPane>
                                </TabContent>
                            </AppLayout.TabBody>
                        </AppLayout.Body>
                    </AppLayout.Wrapper>
                </React.Fragment>
            )}
        </AuthLayout>
    );
};
