import { airportsReducer, AirportsState } from './airports';
import { authReducer, AuthState } from './auth';
import { bookingsReducer, BookingsState, PBookingsState, pbookingsReducer } from './bookings';
import { loadingReducer, LoadingState } from './loading';
import { notificationsReducer, NotificationsState } from './notifications';
import { serviceReducer, ServiceState } from './services';
import { transactionsReducer, TransactionsState } from './transactions';
import { usersReducer, UsersState } from './users';
import { ReportingState, reportingReducer } from './reporting';
import { VcardState, vcardReducer } from './vcard';
import { feedbackReducer, FeedbackState } from './feedback';

export * from './airports';
export * from './auth';
export * from './bookings';
export * from './loading';
export * from './notifications';
export * from './services';
export * from './transactions';
export * from './users';
export * from './reporting';
export * from './vcard';

// The top-level state object
export interface ApplicationState {
    auth: AuthState;
    airports: AirportsState;
    users: UsersState;
    services: ServiceState;
    bookings: BookingsState;
    loading: LoadingState;
    notifications: NotificationsState;
    transactions: TransactionsState;
    reportings: ReportingState;
    vcard: VcardState;
    booking: PBookingsState;
    feedbacks: FeedbackState;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    auth: authReducer,
    airports: airportsReducer,
    users: usersReducer,
    services: serviceReducer,
    bookings: bookingsReducer,
    loading: loadingReducer,
    notifications: notificationsReducer,
    transactions: transactionsReducer,
    reportings: reportingReducer,
    vcard: vcardReducer,
    booking: pbookingsReducer,
    feedbacks: feedbackReducer,
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export type AppThunkAction<TAction> = (dispatch: (action: TAction) => void, getState: () => ApplicationState) => void;
