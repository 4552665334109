import {
    ChangeUserStatusModel,
    CreateProtocolModel,
    CreateUserModel,
    EditUserModel,
    EditUserProfileModel,
    UpdateClientModel,
    UpdateIndividualClientModel,
} from '../models/request';
import { BookingServicesStat, ClientStats, User, UserActivity } from '../models/response';
import { ApiClient, QueryParams } from '../util/api-client';
import { ClientStatuses, Roles } from '../util/constants';

export class UsersService {
    public static async getDashboardStats() {
        return ApiClient.get<any>('users/dashboard-stats');
    }

    public static async createGovernmentOfficial(userDetails: FormData) {
        return ApiClient.post<FormData, User>('users/government', userDetails, true);
    }

    public static async createCorporateOrganization(userDetails: FormData) {
        return ApiClient.post<FormData, User>('users/corporate', userDetails, true);
    }

    public static async createIndividual(userDetails: FormData) {
        return ApiClient.post<FormData, User>('users/individual', userDetails, true);
    }

    public static async createUser(userDetails: CreateUserModel | CreateProtocolModel) {
        return ApiClient.post<CreateUserModel | CreateProtocolModel, User>('users/users', userDetails);
    }

    public static async getClients(filter?: ClientStatuses, page?: number, searchTerms?: string) {
        const query = page ? `?pageNumber=${page}` : '';
        const endpoint = filter ? `clientByStatus/${filter}` : 'clients';
        const path = searchTerms ? `search/${searchTerms}` : endpoint;

        return ApiClient.get<User[]>('users/' + path + query);
    }

    public static async getClientBookingStats() {
        return ApiClient.get<BookingServicesStat>('booking-services/stats');
    }

    public static async getClientStats() {
        return ApiClient.get<ClientStats>('users/status-stats');
    }

    public static async getUsers(page?: number, searchTerm?: string) {
        const query = page ? `?pageNumber=${page}` : '';
        const path = searchTerm ? `search/users/faan/${searchTerm}` : 'users';
        return ApiClient.get<User[]>('users/' + path + query);
    }

    public static async getProtocols(page?: number, searchTerm?: string) {
        const query = page ? `?pageNumber=${page}` : '';
        const endpoint = 'protocols/';
        const path = searchTerm ? `search/${searchTerm}` : '';
        return ApiClient.get<User[]>('users/' + endpoint + path + query);
    }

    public static async getAllProtocols() {
        return ApiClient.get<User[]>('users/all/protocols');
    }

    public static async changeUserStatus(userDetails: ChangeUserStatusModel) {
        return ApiClient.patch<ChangeUserStatusModel, User>('users/activation', userDetails);
    }

    public static async viewUser(user_id: string | undefined) {
        return ApiClient.get<[]>(`users/users/${user_id}`);
    }

    public static async getUserBooking(user_id: string | undefined, page?: number) {
        return ApiClient.get<[]>(`booking-services/client/${user_id}?pageNumber=${page}`);
    }

    public static async updateUser(data: EditUserModel) {
        return ApiClient.patch<EditUserModel, User>('users/user', data);
    }

    public static async updateUserProfile(data: EditUserProfileModel) {
        return ApiClient.patch<EditUserProfileModel, User>('users/faan-user', data);
    }

    public static async updateClient(data: UpdateClientModel, type?: number) {
        return ApiClient.patch<UpdateClientModel, User>(`${type === 1 ? 'users/corporate' : 'users/government'}`, data);
    }

    public static async updateIndividualClient(data: UpdateIndividualClientModel) {
        return ApiClient.patch<UpdateIndividualClientModel, User>('users/individual', data);
    }

    public static async userActivities(role: string, query?: QueryParams) {
        if (role === Roles.Admin) {
            return ApiClient.get<UserActivity[]>(`activity/all-activities`, query);
        }

        return ApiClient.get<UserActivity[]>(`activity/client`);
    }
}
