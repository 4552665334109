import { ApiClient } from '../util/api-client';

export class ReportingService {
    public static async getReporting(dateFilter?: { startDate: string; endDate: string }) {
        return ApiClient.get<any>('users/reporting-stats', dateFilter);
    }

    public static async getReportinginterval(interval: { startDate: string; endDate: string }) {
        return ApiClient.get<any>(`users/reporting-stats/airports`, interval);
    }
}
