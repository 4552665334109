import { find } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Check, Info, X } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { Cell } from 'react-table';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { AppLayout, CustomPrimaryButton, DataTable, FundWalletModal } from '../../../../../components';
import { CreateBookingModel, CreatePassengerBooking, CreatePorthacourtBooking } from '../../../../../models/request';
import { BookingsService, TransactionsService } from '../../../../../services';
import { ApplicationState, NotificationsThunk } from '../../../../../store';
import { BookingsThunk } from '../../../../../store/bookings/bookings.thunk';
import { PBookingsThunk } from '../../../../../store/bookings/pbooking.thunk';
import { formatCurrency, formatDate, formatTime } from '../../../../../util/misc/formatters';
import { PresentationalFormRadio } from '../../../../../components/form';
import Card from '../../../../../assets/images/card.png';

interface Props {
    passengerBooking: CreatePorthacourtBooking;
    goBack: () => void;
    proceed: () => void;
    client: string;
    setBooking: (booking: any) => void;
}

const InsufficientFundsWrapper = styled.div`
    width: 100%;
    font-size: 0.85rem;
    padding: 0.75em;
    color: #f4f4f4;
    background-color: rgb(247, 123, 114);
    font-weight: 500;
    display: flex;
    align-items: center;
    border-radius: 8px;
    justify-content: space-between;

    button {
        background-color: #f4f4f4;
        color: rgb(247, 123, 114);
        border: none;
        padding: 0.5em 1em;
        border-radius: 8px;
        font-weight: 700;
        transition: color 0.5s ease-in;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        &:hover {
            color: rgba(247, 123, 114, 0.863);
            background-color: #f4f4f4cc;
        }
    }
`;

export const CreatePorthacourtBookingVerificationPage: React.FC<Props> = ({
    passengerBooking,
    goBack,
    client,
    proceed,
    setBooking,
}) => {
    const [isFundingWallet, setFundingWallet] = useState(false);
    const [balance, setBalance] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [formData, setFormData] = useState<CreatePorthacourtBooking>(new CreatePorthacourtBooking());
    const params: any = useParams();

    const { services: service } = useSelector((state: ApplicationState) => state);

    const defaultODCValue = service.serviceDetails?.defaultOdc ?? 0;
    const { airports } = useSelector((state: ApplicationState) => state.airports);
    const addODCValue = passengerBooking.additionalOdc ?? 0;

    const dispatch = useDispatch();

    const handleCreateSingleBooking = async () => {
        const booking = passengerBooking;

        setBooking(booking);
        try {
            const data: any = await dispatch(PBookingsThunk.createporthacourtbooking(booking, client));
            console.log('Data Sent', data);
            await setBooking(data); // Update the booking with the response data
        } catch (error) {
            console.error('Error creating single booking:', error);
        }
    };

    const handledleConfirmBooking = async () => {
        await handleCreateSingleBooking();
        await proceed();
    };

    useEffect(() => {
        TransactionsService.getWalletBalance(client)
            .then(({ data }) => {
                setBalance(data?.balance.balance ?? 0);
            })
            .catch((err) => console.log({ err }));
    }, []);

    const calculateDefaultODCValue = (numberOfODC: number): number => {
        let totalODCPrice = 0;

        if (numberOfODC > 0) {
            totalODCPrice = 200000; // Initial price for the first ODC
            if (numberOfODC > 1) {
                totalODCPrice += (numberOfODC - 1) * 300000; // Adding 300000 for each additional ODC
            }
        }

        return totalODCPrice;
    };

    const initialprice = service.serviceDetails?.price ?? 0;
    const tprice = calculateDefaultODCValue(addODCValue);
    const totalPrice = initialprice + tprice;
    return (
        <React.Fragment>
            {/* Booking Verification Page */}
            {balance < totalPrice && (
                <InsufficientFundsWrapper className="my-3">
                    {isFundingWallet && (
                        <FundWalletModal
                            onComplete={(value) => setBalance((prev) => prev + value)}
                            isOpen={isFundingWallet}
                            isCreatingBooking
                            setIsOpen={setFundingWallet}
                            client={client}
                        />
                    )}
                    <span>
                        You do not have enough balance to complete this process, Please Fund your wallet or use the Pay
                        with Card option below
                    </span>

                    <button onClick={() => setFundingWallet(true)}>Fund wallet</button>
                </InsufficientFundsWrapper>
            )}
            <AppLayout.Body>
                <AppLayout.TabHeader>
                    <div className="my-4 d-flex justify-content-between">
                        <span>Kindly review and confirm your Service booking details</span>
                        <b>Passenger(s)</b>
                    </div>
                </AppLayout.TabHeader>
                <div className="tab__content__row mb-5">
                    <DataTable
                        columns={[
                            {
                                Header: 'S/N',
                                Cell: (cell: Cell<CreatePorthacourtBooking>) => cell.row.index + 1,
                            },
                            // {
                            //     Header: 'Service Type',
                            //     accessor: (row: CreatePorthacourtBooking) => row.Service?.serviceTypeId ?? '',
                            //     Cell: ({ row: { original } }: any) => ClientTypesMap[original.serviceTypeId as ClientTypes],
                            // },

                            {
                                Header: 'Airport',
                                accessor: 'AirportId',
                                Cell: ({ row: { original: booking } }: Cell<CreatePorthacourtBooking>) => (
                                    <span>{find(airports, { id: booking.AirportId })?.name}</span>
                                ),
                            },

                            // {
                            //     Header: 'Default ODC',
                            //     accessor: (values: CreatePorthacourtBooking) => values.defaultOdc,
                            //     // Render the value within the table cell
                            //     Cell: ({ value }: { value: number }) => <span>{4}</span>,
                            // },
                            {
                                Header: 'Additional ODC.',
                                accessor: 'additionalOdc',
                            },
                            {
                                Header: 'Booking date',
                                // accessor: 'Date_Of_Travel',
                                // Cell: ({ row: { original: passenger } }: Cell<CreatePassengerBooking>) =>
                                //     formatDate(passenger.Date_Of_Travel),
                            },
                        ]}
                        data={[passengerBooking]}
                    />
                    <div className="d-flex mt-3 mb-5 justify-content-end">
                        <b>Total Price {formatCurrency(totalPrice)}</b>
                    </div>
                    <div className="d-flex mt-4 justify-content-end">
                        <div>
                            <CustomPrimaryButton className="inverted" onClick={goBack}>
                                <span>Edit Details</span>
                            </CustomPrimaryButton>
                            {balance >= totalPrice && (
                                <CustomPrimaryButton onClick={handleCreateSingleBooking}>
                                    <span>Confirm</span>
                                </CustomPrimaryButton>
                            )}
                        </div>
                    </div>
                </div>
            </AppLayout.Body>
            {/* {balance < totalPrice && (
                <div className="mt-5">
                    <div>
                        <b>Alternative Method</b>
                    </div>
                    <div className="mt-3">
                        <div
                            style={{ maxWidth: '300px' }}
                            className="d-flex align-items-center justify-content-between"
                        >
                            <div>
                                <PresentationalFormRadio checked size="medium" />
                            </div>
                            <div className="d-flex align-items-center">
                                <img width={130} height={50} src={Card} />
                                <div className="pl-3">Pay with Card</div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-5">
                        <CustomPrimaryButton onClick={handledleConfirmBooking}>
                            <span>Confirm</span>
                        </CustomPrimaryButton>
                    </div>
                </div>
            )} */}
        </React.Fragment>
    );
};
