import { filter } from 'lodash';
import { Field, Formik } from 'formik';
import queryString from 'query-string';
import ReactDOM from 'react-dom';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useLocation } from 'react-router';
import { saveAs } from 'file-saver';
import { Col, TabContent, TabPane } from 'reactstrap';
import { AppLayout, AuthLayout, Breadcrumb, TabLink, TopBar } from '../../../../components';
import { AuthContext } from '../../../../context';
import { Booking } from '../../../../models/response';
import { ApplicationState, NotificationsThunk } from '../../../../store';
import { BookingsThunk } from '../../../../store/bookings/bookings.thunk';
import { NotificationTypes, Roles } from '../../../../util/constants';
import { BookingsTable } from './bookings-table';
import { Button } from '../../../../components/form';
import { setSearch } from '../../../../util/history';
import { ApiClient } from '../../../../util/api-client';

const HeaderStyleWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > form {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        gap: 20px;
        padding: 0;

        label {
            display: flex;
            font-weight: 500;
            flex-direction: column;
        }
    }
`;

export const BookingsPage: React.FC = () => {
    const breadcrumbs: Breadcrumb[] = [
        { name: 'Dashboard', path: '/app/dashboard' },
        { name: 'All Bookings', path: window.location.href },
    ];

    const links: TabLink[] = [{ index: 1, name: 'All Bookings' }];
    const [activeTab, setActiveTab] = React.useState(1);
    const [searchTerm, setSearchTerm] = React.useState('');
    const { user } = React.useContext(AuthContext.Context);
    const { bookings, meta } = useSelector((state: ApplicationState) => state.bookings);
    const { search } = useLocation();

    const filteredBookings = React.useMemo(() => {
        switch (user?.role) {
            case Roles.Admin:
            case Roles.Client:
                return bookings;
            case Roles.AirportManager:
            case Roles.AirportProtocolCoordinator:
                return filter(bookings, { airportId: user.airportId });
            default:
                return [];
        }
    }, [bookings, user]);

    const latest_bookings = filteredBookings.sort((a, b) => Date.parse(b.dateCreated) - Date.parse(a.dateCreated));

    const dispatch = useDispatch();
    const pageNumber = React.useMemo(() => +(queryString.parse(search) as any).page || 1, [search]);

    React.useEffect(() => {
        dispatch(BookingsThunk.list(pageNumber, searchTerm));
    }, [dispatch, pageNumber]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };

    const handleSearch = () => {
        dispatch(BookingsThunk.list(pageNumber, searchTerm));
    };

    const handleFilter = (filter?: any) => {
        dispatch(BookingsThunk.list(pageNumber, searchTerm, filter));
    };

    const exportBookingFile = async () => {
        const config: RequestInit = {
            ...ApiClient.getFetchConfig(),
        };

        try {
            dispatch(NotificationsThunk.create(NotificationTypes.Info, `Downloading document`));

            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/booking-services/export-bookings`, config);

            try {
                const downloadedFile = await response.blob();
                const documentName = 'Bookings';
                saveAs(downloadedFile, documentName + '.csv');
            } catch (e) {
                dispatch(NotificationsThunk.create(NotificationTypes.Error, 'Error saving file'));
            }
        } catch (e) {
            dispatch(NotificationsThunk.create(NotificationTypes.Error, 'Error downloading file'));
        }
    };

    return (
        <AuthLayout showSidebar={false}>
            <TopBar breadcrumbs={breadcrumbs} />
            <AppLayout.Wrapper>
                <AppLayout.Header>
                    <HeaderStyleWrapper>
                        {/* <Col lg="8"> */}
                        <h3 className="header__title">All Bookings</h3>
                        {/* </Col> */}
                        <Formik
                            initialValues={{ startDate: '', endDate: '' }}
                            onSubmit={async (values) => {
                                ReactDOM.unstable_batchedUpdates(() => {
                                    setSearch('page', 1);
                                    handleFilter({ startDate: values.startDate, endDate: values.endDate });
                                });
                            }}
                        >
                            {({ handleSubmit, values }) => {
                                return (
                                    <form onSubmit={handleSubmit}>
                                        <h4>Filter By:</h4>
                                        <label className="label">
                                            Start Date:
                                            <Field type="date" name="startDate" />
                                        </label>
                                        <label className="label">
                                            End Date:
                                            <Field type="date" name="endDate" />
                                        </label>
                                        <Button
                                            style={{
                                                border: '1px solid currentColor',
                                                borderRadius: '5px',
                                                marginBottom: 8,
                                            }}
                                            disabled={!values.endDate.length || !values.startDate.length}
                                            type="submit"
                                        >
                                            Filter
                                        </Button>
                                    </form>
                                );
                            }}
                        </Formik>
                    </HeaderStyleWrapper>
                </AppLayout.Header>
                <AppLayout.Body>
                    <AppLayout.TabHeader
                        links={links}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        handleChange={handleChange}
                        handleSearch={handleSearch}
                        value={searchTerm}
                        searchable
                        isSearchNotOnChange
                        handleExport={exportBookingFile}
                    />
                    <AppLayout.TabBody>
                        <TabContent activeTab={activeTab} className="tab__content">
                            <TabPane tabId={1}>
                                {latest_bookings.length ? (
                                    <BookingsTable bookings={latest_bookings} meta={meta} />
                                ) : (
                                    <p>There are no bookings</p>
                                )}
                            </TabPane>
                        </TabContent>
                    </AppLayout.TabBody>
                </AppLayout.Body>
            </AppLayout.Wrapper>
        </AuthLayout>
    );
};
