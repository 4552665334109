import React from 'react';
import styled from 'styled-components';
import { InCome, Passenger } from './icons';
import { formatCurrencyWithNairaSign } from '../../../../util/misc/formatters';

enum BorderColor {
    Revenue = '#4DBD7D',
    Bookings = '#E6BE4A',
    Generated = '#1A9BFC',
    Passenger = '#6B6B6B',
}

enum Title {
    Revenue = 'Revenue',
    Bookings = 'Bookings',
    Generated = 'Generated',
    Passenger = 'Passenger',
}

const BorderColorMap: any = {
    [Title.Revenue]: BorderColor.Revenue,
    [Title.Bookings]: BorderColor.Bookings,
    [Title.Generated]: BorderColor.Generated,
    [Title.Passenger]: BorderColor.Passenger,
};

const CardWrapper: React.ElementType = styled.div<{ borderColor?: string }>`
    width: 100%;
    // max-width: 100%;
    min-width: 210px;
    height: 80px;
    border-left: 10px solid ${({ borderColor }) => borderColor};
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3), 0px 2px 0px 0px rgba(0, 0, 0, 0.15);
    padding: 0 8px;
    flex-wrap: wrap;
    display: grid;
    //  place-items: center;

    .bold-text {
        font-weight: 700;
    }
`;

export const RevenueCard: React.FC<{ title: string; amount: number | string; money?: boolean | number }> = ({
    title,
    amount,
    money,
}) => {
    const borderColorTitle = title.split(' ')[1];
    return (
        <div>
            <CardWrapper borderColor={BorderColorMap[borderColorTitle]} className="mt-4 mt-md-0">
                <div className="d-flex align-items-center justify-content-between">
                    <div>
                        <div>{title}</div>
                        <div className="bold-text mt-1">
                            {money
                                ? formatCurrencyWithNairaSign(amount ?? 0)
                                : String(amount).replace(/(.)(?=(\d{3})+$)/g, '$1,') ?? 0}
                        </div>
                    </div>
                    {borderColorTitle === Title.Passenger ? <Passenger /> : <InCome />}
                </div>
            </CardWrapper>
        </div>
    );
};
