import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import styled from 'styled-components';
import { AppLayout, AuthLayout, ClientServiceCardComponent, TopBar } from '../../../components';
import { Breadcrumb } from '../../../components/breadcrumbs/breadcrumbs';
import { ApplicationState } from '../../../store';
import { ServiceThunk } from '../../../store/services/services.thunk';
import { useParams } from 'react-router';
import { formatCurrency } from '../../../util/misc';
import { AuthContext } from '../../../context';
import { Roles } from '../../../util/constants';

const HeaderStyleWrapper = styled.div`
    display: flex;
    flex-direction: row;

    .left__header {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .right {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
`;

export const ClientServicePage: React.FC = () => {
    const breadcrumbs: Breadcrumb[] = [
        { name: 'Dashboard', path: '/app/dashboard' },
        { name: 'All Services', path: window.location.href },
    ];
    const { client_id } = useParams<any>();

    const dispatch = useDispatch();
    const { services } = useSelector((state: ApplicationState) => state);

    const { user: loggedInUser } = useContext(AuthContext.Context)!;

    React.useEffect(() => {
        dispatch(ServiceThunk.getAllServices());
    }, [dispatch]);

    return (
        <AuthLayout showSidebar={false}>
            <TopBar breadcrumbs={breadcrumbs} />
            <AppLayout.Wrapper>
                <AppLayout.Header>
                    <HeaderStyleWrapper>
                        <div className="left__header">
                            <h3 className="header__title">All Services</h3>
                        </div>
                    </HeaderStyleWrapper>
                </AppLayout.Header>

                <Row>
                    {services.services?.length > 0 ? (
                        services.services.map((service) => (
                            <Col lg={4} key={service.id}>
                                {loggedInUser?.role === Roles.AirportProtocolCoordinator && !service.status ? (
                                    <div
                                        style={{
                                            cursor: 'not-allowed',
                                            opacity: 0.5,
                                        }}
                                    >
                                        <ClientServiceCardComponent
                                            title={service.name}
                                            price={formatCurrency(service.price)}
                                            desc={service.description}
                                            to="#"
                                        />
                                    </div>
                                ) : (
                                    <ClientServiceCardComponent
                                        title={service.name}
                                        price={formatCurrency(service.price)}
                                        to={
                                            service.portharcourtService
                                                ? `/app/client-service/${service.id}/create-porthacourt-booking/${client_id}`
                                                : `/app/client-service/${service.id}/create-booking/${client_id}`
                                        }
                                        desc={service.description}
                                    />
                                )}
                            </Col>
                        ))
                    ) : (
                        <p>There are no services</p>
                    )}
                </Row>
            </AppLayout.Wrapper>
        </AuthLayout>
    );
};
