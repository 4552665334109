import { Formik, FormikHelpers } from 'formik';
import { find, indexOf } from 'lodash';
import React, { useState } from 'react';
import { Calendar, Check, Info, Trash2, X } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { Cell } from 'react-table';
import { Col, Row } from 'reactstrap';
import { AppLayout, CustomPrimaryButton, DataTable } from '../../../../../components';
import { FormButton, FormDatePicker, FormTextInput } from '../../../../../components/form';
import { FormCheckBox } from '../../../../../components/form/form-check-box';
import { CustomModal } from '../../../../../components/utilities/modal/modal';
import { CreatePassengerBooking } from '../../../../../models/request';
import { Service } from '../../../../../models/response';
import { ApplicationState, ServiceThunk } from '../../../../../store';
import { AirportsThunk } from '../../../../../store/airports/airports.thunk';
import { GendersMap, HonorificsMap } from '../../../../../util/constants';
import { AirlinesMap } from '../../../../../util/constants/airline';
import { formatDate, formatTime } from '../../../../../util/misc/formatters';
import { validateFormValues } from '../../../../../util/validation';
import { formatCurrency } from '../../../../../util/misc';
import { TermsAndConditionsModal } from './terms-and-conditions';

interface Props {
    service: Service;
    client: string;
    setPassengerBookings: (bookings: CreatePassengerBooking[]) => void;
    proceed: () => void;
}

export const CreateBookingForm: React.FC<Props> = ({ service, setPassengerBookings, proceed, client }) => {
    const [passengerBookings, setPassengerbookings] = React.useState<CreatePassengerBooking[]>([]);
    const [isTermsOpen, setTermsOpen] = useState(false);
    const [lastPassengerBooking, setLastPassengerBooking] = React.useState<CreatePassengerBooking>(
        new CreatePassengerBooking(service)
    );
    const dispatch = useDispatch();
    const { airports } = useSelector((state: ApplicationState) => state.airports);
    const { services } = useSelector((state: ApplicationState) => state);
    const [additionalService, setAdditionalService] = React.useState<number>(0);

    const additionalServicePrice = services && services.services.find((s) => s.id === +additionalService)?.price;
    const addBooking = (booking: CreatePassengerBooking, helpers: FormikHelpers<CreatePassengerBooking>) => {
        setPassengerbookings(passengerBookings.concat(booking));
        setLastPassengerBooking(booking);
        helpers.resetForm();
    };

    const removePassenger = (index: number) => {
        const newPassengersList = passengerBookings.slice(0, index).concat(passengerBookings.slice(index + 1));
        setPassengerbookings(newPassengersList);
    };

    const verifyBookings = () => {
        setPassengerBookings(passengerBookings);
        proceed();
    };

    React.useEffect(() => {
        dispatch(ServiceThunk.getAllServices());
    }, [dispatch]);

    React.useEffect(() => {
        dispatch(AirportsThunk.list());
    }, [dispatch]);

    if (isTermsOpen) {
        return (
            <TermsAndConditionsModal isOpen={isTermsOpen} setIsOpen={setTermsOpen} title="Booking Terms and Conditions">
                <div style={{ lineHeight: '1.6', color: '#000000' }}>
                    <h4 style={{ fontWeight: 'bold', marginBottom: '15px' }}>Indemnity Form</h4>
                    <p style={{ textTransform: 'uppercase' }}>
                        I undertake that I am aware that Federal Airports Authority of Nigeria (FAAN) protocol service
                        is for fast, seamless, and memorable travel experience through the airport, and does not exempt
                        any of the authority's clients from routine airport security screening, except at the discretion
                        of any of the officers of the relevant agencies.
                    </p>

                    <p
                        style={{
                            color: 'red',
                            textDecoration: 'underline',
                            textTransform: 'uppercase',
                            fontWeight: 'bold',
                            marginTop: '15px',
                        }}
                    >
                        I hereby affirm: that I personally packed my bag(s);
                        <br />
                        That I do not have any air travel prohibited item in my baggage;
                        <br />
                        That I do not have any illicit drugs in my possession;
                        <br />
                        That my travel documents are valid and legal;
                    </p>

                    <p
                        style={{
                            color: 'red',
                            textDecoration: 'underline',
                            textTransform: 'uppercase',
                            fontWeight: 'bold',
                            marginTop: '15px',
                        }}
                    >
                        That I shall willingly indemnify the Federal Airports Authority of Nigeria (FAAN) and its
                        protocol personnel from any form of liability that may arise as a result of my using FAAN
                        protocol service.
                    </p>

                    <h4 style={{ fontWeight: 'bold', marginTop: '20px' }}>Declaration</h4>
                    <p style={{ textTransform: 'uppercase' }}>
                        I undertake that I will take full responsibility for any issue arising from any contraband item
                        found on me, or in my possession, or in my belongings, or illegality of my travelling documents,
                        etc. Therefore, FAAN and its officer(s) are absolved from any such liability, where it occurs.
                    </p>
                </div>
            </TermsAndConditionsModal>
        );
    }

    return (
        <React.Fragment>
            {/* Create Passenger Booking */}
            <AppLayout.Body className="mb-5">
                <AppLayout.TabHeader>
                    <div className="my-4">
                        <span>Kindly fill out the information for booking service</span>
                    </div>
                </AppLayout.TabHeader>
                <Formik
                    validate={validateFormValues}
                    enableReinitialize
                    initialValues={new CreatePassengerBooking(lastPassengerBooking)}
                    onSubmit={addBooking}
                >
                    {({ handleSubmit, values, setFieldValue }) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <div className="tab__content__row">
                                    <div className="mb-3">
                                        <b>Passenger airport details</b>
                                    </div>
                                    <div className="row mb-3">
                                        <Col sm={6} lg={3}>
                                            <FormTextInput name="AirportId" label="Airport" as="select" compact>
                                                <option disabled value="">
                                                    SELECT AN AIRPORT
                                                </option>
                                                {airports.map(({ id, name }) => (
                                                    <option value={id} key={id}>
                                                        {name}
                                                    </option>
                                                ))}
                                            </FormTextInput>
                                        </Col>
                                        <Col sm={6} lg={3}>
                                            <FormDatePicker
                                                name="Date_Of_Travel"
                                                label="Travel date"
                                                icon={Calendar}
                                                minDate={new Date()}
                                                compact
                                            />
                                        </Col>
                                        <Col sm={6} lg={3}>
                                            <span style={{ fontSize: 14 }}>Flight Number</span>
                                            <FormTextInput name="Flight_Number" compact />
                                        </Col>
                                        <Col sm={6} lg={3}>
                                            <FormDatePicker name="Date_Of_Arrival" label="Flight time" isTime compact />
                                        </Col>
                                    </div>
                                    <div className="row mb-3">
                                        <Col sm={6} lg={3}>
                                            <FormTextInput name="airline" label="Airline" compact as="select">
                                                <option disabled value="">
                                                    Select an Airline
                                                </option>
                                                {Object.entries(AirlinesMap).map(([air, i]) => (
                                                    <option key={i} value={air}>
                                                        {air}
                                                    </option>
                                                ))}
                                            </FormTextInput>
                                        </Col>

                                        <Col sm={6} lg={3}>
                                            <FormTextInput
                                                name="DestinationAirportId"
                                                label="Destination"
                                                as="select"
                                                compact
                                            >
                                                <option disabled value="">
                                                    SELECT DESTINATION
                                                </option>
                                                {airports.map(({ id, name }) => (
                                                    <option value={id} key={id}>
                                                        {name}
                                                    </option>
                                                ))}
                                            </FormTextInput>
                                        </Col>
                                    </div>
                                    <div className="mb-3">
                                        <b>Passenger/Family Member's details</b>
                                    </div>
                                    <div className="row mb-3">
                                        <Col sm={6} lg={3}>
                                            <FormTextInput name="FirstName" label="First name" compact />
                                        </Col>
                                        <Col sm={6} lg={3}>
                                            <FormTextInput name="LastName" label="Last name" compact />
                                        </Col>
                                        <Col sm={6} lg={3}>
                                            <FormTextInput name="Designation" label="Designation" compact as="select">
                                                <option disabled value="">
                                                    SELECT A DESIGNATION
                                                </option>
                                                {Object.entries(HonorificsMap).map(([id, name]) => (
                                                    <option value={id} key={id}>
                                                        {name}
                                                    </option>
                                                ))}
                                            </FormTextInput>
                                        </Col>
                                        <Col sm={6} lg={3}>
                                            <FormTextInput name="Gender" label="Gender" compact as="select">
                                                <option disabled value="">
                                                    SELECT A GENDER
                                                </option>
                                                {Object.entries(GendersMap).map(([id, name]) => (
                                                    <option value={id} key={id}>
                                                        {name}
                                                    </option>
                                                ))}
                                            </FormTextInput>
                                        </Col>
                                    </div>

                                    <div className="row mb-3">
                                        {/* <Col sm={6} lg={3}>
                                            <FormTextInput name="nin" label="NIN Number" compact type="number" />
                                        </Col> */}
                                        <Col sm={6} lg={3}>
                                            <FormTextInput
                                                name="mobileNumber"
                                                label="Mobile Number"
                                                compact
                                                type="text"
                                            />
                                        </Col>
                                        {/* <Col sm={6} lg={3}>
                                            <span style={{ fontSize: 14 }}>
                                                Student?<small>(optional)</small>
                                            </span>
                                            <FormTextInput name="isStudent" compact />
                                        </Col>
                                        <Col sm={6} lg={3}>
                                            <span style={{ fontSize: 14 }}>
                                                Name of School<small>(optional)</small>
                                            </span>
                                            <FormTextInput name="schoolName" compact />
                                        </Col> */}
                                    </div>
                                    <div className="mt-3">
                                        <span>Passenger special requirement</span>
                                    </div>
                                    <div className="my-3">
                                        <div className="d-flex">
                                            <div>
                                                <FormCheckBox name="Wheel_Chair" label="Wheelchair" />
                                            </div>
                                            <div className="ml-5">
                                                <FormCheckBox name="Others" label="Others" />
                                            </div>
                                        </div>
                                    </div>
                                    {values.Others && (
                                        <div className="my-3">
                                            <FormTextInput
                                                className="wide"
                                                name="Special_Requirement"
                                                compact
                                                as="textarea"
                                            />
                                        </div>
                                    )}
                                    {/* <div className="row">
                                        <Col sm={6} lg={3}>
                                            <FormTextInput
                                                name="additionalServiceId"
                                                label="Additional Services"
                                                as="select"
                                                currency
                                                handleChange={(e: any) => {
                                                    setFieldValue('additionalServiceId', e.target.value);
                                                    setAdditionalService(e.target.value);
                                                }}
                                                amount={values.additionalServiceId}
                                                compact
                                            >
                                                <option value="">SELECT SERVICE</option>
                                                {services.services.map(({ id, name }) => (
                                                    <option value={id} key={id}>
                                                        {name}
                                                    </option>
                                                ))}
                                            </FormTextInput>
                                        </Col>
                                    </div> */}
                                    {/* {find(services.services, { id: +values.additionalServiceId })?.name == 'Arrival' ? (
                                        <Row>
                                            <Col sm={6} lg={3}>
                                                <FormTextInput
                                                    name="departureAirport"
                                                    label="Departure Airport"
                                                    as="select"
                                                    compact
                                                >
                                                    <option disabled value="">
                                                        SELECT AN AIRPORT
                                                    </option>
                                                    {airports.map(({ id, name }) => (
                                                        <option value={id} key={id}>
                                                            {name}
                                                        </option>
                                                    ))}
                                                </FormTextInput>
                                            </Col>
                                            <Col sm={6} lg={3}>
                                                <FormDatePicker
                                                    name="departureTime"
                                                    label="Departure Time"
                                                    isTime
                                                    compact
                                                />
                                            </Col>
                                        </Row>
                                    ) : null} */}
                                    <div className="d-flex flex-column align-items-end ">
                                        <div className="">
                                            <span className="mr-2">
                                                <b>Sub total</b>
                                            </span>
                                            <span>{formatCurrency(service.price * passengerBookings.length)}</span>
                                        </div>
                                        <div className="mb-2">
                                            <span className="mr-2">
                                                <b>+</b>
                                            </span>
                                            <span>{formatCurrency(additionalServicePrice ?? 0)}</span>
                                        </div>
                                        <div className="mb-5">
                                            <span className="mr-2">
                                                <b>Total</b>
                                            </span>
                                            <span>
                                                {formatCurrency(
                                                    passengerBookings.length * service.price +
                                                        (additionalServicePrice ?? 0)
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <CustomPrimaryButton type="submit">
                                            <span>Add +</span>
                                        </CustomPrimaryButton>
                                    </div>
                                </div>
                            </form>
                        );
                    }}
                </Formik>
            </AppLayout.Body>

            {/* Passenger listings */}
            <AppLayout.Body className="mb-5">
                {passengerBookings.length ? (
                    <div className="tab__content__row">
                        <div className="mb-3">
                            <b>Passengers added </b>
                        </div>
                        <DataTable
                            columns={[
                                {
                                    Header: 'S/N',
                                    Cell: (cell: Cell<CreatePassengerBooking>) => cell.row.index + 1,
                                },
                                {
                                    Header: 'Name',
                                    Cell: ({ row: { original: passenger } }: Cell<CreatePassengerBooking>) =>
                                        passenger.FirstName + ' ' + passenger.LastName,
                                },
                                {
                                    Header: 'Airport',
                                    accessor: 'AirportId',
                                    Cell: ({ row: { original: passenger } }: Cell<CreatePassengerBooking>) =>
                                        find(airports, { id: passenger.AirportId })?.name,
                                },
                                {
                                    Header: 'Airline',
                                    Cell: ({ row: { original: passenger } }: Cell<CreatePassengerBooking>) =>
                                        passenger.airline,
                                },
                                {
                                    Header: 'Flight No.',
                                    accessor: 'Flight_Number',
                                },
                                {
                                    Header: 'Travel date',
                                    accessor: 'Date_Of_Travel',
                                    Cell: ({ row: { original: passenger } }: Cell<CreatePassengerBooking>) =>
                                        formatDate(passenger.Date_Of_Travel),
                                },
                                {
                                    Header: 'Flight time',
                                    accessor: 'Date_Of_Arrival',
                                    Cell: ({ row: { original: passenger } }: Cell<CreatePassengerBooking>) =>
                                        formatTime(passenger.Date_Of_Arrival),
                                },
                                {
                                    Header: 'Special R.',
                                    accessor: 'special_Requirement' as any,
                                    Cell: ({ row: { original: passenger } }: Cell<CreatePassengerBooking>) => {
                                        return (
                                            <div>
                                                {passenger.Wheel_Chair ? (
                                                    <span>Wheel chair</span>
                                                ) : !passenger.Special_Requirement ? (
                                                    <X size={14} strokeWidth={2} />
                                                ) : (
                                                    <span>{passenger.Special_Requirement}</span>
                                                )}
                                            </div>
                                        );
                                    },
                                },
                                // {
                                //     Header: 'Addtional service',
                                //     accessor: 'additionalServiceId' as any,
                                //     Cell: ({ row: { original: passenger } }: Cell<CreatePassengerBooking>) =>
                                //         find(services.services, { id: passenger.additionalServiceId })?.name ?? '',
                                // },
                                {
                                    Header: 'Action',
                                    Cell: (cell: Cell<CreatePassengerBooking>) => (
                                        <React.Fragment>
                                            <CustomPrimaryButton
                                                className="small inverted"
                                                onClick={removePassenger.bind(undefined, cell.row.index)}
                                            >
                                                <Trash2 size={14} />
                                            </CustomPrimaryButton>
                                        </React.Fragment>
                                    ),
                                },
                            ]}
                            data={passengerBookings}
                        />
                    </div>
                ) : (
                    <div className="tab__content__row">
                        <span>You have not added any passengers</span>
                    </div>
                )}
            </AppLayout.Body>

            <Formik
                validate={(values) => {
                    return values.Accepted ? {} : { Accepted: 'You must accept the terms and conditions' };
                }}
                initialValues={{ Accepted: false }}
                onSubmit={verifyBookings}
            >
                {({ handleSubmit, errors }) =>
                    passengerBookings.length > 0 && (
                        <form onSubmit={handleSubmit}>
                            <div className="d-flex align-items-center gap-2">
                                <FormCheckBox name="Accepted" hideError label={<span>I agree to the </span>} />
                                <button className="text-btn" type="button" onClick={() => setTermsOpen(true)}>
                                    terms and conditions
                                </button>
                            </div>
                            {errors && errors.Accepted && <span className="form-error-message">{errors.Accepted}</span>}
                            <FormButton type="submit" className="small mt-5">
                                <span>Continue</span>
                            </FormButton>
                        </form>
                    )
                }
            </Formik>
        </React.Fragment>
    );
};
