import { merge } from 'lodash';
import { PBooking } from '../../models/response/bookings/pbooking';
import { PBookingActionNames, PBookingsState } from './pbooking.types';
import { ReduxAction } from '../common/redux-action';
import { AuthActionNames } from '../auth';

const initialState: PBookingsState = {
    booking: { serviceId: 0, airportId: 0, clientId: '', additionalOdc: 0 },
    meta: undefined,
};

export const pbookingsReducer = (
    state = initialState,
    action: ReduxAction<PBookingActionNames & AuthActionNames.LOG_OUT>
): PBookingsState => {
    switch (action.type) {
        case PBookingActionNames.ADD: {
            return {
                ...state,
                booking: { ...state.booking, [action.payload.id]: action.payload },
            };
        }
        default: {
            return state; // Add a default return statement
        }
    }
};
