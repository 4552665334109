import React from 'react';
import { MoreVertical, Users } from 'react-feather';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Cell } from 'react-table';
import { CustomDropdown, CustomDropdownItem, CustomPrimaryButton, DataTable } from '../../../components';
import { PresentationalFormSwitch } from '../../../components/form';
import { AuthContext } from '../../../context';
import { UpdateServiceModel } from '../../../models/request';
import { Service } from '../../../models/response';
import { ServiceThunk } from '../../../store/services/services.thunk';
import { ClientTypes, ClientTypesMap, Roles } from '../../../util/constants';
import { formatDate } from '../../../util/misc/formatters';

interface ServicesTableProps {
    services: Service[];
    setUpdateModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setActiveService: React.Dispatch<React.SetStateAction<Service | undefined>>;
}

export const ServicesTable: React.FC<ServicesTableProps> = ({ services, setActiveService, setUpdateModalOpen }) => {
    const { canAccess } = React.useContext(AuthContext.Context);
    const dispatch = useDispatch();

    const handleSetStatus = React.useCallback(
        (service: Service, status: boolean) => {
            if (service.status !== status) {
                service.status = status;
                dispatch(ServiceThunk.updateService(new UpdateServiceModel(service)));
            }
        },
        [dispatch]
    );

    return (
        <DataTable
            columns={[
                {
                    Header: 'Date Created',
                    accessor: 'dateCreated',
                    Cell: ({ row: { original } }: Cell<Service>) => {
                        return <b>{formatDate(original.dateCreated)}</b>;
                    },
                },
                {
                    Header: 'Name',
                    accessor: 'name',
                },

                {
                    Header: 'Service Type',
                    accessor: 'serviceTypeId',
                    Cell: ({ row: { original } }: any) => ClientTypesMap[original.serviceTypeId as ClientTypes],
                },
                {
                    Header: 'Price',
                    accessor: 'price',
                    Cell: ({ row: { original } }: Cell<Service>) => {
                        return <span>NGN {original.price.toLocaleString()}</span>;
                    },
                },
                {
                    Header: 'Bookings',
                    Cell: (cell: Cell<Service>) => {
                        const bookingCount = cell.row.original.bookingCount;
                        return (
                            <span className="d-flex align-items-center">
                                <Users color="#B9B9B9" size={12} className="mr-2" />
                                <span
                                    style={{
                                        color: 'var(--primary-color)',
                                    }}
                                >
                                    {bookingCount} Booking(s)
                                </span>
                            </span>
                        );
                    },
                },
                {
                    Header: 'Actions',
                    Cell: ({ row: { original: service } }: Cell<Service>) => {
                        return (
                            <React.Fragment>
                                <CustomPrimaryButton
                                    as={Link}
                                    to={{
                                        pathname: `/app/service/${service.id}`,
                                        state: { intent: 'bookings' },
                                    }}
                                    className="small inverted mr-2"
                                >
                                    <span>View Bookings</span>
                                </CustomPrimaryButton>
                                {canAccess(Roles.Client, true) && (
                                    <CustomPrimaryButton
                                        as={Link}
                                        to={`/app/service/${service.id}/create-booking`}
                                        className="small mr-2"
                                    >
                                        <span>Book service</span>
                                    </CustomPrimaryButton>
                                )}
                                <CustomDropdown
                                    triggerComponent={() => {
                                        return (
                                            <CustomPrimaryButton className="small">
                                                <MoreVertical size={12} />
                                            </CustomPrimaryButton>
                                        );
                                    }}
                                >
                                    <CustomDropdownItem>
                                        <Link to={`/app/service/${service.id}`}>View Service</Link>
                                    </CustomDropdownItem>
                                    {canAccess(Roles.Admin) && (
                                        <React.Fragment>
                                            <CustomDropdownItem
                                                onClick={() => {
                                                    setActiveService(service);
                                                    setUpdateModalOpen(true);
                                                }}
                                            >
                                                <span>Edit Service</span>
                                            </CustomDropdownItem>
                                            <CustomDropdownItem
                                                onClick={handleSetStatus.bind(undefined, service, !service.status)}
                                            >
                                                <span>{service.status ? 'Deactivate' : 'Activate'}</span>
                                                <PresentationalFormSwitch checked={service.status} />
                                            </CustomDropdownItem>
                                        </React.Fragment>
                                    )}
                                </CustomDropdown>
                            </React.Fragment>
                        );
                    },
                },
            ]}
            data={services}
        />
    );
};
