type AcceptableCurrency = 'NGN' | 'USD' | 'GBP';

export const formatCurrency = (value: number | string, currency: AcceptableCurrency = 'NGN') => {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency,
    });

    return formatter.format(+value);
};

export const formatCurrencyWithNairaSign = (amount: string | number) => {
    // Convert the number to a string and remove any non-digit characters
    const cleanAmount = String(amount).replace(/\D/g, '');

    // Insert a comma every three digits from the right
    const formattedAmount = cleanAmount.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    // Add the Naira sign prefix
    const result = `₦ ${formattedAmount}`;

    return result;
};

export const convertCurrencyToDecimal = (amount: string) => {
    const numberString = amount.replace(/,/g, '');
    const number = parseInt(numberString);
    return number as any;
};
