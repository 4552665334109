import { PBookingActionNames, AddPBookingAction } from './pbooking.types';
import { ApiResponse } from '../../util/api-client';
import { PBooking } from '../../models/response/bookings/pbooking';

export class PBookingActions {
    public static add(booking: PBooking): AddPBookingAction {
        return {
            type: PBookingActionNames.ADD,
            payload: booking,
        };
    }
}
