import { Formik } from 'formik';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { Loader } from 'react-feather';
import { useDispatch } from 'react-redux';
import { Col, ModalBody, ModalFooter } from 'reactstrap';
import { CustomPrimaryButton } from '..';
import { FundWalletModel } from '../../models/request';
import { TransactionsService } from '../../services';
import { NotificationsThunk, TransactionsThunk } from '../../store';
import { NotificationTypes } from '../../util/constants';
import { history } from '../../util/history';
import { convertCurrencyToDecimal } from '../../util/misc/formatters';
import { FormTextInput } from '../form';
import DatePicker from 'react-datepicker';
import { CustomModal } from '../utilities/modal/renewal-modal';
import { RenewalsModel } from './renewal';

interface Props {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    onComplete?: (value: string) => void;
    client?: string;
    isCreatingBooking?: boolean;
    pageNumber: number;
}

export const RenewalModal: React.FC<Props> = ({ isOpen, setIsOpen, onComplete, client, pageNumber }) => {
    const dispatch = useDispatch();

    return (
        <CustomModal isOpen={isOpen} setIsOpen={setIsOpen} size="lg" title="Renewal">
            <div className="py-4">
                <Formik
                    initialValues={new RenewalsModel()}
                    onSubmit={async (model, { setSubmitting }) => {
                        setSubmitting(true);
                        if (!model.startDate) {
                            dispatch(NotificationsThunk.create(NotificationTypes.Error, 'Please select a valid date'));
                            setSubmitting(false);
                        } else {
                            model.applicationUserId = client ?? '';

                            await TransactionsService.renewalDate(model)
                                .then((res) => {
                                    if (res.ok) {
                                        dispatch(
                                            NotificationsThunk.create(
                                                NotificationTypes.Success,
                                                `Annual renewal date saved successfully`
                                            )
                                        );
                                        if (client !== undefined) {
                                            dispatch(TransactionsThunk.fetchPayRenewalId(client, pageNumber));
                                        } else {
                                            console.error('Client is undefined. Unable to dispatch action.');
                                        }
                                        onComplete?.(Date.parse(model.startDate).toString());
                                    }
                                })
                                .catch((error) => {
                                    console.error('Error occurred:', error);
                                    dispatch(NotificationsThunk.create(NotificationTypes.Error, 'An error occurred'));
                                })

                                .finally(() => {
                                    setSubmitting(false);
                                    setIsOpen(false);
                                });
                        }
                    }}
                >
                    {({ handleSubmit, isSubmitting, isValid, setFieldValue, values }) => (
                        <form onSubmit={handleSubmit}>
                            <ModalBody>
                                <div className="form-row">
                                    <Col sm="12" className="text-center">
                                        <span style={{ fontSize: '1.7rem' }}>
                                            Plaese Select Renewal Date for this client
                                        </span>
                                    </Col>
                                    <Col sm="12" className="text-center">
                                        <span className="text-danger" style={{ fontSize: '0.9rem' }}>
                                            Note: Selected date will be used as annual renewal date going forword
                                        </span>
                                    </Col>
                                </div>
                                <div className="form-row mt-3">
                                    <Col sm="8" className="mx-auto">
                                        <FormTextInput name="startDate" startDate={values.startDate} type="date" />
                                    </Col>
                                </div>
                            </ModalBody>
                            <ModalFooter className="d-flex justify-content-center  align-items-center">
                                <CustomPrimaryButton disabled={!isValid} type="submit" className="mr-8">
                                    {isSubmitting ? <Loader /> : <span>Submit</span>}
                                </CustomPrimaryButton>
                                <CustomPrimaryButton
                                    disabled={!isValid}
                                    onClick={() => setIsOpen(false)}
                                    style={{ backgroundColor: 'white', color: 'green' }}
                                >
                                    <span>Cancel</span>
                                </CustomPrimaryButton>
                            </ModalFooter>
                        </form>
                    )}
                </Formik>
            </div>
        </CustomModal>
    );
};
