import { Reporting, ReportingInterval } from '../../models/response';
import { ReduxAction } from '../common/redux-action';

export interface ReportingState {
    reportings: Reporting;
    reportingsIntervals: ReportingInterval;
}

export enum ReportingActionNames {
    REPRTING_GET = 'GET REPRTING STATS',
    REPRTING_GET_INTERVAL = 'GET REPRTING STATS INTERVAL',
}

export interface ReportingAction extends ReduxAction<typeof ReportingActionNames.REPRTING_GET, any> {}
export interface ReportingIntervalAction extends ReduxAction<typeof ReportingActionNames.REPRTING_GET_INTERVAL, any> {}
