import { capitalize } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo } from 'react';
import { Grid, Users } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Cell } from 'react-table';
import { AuthLayout, DataTable, FlagTime, SignOutSwitchComponent } from '../../../components';
import { Transaction, User, UserActivity } from '../../../models/response';
import { ApplicationState, TransactionsThunk, UsersThunk } from '../../../store';
import { Roles } from '../../../util/constants';
import { DashboardPageStyleWrapper } from '../components';
import { AdminDashbboardStyle } from './admin-dashboard';
import { TopCardLink } from './airport-protocol-dashboard';

export const AccountantDashboard: React.FC = () => {
    const dispatch = useDispatch();
    const { transactions, users } = useSelector((state: ApplicationState) => state);
    const activities = useMemo(() => users.userActivities || [], [users.userActivities]);

    const cards: TopCardLink[] = useMemo(
        () => [
            {
                name: 'Services',
                icon: Grid,
                count: transactions.transactionsMeta?.totalCount ?? 0,
                color: 'var(--primary-color)',
            },
            {
                name: 'Payments Paid',
                icon: Grid,
                count: users.clientStats?.paid ?? 0,
                color: '#ED0000',
            },
            {
                name: 'Clients',
                icon: Users,
                count: users.clientStats?.total ?? 0,
                color: 'var(--primary-color)',
            },
            {
                name: 'Unpaid Payments',
                icon: Grid,
                count: users.clientStats?.unpaid ?? 0,
                color: '#B1B71C',
            },
        ],
        [transactions.transactionsMeta, users.clientStats]
    );

    useEffect(() => {
        dispatch(TransactionsThunk.list());
        dispatch(UsersThunk.userActivities(Roles.Client));
        dispatch(UsersThunk.getClientStats());
    }, [dispatch]);

    return (
        <AuthLayout showSidebar={false}>
            <DashboardPageStyleWrapper>
                <AdminDashbboardStyle>
                    <div className="d-flex flex-row top__content">
                        <div className="flag">
                            <FlagTime />
                        </div>
                        <div className="switch d-flex justify-content-end">
                            <SignOutSwitchComponent />
                        </div>
                    </div>
                    <div className="mt-3 icon-row row">
                        {cards.map(({ name, icon: Icon, count, color }, i) => (
                            <div className="icon-row__col mb-3 col" key={i}>
                                <div className="icon-row__item row">
                                    <div className="d-flex justify-content-start col-md-9 pt-1">
                                        <Icon size={20} className="mr-2" style={{ color }} />
                                        <span className="oneline__text">{name}</span>
                                    </div>
                                    <div className="d-flex flex-row-reverse text-right col-md-3">
                                        <span style={{ fontSize: '20px', fontWeight: 'bold' }}>{count}</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                        <div className="w-100" />
                        <div className="col deck-container__deck p-3 mb-4">
                            <div className="row">
                                <div className="col">
                                    <p>Latest Services</p>
                                </div>
                                <div className="col d-flex flex-row-reverse">
                                    <p>
                                        <Link to="/app/transaction-services">
                                            View all <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                                        </Link>
                                    </p>
                                </div>
                            </div>
                            <div>
                                <DataTable
                                    hideHead
                                    columns={[
                                        {
                                            accessor: 'dateCreated',
                                            Cell: ({ row: { original } }: Cell<User>) => {
                                                return <b>{capitalize(moment(original.dateCreated).fromNow())}</b>;
                                            },
                                        },
                                        {
                                            accessor: 'transactType',
                                            Cell: ({ row: { original } }: Cell<Transaction>) => {
                                                const text =
                                                    original.transactType === 'Protocol Services'
                                                        ? 'Booking service'
                                                        : 'Registration Service';
                                                return <span>{text}</span>;
                                            },
                                        },
                                        {
                                            Header: 'Actions',
                                            Cell: ({ row: { original: transaction } }: Cell<Transaction>) => {
                                                return (
                                                    <Link to={`/app/transaction-service/${transaction.transactionId}`}>
                                                        View service
                                                    </Link>
                                                );
                                            },
                                        },
                                    ]}
                                    data={transactions.transactions.slice(0, 5)}
                                />
                            </div>

                            <p className="text-center">
                                <Link to="/app/transaction-services" style={{ color: '#000' }}>
                                    See all <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                                </Link>
                            </p>
                        </div>
                        <div className="col deck-container__deck p-3">
                            <div className="row">
                                <div className="col">
                                    <p>Latest Activities</p>
                                </div>
                                <div className="col d-flex flex-row-reverse">
                                    <p>
                                        <Link to="/app/dashboard">
                                            View all <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                                        </Link>
                                    </p>
                                </div>
                            </div>
                            <div>
                                <DataTable
                                    hideHead
                                    columns={[
                                        {
                                            accessor: 'dateCreated',
                                            Cell: ({ row: { original } }: Cell<UserActivity>) => {
                                                return <b>{capitalize(moment(original.dateCreated).fromNow())}</b>;
                                            },
                                        },
                                        {
                                            accessor: 'description',
                                        },
                                    ]}
                                    data={activities.slice(0, 5)}
                                />
                                <p className="text-center">
                                    <Link to="/app/dashboard" style={{ color: '#000' }}>
                                        See all <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                                    </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </AdminDashbboardStyle>
            </DashboardPageStyleWrapper>
        </AuthLayout>
    );
};
