import * as queryString from 'query-string';
import React from 'react';
import { Plus } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { TabContent, TabPane } from 'reactstrap';
import { AppLayout, AuthLayout, CustomPrimaryButton, TabLink, TopBar } from '../../components';
import { ChangeUserStatusModel, CreateProtocolModel, CreateUserModel, EditUserModel } from '../../models/request';
import { User } from '../../models/response';
import { ApplicationState } from '../../store';
import { AirportsThunk } from '../../store/airports/airports.thunk';
import { UsersThunk } from '../../store/users/users.thunk';
import { Breadcrumb } from '../../components';
// import { ClientStatuses } from '../../../util/constants';
import { HeaderStyleWrapper } from '../admin/bookings/view-booking/view-booking-page.style';
import { CreateUserModal } from './modals';
import { EditUserModal } from './modals/edit-protocol-modal';
import { ProtocolTable } from './protocols-table';
import { Roles } from '../../util/constants';

export const ProtocolOfficersPage = () => {
    const breadcrumbs: Breadcrumb[] = [
        { name: 'Dashboard', path: '/app/dashboard' },
        { name: 'Protocols', path: window.location.href },
    ];

    const [createModalOpen, setCreateModalOpen] = React.useState(false);
    const [EditModalOpen, setEditModalOpen] = React.useState(false);
    const [value, setValue] = React.useState('');
    const [activeUser, setActiveUser] = React.useState<User>();
    const [searchTerm, setSearchTem] = React.useState('');
    const { search } = useLocation();

    const links: TabLink[] = [
        { index: 1, name: 'All Protocols', query: [] },
        // { index: 2, name: 'Suspended', query: ['status', 'false'] },
    ];

    const [activeTab, setActiveTab] = React.useState(1);
    const dispatch = useDispatch();
    const { protocols, protocolsMeta } = useSelector((state: ApplicationState) => state.users);

    const handleCreateUser = React.useCallback(
        (userDetails: CreateProtocolModel) => {
            const protocolDetails = { ...userDetails };
            dispatch(
                UsersThunk.createUser(protocolDetails as CreateProtocolModel, setCreateModalOpen.bind(undefined, false))
            );
        },
        [dispatch]
    );

    const handleUpdateProtocol = React.useCallback(
        (prtocolDetails: EditUserModel) => {
            dispatch(
                UsersThunk.UpdateUser(prtocolDetails, setEditModalOpen.bind(undefined, false), () => {
                    fetchProtocols();
                })
            );
        },
        [dispatch]
    );

    const pageNumber = React.useMemo(() => +(queryString.parse(search) as any).page || 1, [search]);
    const status: 'true' | 'false' | undefined = React.useMemo(
        () => (queryString.parse(search) as any).status,
        [search]
    );

    const fetchProtocols = React.useCallback(() => {
        dispatch(UsersThunk.getProtocols(pageNumber, searchTerm));
    }, [dispatch, pageNumber]);

    const handleSetStatus = React.useCallback(
        (protocol: User, status: boolean) => {
            if (protocol.account_Status !== status) {
                protocol.account_Status = status;
                dispatch(
                    UsersThunk.changeUserStatus(new ChangeUserStatusModel(protocol), () => {
                        fetchProtocols();
                    })
                );
            }
        },
        [dispatch, fetchProtocols]
    );

    React.useEffect(() => {
        fetchProtocols();
        dispatch(AirportsThunk.list());

        switch (status) {
            case 'false':
                setActiveTab(2);
                break;
            default:
                setActiveTab(1);
        }
    }, [dispatch, status, fetchProtocols]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTem(e.target.value);
    };

    const handleSearch = () => {
        dispatch(UsersThunk.getProtocols(pageNumber, searchTerm));
    };

    const filterdUsers: User[] = protocols.filter((user: User) => {
        return (
            (user.firstName && user.firstName.toLowerCase().indexOf(value.toLowerCase()) !== -1) ||
            (user.email && user.email.toString().toLowerCase().indexOf(value.toLowerCase()) !== -1)
        );
    });

    return (
        <AuthLayout showSidebar={false}>
            <TopBar breadcrumbs={breadcrumbs} />

            <CreateUserModal
                isOpen={createModalOpen}
                setIsOpen={setCreateModalOpen}
                handleCreateUser={handleCreateUser}
            />
            {activeUser && (
                <EditUserModal
                    editModalOpen={EditModalOpen}
                    setEditModalOpen={setEditModalOpen}
                    activeUser={activeUser}
                    handleUpdateUser={handleUpdateProtocol}
                />
            )}

            <AppLayout.Wrapper>
                <AppLayout.Header>
                    <HeaderStyleWrapper>
                        <div className="flex-column" style={{ flex: 1 }}>
                            <h3 className="header__title">All Protocols</h3>
                            <p>{filterdUsers?.length} total Users</p>
                        </div>
                        <div className="right d-flex justify-content-end">
                            <CustomPrimaryButton onClick={setCreateModalOpen.bind(undefined, true)}>
                                <Plus size={14} strokeWidth={3} />
                                <span>Add Protocol</span>
                            </CustomPrimaryButton>
                        </div>
                    </HeaderStyleWrapper>
                </AppLayout.Header>
                <AppLayout.Body>
                    <AppLayout.TabHeader
                        links={links}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        handleChange={handleChange}
                        value={searchTerm}
                        handleSearch={handleSearch}
                        searchable
                        isSearchNotOnChange
                    />
                    <AppLayout.TabBody>
                        <TabContent activeTab={activeTab} className="tab__content">
                            <TabPane tabId={1}>
                                {filterdUsers.length ? (
                                    <ProtocolTable
                                        users={filterdUsers}
                                        meta={protocolsMeta}
                                        setActiveUser={setActiveUser}
                                        handleSetStatus={handleSetStatus}
                                        handleModal={setEditModalOpen}
                                    />
                                ) : (
                                    <p>There are no users</p>
                                )}
                            </TabPane>
                            {/* <TabPane tabId={2}>
                                {filterdUsers.length ? (
                                    <ProtocolTable
                                        users={filterdUsers}
                                        meta={protocolsMeta}
                                        setActiveUser={setActiveUser}
                                        handleSetStatus={handleSetStatus}
                                        handleModal={setEditModalOpen}
                                    />
                                ) : (
                                    <p>There are no users</p>
                                )}
                            </TabPane> */}
                        </TabContent>
                    </AppLayout.TabBody>
                </AppLayout.Body>
            </AppLayout.Wrapper>
        </AuthLayout>
    );
};
