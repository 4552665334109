import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AdminDashboardPage, AirportProtocolDashboardPage } from '.';
import { AuthLayout, DataTable, FlagTime, SignOutSwitchComponent } from '../../../components';
import { ApplicationState } from '../../../store';
import { ServiceThunk } from '../../../store/services/services.thunk';
import { UsersThunk } from '../../../store/users/users.thunk';
import { Roles } from '../../../util/constants';
import { DashboardPageStyleWrapper } from '../components/dashboard-page.style';

export const ClientDashboardPage: React.FC<{}> = (props) => {
    const dispatch = useDispatch();
    const { auth } = useSelector((state: ApplicationState) => state);
    const role = (auth.authUser && auth.authUser.role) || '';
    const access_level = [`${Roles.Admin}`, `${Roles.AirportManager}`, `${Roles.AirportProtocolCoordinator}`];

    React.useEffect(() => {
        dispatch(UsersThunk.userActivities(role));
    }, [role, dispatch]);

    React.useEffect(() => {
        dispatch(ServiceThunk.getAllServices());
        dispatch(UsersThunk.getClientBookingStats());
    }, [dispatch]);

    return (
        <AuthLayout>
            <DashboardPageStyleWrapper>
                {access_level.includes(role) && (
                    <React.Fragment>
                        <div className="d-flex flex-row top__content">
                            <div className="flag">
                                <FlagTime />
                            </div>
                            <div className="switch d-flex justify-content-end">
                                <SignOutSwitchComponent />
                            </div>
                        </div>
                        {role === Roles.AirportProtocolCoordinator ? (
                            <AirportProtocolDashboardPage />
                        ) : (
                            <AdminDashboardPage />
                        )}
                    </React.Fragment>
                )}
            </DashboardPageStyleWrapper>
        </AuthLayout>
    );
};
