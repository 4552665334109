import { Formik } from 'formik';
import React from 'react';
import { Plus } from 'react-feather';
import { useSelector } from 'react-redux';
import { Col, ModalBody, ModalFooter } from 'reactstrap';
import { CustomPrimaryButton } from '../../../components';
import { FormTextInput } from '../../../components/form';
import { CustomModal } from '../../../components/utilities/modal/modal';
import { CreateProtocolModel } from '../../../models/request';
import { ApplicationState } from '../../../store';
import { CreateProtocolRoleMap, Roles } from '../../../util/constants';
import { validateFormValues } from '../../../util/validation';

interface CreateUserModalProps {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    handleCreateUser: (userDetails: CreateProtocolModel) => void;
}

export const CreateUserModal: React.FC<CreateUserModalProps> = ({ isOpen, setIsOpen, handleCreateUser }) => {
    const { airports } = useSelector((state: ApplicationState) => state.airports);
    const { authUser: user } = useSelector((state: ApplicationState) => state.auth);

    const airpot = airports.find((airport) => airport.id === user?.airportId);

    return (
        <CustomModal isOpen={isOpen} setIsOpen={setIsOpen} title="Create Protocol">
            <Formik validate={validateFormValues} initialValues={new CreateProtocolModel()} onSubmit={handleCreateUser}>
                {({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <ModalBody>
                            <div className="form-row">
                                <div className="col-sm-6">
                                    <FormTextInput name="FirstName" label="First name " compact />
                                </div>
                                <div className="col-sm-6">
                                    <FormTextInput name="LastName" label="Last name " compact />
                                </div>
                            </div>
                            <div className="form-row">
                                <Col sm="6">
                                    <FormTextInput name="PhoneNumber" label="Phone Number " compact />
                                </Col>
                                <Col sm="6">
                                    <FormTextInput name="Email" label="Email " type="email" compact />
                                </Col>
                            </div>
                            <div className="form-row">
                                <Col sm="6">
                                    <FormTextInput name="Role" label="Role" as="select" compact>
                                        <option value="">SELECT A ROLE *</option>
                                        <option value={CreateProtocolRoleMap.Protocol}>Protocol</option>
                                        {/* {Object.entries(CreateUserRolesMap).map(([role, value], i) => (
                                            <option value={role} key={i} disabled={!role}>
                                                {value}
                                            </option>
                                        ))} */}
                                    </FormTextInput>
                                </Col>
                                <Col sm="6">
                                    <FormTextInput name="AirportId" label="Airport" as="select" compact>
                                        <option value="">SELECT AN AIRPORT *</option>
                                        {user?.role !== Roles.Admin ? (
                                            <option value={airpot?.id}>{airpot?.name}</option>
                                        ) : (
                                            airports.map(({ id, name }) => (
                                                <option value={id} key={id}>
                                                    {name}
                                                </option>
                                            ))
                                        )}
                                    </FormTextInput>
                                </Col>
                            </div>
                        </ModalBody>
                        <ModalFooter className="d-flex justify-content-between align-items-center">
                            <CustomPrimaryButton type="submit">
                                <Plus size={14} strokeWidth={3} />
                                <span>Create protocol</span>
                            </CustomPrimaryButton>
                            <button
                                className="modal-footer__button"
                                type="button"
                                onClick={setIsOpen.bind(undefined, false)}
                            >
                                <span>Close</span>
                            </button>
                        </ModalFooter>
                    </form>
                )}
            </Formik>
        </CustomModal>
    );
};
