import { store } from '..';
import { AuthThunk } from '../store/auth/auth.thunk';
import { NotificationsThunk } from '../store/notifications/notifications.thunk';
import { NotificationTypes } from './constants';
import { extract, save } from './local-storage';

export interface QueryParams extends Record<string, any> {}

export interface ApiResponse<T> {
    ok: boolean;
    status: number;
    message: string;
    meta?: {
        perPage: number;
        totalPages: number;
        pageSize: number;
        totalCount: number;
        pageNumber: number;
        nextPage: number;
        previousPage: number;
        firstPage: number;
        lastPage: number;
    };
    data?: T;
    errors?: Record<string, string[]>;
}

interface ApiRequest<T = any> {
    body?: T;
    query?: QueryParams;
    model?: T;
}

export enum HttpMethods {
    Get = 'GET',
    Post = 'POST',
    Patch = 'PATCH',
    Delete = 'DELETE',
}

function LogRequests() {
    const styles = `
        color: green;
        font-weight: bold;
        font-size: 14px;
        letter-spacing: 1px;
    `;

    return function (target: Function, _: string, descriptor: PropertyDescriptor) {
        const originalFunction: Function = descriptor.value;
        descriptor.value = async function (...args: any[]) {
            try {
                const [method, endpoint, request] = args;
                const response = await originalFunction.call(target, ...args);
                if (process.env.NODE_ENV !== 'production') {
                    console.log(`%c Made [${method}] API call to [${endpoint}]:`, styles, {
                        request,
                        response,
                    });
                }
                return response;
            } catch (error) {
                console.error(error);
            }
        };

        return descriptor;
    };
}

const LOCAL_STORAGE_TOKEN_KEY = 'API_TOKEN';

export class ApiClient {
    private static abortController = new AbortController();
    private static token: string | null = null;
    private static sessionTimeout: NodeJS.Timeout | null = null;
    public static timedOut: boolean = false;

    private static extractToken() {
        if (!this.token) {
            this.token = extract(LOCAL_STORAGE_TOKEN_KEY) as string;
        }
    }

    public static getBearerToken() {
        return `Bearer ${this.token}`;
    }

    public static getFetchConfig(formData?: boolean) {
        const host = `${window.location.protocol}//${window.location.host}`;
        const headers: Headers = new Headers();

        if (!formData) {
            headers.append('Accept', 'application/json');
            headers.append('Content-Type', 'application/json');
        }

        if (this.token) {
            headers.append('Authorization', this.getBearerToken());
        }

        const corsOptions: Partial<RequestInit> = {
            mode: 'cors',
        };

        const isOrigin = process.env.REACT_APP_ROOT_URL === host;

        const options: RequestInit = {
            headers,
            signal: this.abortController.signal,
            ...(isOrigin ? {} : corsOptions),
        };

        return options;
    }

    public static setToken(token: string) {
        this.token = token;
        save<string>(LOCAL_STORAGE_TOKEN_KEY, token);
    }

    public static unsetToken() {
        this.token = null;
        localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
        this.abort();
    }

    @LogRequests()
    private static async request<ResponseType = any, RequestType = any>(
        method: HttpMethods,
        endpoint: string,
        request?: ApiRequest<RequestType>,
        isFormData?: boolean
    ): Promise<ApiResponse<ResponseType>> {
        this.abortController = new AbortController();
        const base = process.env.REACT_APP_BASE_URL;
        this.extractToken();
        this.resetSessionTimeout();

        const requestContent: { body?: string | FormData } = {};

        if (request && request.query) {
            const query = Object.entries(request.query)
                .filter(([_, value]) => !!value)
                .map(([field, value]) => field + '=' + value)
                .join('&');
            endpoint += query ? '?' + query : '';
        }

        if (request && request.body) {
            if ('finalize' in request.body) {
                (request.body as any).finalize();
            }

            requestContent.body = isFormData ? (request.body as unknown as FormData) : JSON.stringify(request.body);
        }

        try {
            const options: RequestInit = {
                ...this.getFetchConfig(isFormData),
                method,
                ...requestContent,
            };

            const response = await fetch(base + '/' + endpoint, options);
            const responseContent = (await response.json()) as ApiResponse<ResponseType>;

            if (response.status >= 400 && response.status !== 401) {
                NotificationsThunk.create(
                    NotificationTypes.Error,
                    responseContent.errors ?? responseContent.message
                )(store.dispatch);
            }

            if (response.status === 401) {
                AuthThunk.clearSession()(store.dispatch);
                this.unsetToken();
            }

            return {
                errors: responseContent.errors,
                data: responseContent.data,
                ok: response.status === 200, // 200 is Http OK status code
                status: response.status,
                meta: responseContent.meta,
                message: (responseContent as any).title ? (responseContent as any).title : responseContent.message,
            };
        } catch (error) {
            return {
                ok: false,
                status: 500,
                message: (error as any).message,
            };
        }
    }

    public static abort() {
        this.abortController.abort();
        this.cancelTimeout();
    }

    public static resetSessionTimeout() {
        this.timedOut = false;

        if (this.sessionTimeout) clearTimeout(this.sessionTimeout);
        if (this.token) {
            const TIMEOUT_DURATION = process.env.NODE_ENV === 'development' ? 10 * 60 * 1000 : 20 * 60 * 1000; // 20 Minutes
            this.sessionTimeout = setTimeout(() => {
                AuthThunk.clearSession()(store.dispatch);
                this.abort();
            }, TIMEOUT_DURATION);
        }
    }

    public static cancelTimeout() {
        if (this.sessionTimeout) clearTimeout(this.sessionTimeout);
        this.sessionTimeout = null;
    }

    public static get<T>(endpoint: string, query?: QueryParams) {
        return this.request<T>(HttpMethods.Get, endpoint, { query });
    }

    public static post<RequestType, ResponseType = any>(endpoint: string, body?: RequestType, isFormData?: boolean) {
        return this.request<ResponseType>(HttpMethods.Post, endpoint, { body }, isFormData);
    }

    public static patch<RequestType, ResponseType = RequestType>(
        endpoint: string,
        body?: RequestType,
        isFormData?: boolean
    ) {
        return this.request<ResponseType, RequestType>(HttpMethods.Patch, endpoint, { body }, isFormData);
    }

    public static delete<T = any>(endpoint: string) {
        return this.request<T>(HttpMethods.Delete, endpoint);
    }
}

ApiClient.resetSessionTimeout();
