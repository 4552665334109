import { Formik } from 'formik';
import React, { Dispatch, SetStateAction } from 'react';
import { Loader } from 'react-feather';
import { useDispatch } from 'react-redux';
import { Col } from 'reactstrap';
import { CustomPrimaryButton } from '..';
import { FundWalletModel } from '../../models/request';
import { TransactionsService } from '../../services';
import { NotificationsThunk } from '../../store';
import { NotificationTypes } from '../../util/constants';
import { history } from '../../util/history';
import { convertCurrencyToDecimal } from '../../util/misc/formatters';
import { FormTextInput } from '../form';
import { CustomModal } from '../utilities/modal/modal';

interface Props {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    onComplete?: (value: number) => void;
    client: string;
    isCreatingBooking?: boolean;
}

export const FundWalletModal: React.FC<Props> = ({ isOpen, setIsOpen, onComplete, client }) => {
    const dispatch = useDispatch();

    const currencyMask = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value;
        value = value.replace(/\D/g, '');
        value = value.replace(/(\d)(\d{2})$/, '$1. $2');
        value = value.replace(/(?=(\d{3})+(\D))\B/g, ',');
        e.target.value = value;
        return e;
    };

    return (
        <CustomModal isOpen={isOpen} title="Fund Wallet" setIsOpen={setIsOpen}>
            <div className="py-4">
                <Formik
                    initialValues={new FundWalletModel()}
                    onSubmit={async (model, { setSubmitting }) => {
                        setSubmitting(true);
                        // if (!model.amount || convertCurrencyToDecimal(model.amount) < 500000) {
                        if (!model.amount) {
                            // for test on prod
                            dispatch(
                                NotificationsThunk.create(
                                    NotificationTypes.Error,
                                    'Amount must not be lower then NGN 500,000.00'
                                )
                            );
                            setSubmitting(false);
                        } else {
                            model.clientId = client;
                            await TransactionsService.fundWallet(model)
                                .then((res) => {
                                    if (res.ok) {
                                        dispatch(
                                            NotificationsThunk.create(
                                                NotificationTypes.Success,
                                                `Wallet funded successfully`
                                            )
                                        );
                                        onComplete?.(convertCurrencyToDecimal(model.amount));
                                    }
                                })
                                .catch(() =>
                                    dispatch(
                                        NotificationsThunk.create(NotificationTypes.Error, 'Wallet funding failed')
                                    )
                                )
                                .finally(() => {
                                    setSubmitting(false);
                                    setIsOpen(false);
                                });
                        }
                    }}
                >
                    {({ handleSubmit, isSubmitting, isValid, setFieldValue, values }) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <Col sm={9} lg={9} className="mx-auto">
                                    <FormTextInput
                                        amount={values.amount}
                                        handleChange={(e: any) => {
                                            e.preventDefault();
                                            const { value } = currencyMask(e).target;
                                            setFieldValue('amount', value);
                                        }}
                                        name="amount"
                                        label="Amount (minimum NGN 500,000.00)"
                                        currency
                                    />
                                </Col>

                                <div className="d-flex justify-content-center mt-3">
                                    <CustomPrimaryButton disabled={!isValid} type="submit">
                                        {isSubmitting ? <Loader /> : <span>Fund Wallet</span>}
                                    </CustomPrimaryButton>
                                </div>
                            </form>
                        );
                    }}
                </Formik>
            </div>
        </CustomModal>
    );
};
