import React from 'react';
import { AlignJustify, LogOut } from 'react-feather';
import { NavLink } from 'react-router-dom';
import { AuthContext } from '../../../context';
import { useSidebar } from '../sidebar-hook';
import { TopNavigationStyle } from './top-navigation-style';

export const TopNavigation: React.FC<{}> = (props) => {
    const { user, canAccess } = React.useContext(AuthContext.Context);
    const [sidebar, setSidebar] = React.useState(false);
    const { links, handleLogout } = useSidebar();
    const toggleSidebar = React.useCallback(() => {
        setSidebar(!sidebar);
    }, [sidebar]);

    return (
        <TopNavigationStyle>
            <nav className="d-flex flex-row">
                <span className="nav__left d-flex justify-content-start">
                    <AlignJustify size={23} onClick={toggleSidebar} />
                </span>
                <span className="nav__image d-flex justify-content-center">
                    <img alt="logo" width={60} src={require('../../../assets/images/logo_faan.png')} />
                </span>
                <span className="nav__right d-flex justify-content-end">{/* <Bell size={23} /> */}</span>
            </nav>
            {sidebar && (
                <div className="mobile__sidebar__wrapper" onClick={toggleSidebar}>
                    <div className="mobile__sidebar">
                        <img alt="logo" src={require('../../../assets/images/logo_faan.png')} width={84} />
                        <div className="navWrapper">
                            {links
                                .filter((_) => !_.inactive)
                                .filter((_) => (_.role ? canAccess(_.role, true) : true))
                                .map(({ name, link, icon: Icon, inactive }, i) => {
                                    return (
                                        <NavLink
                                            to={link}
                                            className="nav-item"
                                            onClick={(e: React.SyntheticEvent) => {
                                                if (inactive) {
                                                    e.preventDefault();
                                                }
                                            }}
                                            key={i}
                                        >
                                            <div className="nav-item__icon-container">
                                                <Icon className="nav-item__icon" />
                                            </div>
                                            <div className="nav-item__name">
                                                <span>{name}</span>
                                            </div>
                                        </NavLink>
                                    );
                                })}
                        </div>
                        <div className="profile-box d-flex">
                            <div className="p-2 flex-shrink-1">
                                <img
                                    className="profile-box__img"
                                    alt="user"
                                    src={require('../../../assets/images/personal.png')}
                                />
                            </div>
                            <div className="d-flex align-items-center p-1 w-100 contentBox">
                                <span>{user && (user.lastName || user.business_Name)}</span>
                            </div>
                            <div className="p-2 flex-shrink-1">
                                <div className="box" onClick={handleLogout}>
                                    <div className="divCircle">
                                        <LogOut size={12} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </TopNavigationStyle>
    );
};
