import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';
import Check from '../../../assets/images/check (1).svg';
import { EntryFormContainer } from '../../../components';
import { Breadcrumb, Breadcrumbs } from '../../../components/breadcrumbs/breadcrumbs';
import { FormStepper } from '../../../components/form';
import Layout, { StickyBackground } from '../../../components/layout';
import { AuthThunk } from '../../../store/auth/auth.thunk';

export const VerifyEmailPage: React.FC = () => {
    const breadcrumbs: Breadcrumb[] = [
        { name: 'Home', path: '/' },
        { name: 'Create account', path: window.location.href },
    ];

    const [successful, setSuccessful] = React.useState<boolean | null>(null);
    const dispatch = useDispatch();
    const match = useRouteMatch();

    const handleResult = React.useCallback(
        (value: boolean) => {
            setSuccessful(value);
        },
        [setSuccessful]
    );

    useEffect(() => {
        dispatch(
            AuthThunk.verifyEmail((match as any).params.code, {
                onSuccess: handleResult.bind(undefined, true),
                onFail: handleResult.bind(undefined, false),
            })
        );
    }, [dispatch, match, handleResult]);

    return (
        <Layout>
            <StickyBackground />
            <div className="px-5 py-3">
                <Breadcrumbs breadcrumbs={breadcrumbs} />
                <EntryFormContainer>
                    {successful === null ? null : (
                        <div className="d-flex flex-column align-items-center">
                            <header className="form__header">
                                {successful ? (
                                    <React.Fragment>
                                        <div
                                            className="d-flex justify-content-center align-items-center"
                                            style={{
                                                width: 148,
                                                height: 148,
                                                background: '#e6f6ec',
                                                borderRadius: '100%',
                                            }}
                                        >
                                            <img src={Check} width={80} alt="registration successful check icon" />
                                        </div>
                                        <div className="mt-4">
                                            <h2>
                                                <b>Email verified successfully!</b>
                                            </h2>
                                        </div>
                                        <FormStepper steps={1} currentStep={1} />
                                    </React.Fragment>
                                ) : (
                                    <div className="mt-4">
                                        <h2>
                                            <b>Email verification failed</b>
                                        </h2>
                                    </div>
                                )}
                            </header>
                            <main className="mt-4">
                                <p className="text-center">
                                    {successful ? 'Your email has been verified.' : 'Email verfication code invalid'}
                                </p>
                            </main>
                            <footer className="d-flex flex-column align-items-center">
                                <Link to="/login">
                                    <u>Go to Login Page </u>
                                </Link>
                            </footer>
                        </div>
                    )}
                </EntryFormContainer>
            </div>
        </Layout>
    );
};
