import React from 'react';
import { CustomPrimaryButton, TabLink } from '../../../../../components';
import styled from 'styled-components';
import { Send } from 'react-feather';
import { SelectDropDown } from './dropdown';

const TabsWrapper = styled.div`
    .export {
        border-radius: 8px;
        padding: 1.2rem 0.8rem;
    }
`;

const TabHeader = styled.div`
    width: 400px;
    cursor: pointer;
    border-bottom: 2px solid #dcdcdc;

    .text {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.1px;
        color: #000000;
    }

    .active {
        border-bottom: 2px solid #18a956;
    }
`;

export const TabBody = styled.div`
    background: #f6fcf8;
    border-radius: 8px;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3), 0px 2px 0px 0px rgba(0, 0, 0, 0.15);
    margin-top: 2rem;

    .size {
        // height: 327px;
        border: 2px solid red;
    }

    & .select {
        float: right;
        margin-top: 0.5rem;
    }
`;

export enum Title {
    Revenue = 1,
    Bookings = 2,
    Passenger = 3,
}

export const TabsHeader: React.FC<{ tabs: TabLink[]; activeTab: number; setActiveTab: (tab: number) => void }> = ({
    tabs,
    activeTab,
    setActiveTab,
}) => {
    return (
        <TabsWrapper className="d-flex justify-content-between align-items-center flex-wrap mt-5">
            <TabHeader className="d-flex justify-content-between">
                {tabs.map(({ name, index }) => (
                    <div
                        key={index}
                        className={`${activeTab === index && 'active'} py-2 px-3`}
                        onClick={() => setActiveTab(index)}
                    >
                        <span className="text">{name}</span>
                    </div>
                ))}
            </TabHeader>
            {/* <CustomPrimaryButton onClick={() => { }} className="export inverted small">
                <Send size={10} strokeWidth={3} />
                <span>{'Export'}</span>
            </CustomPrimaryButton> */}
        </TabsWrapper>
    );
};

export const TabsBody: React.FC<{ setInterval?: (val: string) => void; interval: string }> = ({
    children,
    setInterval,
    interval,
}) => {
    // const options = ['Daily', 'Monthly', 'Yearly'];

    return (
        <TabBody className="pt-2">
            {/* <div className="d-flex justify-content-end">
                <SelectDropDown options={options} setInterval={setInterval} interval={interval} />
            </div> */}
            {children}
        </TabBody>
    );
};
