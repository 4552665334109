import { registerDecorator, ValidationArguments, ValidationOptions } from 'class-validator';

export function IsOptionalWhenPortharcourtServiceFalse(validationOptions?: ValidationOptions) {
    return function (object: Object, propertyName: string) {
        registerDecorator({
            name: 'isOptionalWhenPortharcourtServiceFalse',
            target: object.constructor,
            propertyName: propertyName,
            options: validationOptions,
            validator: {
                validate(value: any, args: ValidationArguments) {
                    const obj = args.object as any;
                    if (obj.portharcourtService) {
                        return typeof value === 'number' && value > 0;
                    }
                    return true;
                },
                defaultMessage(args: ValidationArguments) {
                    return `Default ODC must be greater than zero when Portharcourt Service is true`;
                },
            },
        });
    };
}
