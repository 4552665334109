import { IsBoolean, IsPositive, IsOptional } from 'class-validator';
import { Service } from '../../response';
import { BaseModel } from '../base-model';
import { CreateServiceModel } from './create-service.model';
import { IsOptionalWhenPortharcourtServiceFalse } from '../../../util/validation/porthacourtvalidation';

export class UpdateServiceModel extends CreateServiceModel implements BaseModel {
    @IsPositive({ message: 'Invalid id' })
    public Id: number = 0;

    @IsBoolean({ message: 'Must be active or inactive' })
    public Status: boolean = true;

    @IsOptional()
    @IsBoolean({ message: 'Must be a boolean value' })
    public portharcourtService?: boolean;

    @IsOptional()
    @IsOptionalWhenPortharcourtServiceFalse({ message: 'Must be greater than zero' })
    public defaultOdc?: number;

    constructor(service: Service) {
        super();

        if (service) {
            this.Id = service.id;
            this.Name = service.name;
            this.Description = service.description;
            this.Price = service.price;
            this.Status = service.status;
            this.ServiceTypeId = service.serviceTypeId;
            this.portharcourtService = service.portharcourtService;
            this.defaultOdc = service.defaultOdc;
            return;
        }
    }
}
