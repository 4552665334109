import React, { Dispatch, SetStateAction } from 'react';
import { Loader } from 'react-feather';
import { useDispatch } from 'react-redux';
import { Col, ModalBody, ModalFooter } from 'reactstrap';
import { Formik } from 'formik';
import { CustomPrimaryButton } from '..';
import { TransactionsService } from '../../services';
import { NotificationsThunk, TransactionsThunk } from '../../store';
import { NotificationTypes } from '../../util/constants';
import { CustomModal } from '../utilities/modal/renewal-modal';

interface Props {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    onComplete?: () => void;
    client_id: string;
    id?: number;
    isCreatingBooking?: boolean;
    pageNumber: number;
}

export const PaymentModal: React.FC<Props> = ({ isOpen, setIsOpen, onComplete, client_id, id, pageNumber }) => {
    const dispatch = useDispatch();

    return (
        <CustomModal isOpen={isOpen} setIsOpen={setIsOpen} size="lg" title="Payment">
            <div className="py-4">
                <Formik
                    initialValues={{ client_id: client_id || '', id: id || 0 }}
                    onSubmit={async (values, { setSubmitting }) => {
                        setSubmitting(true);
                        try {
                            const res = await TransactionsService.payment(values.client_id, values.id);
                            if (res.ok) {
                                dispatch(NotificationsThunk.create(NotificationTypes.Success, `Payment successful`));
                                if (client_id !== undefined) {
                                    dispatch(TransactionsThunk.fetchPayRenewalId(client_id, pageNumber));
                                } else {
                                    console.error('Client is undefined. Unable to dispatch action.');
                                }
                                if (onComplete) onComplete();
                            } else {
                                dispatch(NotificationsThunk.create(NotificationTypes.Error, 'Payment failed'));
                            }
                        } catch (error) {
                            console.error('Error occurred:', error);
                            dispatch(NotificationsThunk.create(NotificationTypes.Error, 'An error occurred'));
                        } finally {
                            setSubmitting(false);
                            setIsOpen(false);
                        }
                    }}
                >
                    {({ handleSubmit, isSubmitting, isValid }) => (
                        <form onSubmit={handleSubmit}>
                            <ModalBody>
                                <div className="form-row mt-3">
                                    <Col sm="12" className="mx-auto">
                                        <span>
                                            Are you sure you want to pay N200,000 to renew this client's registration?
                                        </span>
                                    </Col>
                                </div>
                            </ModalBody>
                            <ModalFooter className="d-flex justify-content-center align-items-center">
                                <CustomPrimaryButton disabled={!isValid} type="submit" className="mr-8">
                                    {isSubmitting ? <Loader /> : <span>Pay Now</span>}
                                </CustomPrimaryButton>
                                <CustomPrimaryButton
                                    disabled={!isValid}
                                    onClick={() => setIsOpen(false)}
                                    style={{ backgroundColor: 'white', color: 'green' }}
                                >
                                    <span>Cancel</span>
                                </CustomPrimaryButton>
                            </ModalFooter>
                        </form>
                    )}
                </Formik>
            </div>
        </CustomModal>
    );
};
