import { Formik } from 'formik';
import { isMatch } from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, ModalBody, ModalFooter } from 'reactstrap';
import { CustomPrimaryButton } from '../../../components';
import { FormTextInput } from '../../../components/form';
import { CustomModal } from '../../../components/utilities/modal/modal';
import { EditUserModel } from '../../../models/request/users';
import { User } from '../../../models/response/users';
import { ApplicationState } from '../../../store';
import { NotificationsThunk } from '../../../store/notifications/notifications.thunk';
import { CreateProtocolRoleMap, NotificationTypes, Roles } from '../../../util/constants';
import { validateFormValues } from '../../../util/validation';

interface EditModalProps {
    editModalOpen: boolean;
    setEditModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    activeUser: User;
    handleUpdateUser: (userDetails: EditUserModel) => void;
}

export const EditUserModal: React.FC<EditModalProps> = ({
    editModalOpen,
    setEditModalOpen,
    activeUser,
    handleUpdateUser,
}) => {
    const { airports } = useSelector((state: ApplicationState) => state.airports);
    const { authUser: user } = useSelector((state: ApplicationState) => state.auth);

    const dispatch = useDispatch();

    const airpot = airports.find((airport) => airport.id === user?.airportId);

    return (
        <CustomModal isOpen={editModalOpen} setIsOpen={setEditModalOpen} title="Edit Protocol">
            <Formik
                validate={validateFormValues}
                initialValues={new EditUserModel(activeUser)}
                onSubmit={(userDetails) => {
                    if (isMatch(new EditUserModel(activeUser), userDetails)) {
                        dispatch(NotificationsThunk.create(NotificationTypes.Info, 'No changes were made'));

                        return setEditModalOpen(false);
                    }

                    handleUpdateUser(userDetails);
                }}
            >
                {({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <ModalBody>
                            <div className="form-row">
                                <div className="col-sm-6">
                                    <FormTextInput name="Firstname" label="First name" compact />
                                </div>
                                <div className="col-sm-6">
                                    <FormTextInput name="LastName" label="Last name" compact />
                                </div>
                            </div>

                            <div className="form-row">
                                <Col sm="6">
                                    <FormTextInput name="PhoneNumber" label="Phone Number" compact />
                                </Col>
                                <Col sm="6">
                                    <FormTextInput disabled name="Email" label="Email" compact />
                                </Col>
                            </div>
                            <div className="form-row">
                                <Col sm="6">
                                    <FormTextInput name="Role" label="Role" as="select" compact>
                                        <option value="">SELECT A ROLE</option>
                                        <option value={CreateProtocolRoleMap.Protocol}>Protocol</option>
                                        {/* {Object.entries(CreateUserRolesMap).map(([role, value], i) => (
                                            <option value={role} key={i} disabled={!role}>
                                                {value}
                                            </option>
                                        ))} */}
                                    </FormTextInput>
                                </Col>
                                <Col sm="6">
                                    <FormTextInput name="AirportId" label="Airport" as="select" compact>
                                        <option value="">SELECT AN AIRPORT</option>
                                        {user?.role !== Roles.Admin ? (
                                            <option value={airpot?.id}>{airpot?.name}</option>
                                        ) : (
                                            airports.map(({ id, name }) => (
                                                <option value={id} key={id}>
                                                    {name}
                                                </option>
                                            ))
                                        )}
                                    </FormTextInput>
                                </Col>
                            </div>
                        </ModalBody>
                        <ModalFooter className="d-flex justify-content-between align-items-center">
                            <CustomPrimaryButton type="submit">
                                {/* <Plus size={14} strokeWidth={3} /> */}
                                <span>Update Protocol</span>
                            </CustomPrimaryButton>
                            <button
                                className="modal-footer__button"
                                type="button"
                                onClick={setEditModalOpen.bind(undefined, false)}
                            >
                                <span>Close</span>
                            </button>
                        </ModalFooter>
                    </form>
                )}
            </Formik>
        </CustomModal>
    );
};
