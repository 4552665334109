import { PBooking } from '../../models/response/bookings/pbooking';
import { ApiResponse } from '../../util/api-client';
import { ReduxAction } from '../common/redux-action';

export interface PBookingsState {
    booking: { serviceId: 0; airportId: 0; clientId: ''; additionalOdc: 0 };
    meta: ApiResponse<PBooking>['meta'];
}

export enum PBookingActionNames {
    GET_ALL = 'PBOOKING_GET_ALL',
    GET_ONE = 'PBOOKING_GET_ONE',
    CLEAR = 'PBOOKING_CLEAR',
    ADD = 'PBOOKING_ADD',
    UPDATE = 'PBOOKING_UPDATE',
}

export interface AddPBookingAction extends ReduxAction<typeof PBookingActionNames.ADD, PBooking> {}
