import {
    Allow,
    Equals,
    IsArray,
    IsEnum,
    IsISO8601,
    IsPositive,
    MinLength,
    Validate,
    ValidateNested,
} from 'class-validator';
import { Service } from '../../response';
import { BaseModel } from '../base-model';

export class CreatePorthacourtBooking implements BaseModel {
    public clientId: string = '';

    @IsPositive({ message: 'Invalid airport selection' })
    public AirportId: number = 24;

    public Service?: Service;

    @Allow()
    public ServiceId: number = 0;

    @Allow()
    public additionalOdc?: number = 0;

    @IsPositive({ message: 'Must be greater than zero' })
    public defaultOdc?: number = 4;

    @Allow()
    public additionalServiceId?: number = 0;
    constructor(service?: Service) {
        this.Service = service;

        if (service) {
            this.ServiceId = service.id;
        }
    }

    public beforeValidation?() {
        this.AirportId = +this.AirportId;
        this.clientId = this.clientId;
        this.additionalOdc =
            this.additionalOdc !== null && this.additionalOdc !== undefined && !isNaN(this.additionalOdc)
                ? parseInt(this.additionalOdc.toString())
                : 0;
    }
}
