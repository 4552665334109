import moment from 'moment';
import React from 'react';
import { MoreVertical } from 'react-feather';
import { Link } from 'react-router-dom';
import { Cell } from 'react-table';
import { CustomDropdown, CustomDropdownItem, CustomPrimaryButton, DataTable } from '../../../components';
import { PresentationalFormSwitch } from '../../../components/form';
import { User } from '../../../models/response';
import { ApiResponse } from '../../../util/api-client';
import { ClientStatuses } from '../../../util/constants';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import { Roles } from '../../../util/constants';

interface UsersTableProps {
    clients: User[];
    meta?: ApiResponse<User[]>['meta'];
    setUpdateModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setActiveClient: React.Dispatch<React.SetStateAction<User | undefined>>;
    handleSetStatus: (...args: any[]) => void;
}

export const ClientsTable: React.FC<UsersTableProps> = ({ clients, meta, handleSetStatus }) => {
    const { authUser } = useSelector((state: ApplicationState) => state.auth);

    return (
        <DataTable
            hideHead
            columns={[
                {
                    accessor: 'dateCreated',
                    Cell: ({ row: { original } }: Cell<User>) => {
                        return <b>{moment(original.dateCreated).format('Do MMMM, YYYY')}</b>;
                    },
                },
                {
                    accessor: 'business_Name',
                    Cell: ({ row: { original: user } }: Cell<User>) => {
                        return user.firstName ?? user.business_Name;
                    },
                },
                {
                    accessor: 'email',
                },
                {
                    accessor: 'business_Nature',
                },
                {
                    accessor: 'profile_Status',
                    Cell: ({ row: { original: user } }: Cell<User>) => {
                        return <span style={{ color: '#D09611' }}>{user.profile_Status}</span>;
                    },
                },
                {
                    Header: 'Actions',
                    Cell: ({ row: { original: user } }: Cell<User>) => {
                        return (
                            <React.Fragment>
                                <CustomPrimaryButton className="small inverted">
                                    <Link
                                        to={`${
                                            [ClientStatuses.New, ClientStatuses.Processing].includes(
                                                user.profile_Status
                                            )
                                                ? `/app/client/approval/${user.id}`
                                                : `/app/client/${user.id}`
                                        }`}
                                        style={{
                                            color: 'var(--primary-color)',
                                        }}
                                    >
                                        <span>
                                            {[ClientStatuses.New, ClientStatuses.Processing].includes(
                                                user.profile_Status
                                            )
                                                ? 'View Request'
                                                : 'View Client'}
                                        </span>
                                    </Link>
                                </CustomPrimaryButton>
                                {[Roles.Admin, Roles.AirportManager].includes(authUser?.role as any) && (
                                    // {authUser?.role !== Roles.AirportProtocolCoordinator && (
                                    <CustomDropdown
                                        triggerComponent={() => {
                                            return (
                                                <CustomPrimaryButton className="small">
                                                    <MoreVertical size={12} />
                                                </CustomPrimaryButton>
                                            );
                                        }}
                                    >
                                        {/* {authUser && valid_access.includes(authUser.role) && (
                                        <CustomDropdownItem
                                            onClick={() => {
                                                setActiveClient(user);
                                                setUpdateModalOpen(true);
                                            }}
                                        >
                                            <span>Edit Client</span>
                                        </CustomDropdownItem>
                                    )} */}

                                        <CustomDropdownItem
                                            onClick={handleSetStatus.bind(undefined, user, !user.account_Status)}
                                        >
                                            <span>{user.account_Status ? 'Deactivate' : 'Activate'}</span>
                                            <PresentationalFormSwitch checked={user.account_Status} />
                                        </CustomDropdownItem>
                                    </CustomDropdown>
                                )}
                            </React.Fragment>
                        );
                    },
                },
            ]}
            data={clients}
            meta={meta}
        />
    );
};
