import React from 'react';
import { ClientServiceCardStyleWrapper } from './service-card.style';

type Props = {
    title: string;
    to: string;
    desc: string;
    price: string;
};

export const ClientServiceCardComponent: React.FC<Props> = ({ title, to, desc, price }) => {
    return (
        <ClientServiceCardStyleWrapper to={to}>
            <div className="iconBox">
                <img alt="departure" src={require('../../../assets/images/departures.svg')} />
            </div>
            <h6>
                <strong>{title}</strong>
            </h6>
            <p>{desc}</p>
            <p>{price}</p>
            <span>
                Book Service <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
            </span>
        </ClientServiceCardStyleWrapper>
    );
};
