import { Formik } from 'formik';
import { isMatch } from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, ModalBody, ModalFooter } from 'reactstrap';
import { CustomPrimaryButton } from '../../../../components';
import { FormTextInput } from '../../../../components/form';
import { CustomModal } from '../../../../components/utilities/modal/modal';
import { EditUserModel } from '../../../../models/request/users';
import { User } from '../../../../models/response/users';
import { ApplicationState } from '../../../../store';
import { NotificationsThunk } from '../../../../store/notifications/notifications.thunk';
import { CreateUserRolesMap, NotificationTypes } from '../../../../util/constants';
import { validateFormValues } from '../../../../util/validation';

interface EditModalProps {
    editModalOpen: boolean;
    setEditModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    activeUser: User;
    handleUpdateUser: (userDetails: EditUserModel) => void;
}

export const EditUserModal: React.FC<EditModalProps> = ({
    editModalOpen,
    setEditModalOpen,
    activeUser,
    handleUpdateUser,
}) => {
    const { airports } = useSelector((state: ApplicationState) => state.airports);
    const dispatch = useDispatch();

    return (
        <CustomModal isOpen={editModalOpen} setIsOpen={setEditModalOpen} title="Edit User">
            <Formik
                validate={validateFormValues}
                initialValues={new EditUserModel(activeUser)}
                onSubmit={(userDetails) => {
                    if (isMatch(new EditUserModel(activeUser), userDetails)) {
                        dispatch(NotificationsThunk.create(NotificationTypes.Info, 'No changes were made'));

                        return setEditModalOpen(false);
                    }

                    handleUpdateUser(userDetails);
                }}
            >
                {({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <ModalBody>
                            <div className="form-row">
                                <div className="col-sm-6">
                                    <FormTextInput name="Firstname" label="First name" compact />
                                </div>
                                <div className="col-sm-6">
                                    <span style={{ fontSize: 14 }}>Middle Name</span>
                                    <FormTextInput name="MiddleName" compact />
                                </div>
                            </div>
                            <div className="form-row">
                                <Col sm="12">
                                    <FormTextInput name="LastName" label="Last name" compact />
                                </Col>
                            </div>
                            <div className="form-row">
                                <Col sm="12">
                                    <FormTextInput name="PhoneNumber" label="Phone Number" compact />
                                </Col>
                            </div>
                            <div className="form-row">
                                <Col sm="6">
                                    <FormTextInput name="Role" label="Role" as="select" compact>
                                        {Object.values(CreateUserRolesMap).map((role, i) => (
                                            <option value={role} key={i}>
                                                {role}
                                            </option>
                                        ))}
                                    </FormTextInput>
                                </Col>
                                <Col sm="6">
                                    <FormTextInput name="AirportId" label="Airport" as="select" compact>
                                        <option value="">SELECT AN AIRPORT</option>
                                        {airports.map(({ id, name }) => (
                                            <option value={id} key={id}>
                                                {name}
                                            </option>
                                        ))}
                                    </FormTextInput>
                                </Col>
                            </div>
                        </ModalBody>
                        <ModalFooter className="d-flex justify-content-between align-items-center">
                            <CustomPrimaryButton type="submit">
                                {/* <Plus size={14} strokeWidth={3} /> */}
                                <span>Update user</span>
                            </CustomPrimaryButton>
                            <button
                                className="modal-footer__button"
                                type="button"
                                onClick={setEditModalOpen.bind(undefined, false)}
                            >
                                <span>Close</span>
                            </button>
                        </ModalFooter>
                    </form>
                )}
            </Formik>
        </CustomModal>
    );
};
