import { Formik } from 'formik';
import { isMatch } from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Col, ModalBody, ModalFooter } from 'reactstrap';
import { CustomPrimaryButton } from '../../../../components';
import { FormTextInput } from '../../../../components/form';
import { CustomModal } from '../../../../components/utilities/modal/modal';
import { EditUserProfileModel } from '../../../../models/request';
import { Profile } from '../../../../models/response';
import { NotificationsThunk } from '../../../../store/notifications/notifications.thunk';
import { NotificationTypes } from '../../../../util/constants';
import { validateFormValues } from '../../../../util/validation';

interface EditUserProfileModalProps {
    editModalOpen: boolean;
    setEditModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    handleUpdateUser: (userDetails: EditUserProfileModel) => void;
    activeUser: Profile;
}

export const EditUserProfileModal: React.FC<EditUserProfileModalProps> = ({
    editModalOpen,
    setEditModalOpen,
    handleUpdateUser,
    activeUser,
}) => {
    const dispatch = useDispatch();

    return (
        <CustomModal isOpen={editModalOpen} setIsOpen={setEditModalOpen} title="Edit User">
            <Formik
                initialValues={new EditUserProfileModel(activeUser)}
                validate={validateFormValues}
                onSubmit={(userDetails) => {
                    if (isMatch(new EditUserProfileModel(activeUser), userDetails)) {
                        dispatch(NotificationsThunk.create(NotificationTypes.Info, 'No changes were made'));

                        return setEditModalOpen(false);
                    }

                    handleUpdateUser(userDetails);
                }}
            >
                {({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <ModalBody>
                            <div className="form-row">
                                <div className="col-sm-6">
                                    <FormTextInput name="firstname" label="First name " compact />
                                </div>
                                <span style={{ fontSize: 14 }}>Middle Name</span>
                                <FormTextInput name="MiddleName" compact />
                            </div>
                            <div className="form-row">
                                <Col sm="12">
                                    <FormTextInput name="lastName" label="Last name " compact />
                                </Col>
                            </div>
                            <div className="form-row">
                                <Col sm="12">
                                    <FormTextInput name="phoneNumber" label="Phone Number *" compact />
                                </Col>
                            </div>
                        </ModalBody>
                        <ModalFooter className="d-flex justify-content-between align-items-center">
                            <CustomPrimaryButton type="submit">
                                {/* <Plus size={14} strokeWidth={3} /> */}
                                <span>Update Profile</span>
                            </CustomPrimaryButton>
                            <button
                                className="modal-footer__button"
                                type="button"
                                onClick={setEditModalOpen.bind(undefined, false)}
                            >
                                <span>Close</span>
                            </button>
                        </ModalFooter>
                    </form>
                )}
            </Formik>
        </CustomModal>
    );
};
