import { IsEmail, Matches, MinLength } from 'class-validator';
import { BaseModel } from '../base-model';
import {
    CONTAINS_DIGIT,
    CONTAINS_LOWERCASE,
    CONTAINS_SPECIAL_CHARACTER,
    CONTAINS_UPPERCASE,
    PASSWORD_MIN_LENGTH,
} from './constants';

export class UserLoginModel implements BaseModel {
    @IsEmail(undefined, { message: 'Email is not valid' })
    public Email!: string;

    @MinLength(1, {
        message: `Password must not be empty`,
    })
    public Password: string;

    constructor() {
        this.Email = '';
        this.Password = '';
    }

    public beforeValidation() {}
}
