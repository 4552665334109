export enum BookingStatuses {
    Processing = 'Processing',
    PaymentReceived = 'Payment_Received',
    ServiceRendered = 'Service_Rendered',
    NoShow = 'No_Show',
    FlightCanceled = 'Flight_Canceled',
    Canceled = 'Canceled',
}

export const BookingStatusesMap = {
    [BookingStatuses.Processing]: BookingStatuses.Processing,
    [BookingStatuses.PaymentReceived]: 'Payment Received',
    [BookingStatuses.ServiceRendered]: 'Service Rendered',
    [BookingStatuses.NoShow]: 'No Show',
    [BookingStatuses.Canceled]: BookingStatuses.Canceled,
    [BookingStatuses.FlightCanceled]: BookingStatuses.FlightCanceled,
};

export const BookingStatusAdminAvailableState: Record<BookingStatuses, BookingStatuses[]> = {
    [BookingStatuses.Processing]: [
        BookingStatuses.ServiceRendered,
        BookingStatuses.NoShow,
        BookingStatuses.FlightCanceled,
        BookingStatuses.Canceled,
    ],
    [BookingStatuses.PaymentReceived]: [],
    [BookingStatuses.ServiceRendered]: [],
    [BookingStatuses.NoShow]: [],
    [BookingStatuses.Canceled]: [],
    [BookingStatuses.FlightCanceled]: [],
};
