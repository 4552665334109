import React from 'react';
import { Modal } from 'reactstrap';
import styled from 'styled-components';
import { ModalButton } from './modal-button';

const StyledModal = styled(Modal)`
    width: ${(props) => (props.customSize ? 'none' : '418px')};

    @media (max-width: 480px) {
        width: 100%;
        margin: 0;
    }

    .modal-content {
        border-radius: 20px !important;
    }

    .modal-header {
        padding: 20px 25px;
    }

    .modal-body {
        padding: 20px 25px;
        height: ${(props) => (props.lg || props.md ? '640px' : 'none')};
    }

    .modal-title {
        font-size: 14px;
        color: black;
        font-weight: bold;
        letter-spacing: 0px;
    }

    .modal-footer {
        border: none;
        padding: 30px 25px;
    }

    .modal-footer__button {
        color: var(--primary-color);
        padding: 0 !important;
        border: none !important;
        background: transparent !important;
        outline: none !important;
        font-size: 14px;
    }
`;

interface CustomModalProps {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    title: string;
    customSize?: boolean;
    size?: string;
}

export const CustomModal: React.FC<CustomModalProps> = ({ isOpen, setIsOpen, title, size, customSize, children }) => {
    const close = setIsOpen.bind(undefined, false);

    return (
        <StyledModal customSize={customSize} size={size} isOpen={isOpen} toggle={close}>
            <div className="modal-header d-flex justify-content-between align-items-center">
                <span className="modal-title">{title}</span>
                <ModalButton onClick={close} />
            </div>
            {children}
        </StyledModal>
    );
};
