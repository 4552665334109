import { Allow } from 'class-validator';
import { Airports } from '../../../util/constants';
import { User } from '../../response';
import { BaseModel } from '../base-model';
import { CreateUserModel } from './create-user.model';

export class EditUserModel extends CreateUserModel implements BaseModel {
    @Allow()
    public Id: String;

    constructor(user: User) {
        super();
        this.Id = user.id;
        this.Firstname = user.firstName;
        this.MiddleName = user.middleName;
        this.Email = user.email;
        this.LastName = user.lastName;
        this.PhoneNumber = user.phoneNumber;
        this.Role = user.role;
        this.AirportId = user.airportId ?? Airports.ABV;
    }
}
