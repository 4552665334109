import React, { useState } from 'react';
import { AccordionBody, AccordionHeader, AccordionItem, UncontrolledAccordion } from 'reactstrap';
import { CustomPrimaryButton } from '../utilities/custom-primary-button/custom-primary-button';
import './accordion-style.scss';

const FAQ = () => {
    return (
        <div>
            <UncontrolledAccordion defaultOpen={['1']} stayOpen className="mb-lg-9 mb-md-9 mb-sm-5 mb-6">
                <AccordionItem>
                    <AccordionHeader targetId="1">
                        {' '}
                        <strong>How to login</strong>{' '}
                    </AccordionHeader>
                    <AccordionBody accordionId="1">
                        <div className="bg-custom-green p-4">
                            <ol>
                                <li>On the FAAN APS landing page click on the button “LOGIN TO ACCOUNT”. </li>
                                <li>Type in your registered email and password</li>
                                <li>Fill the form correctly and click "NEXT STEP". </li>
                                <li>Click on Login.</li>
                            </ol>
                        </div>
                    </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItem>
                        <AccordionHeader targetId="2">
                            {' '}
                            <strong>How to Reset Password</strong>{' '}
                        </AccordionHeader>
                        <AccordionBody accordionId="2">
                            <div className="bg-custom-green p-4">
                                <ol>
                                    <li>On the FAAN APS landing page click on the button “LOGIN TO ACCOUNT”.</li>
                                    <li>Select “Forgot password” below the email and password field.</li>
                                    <li>Input your registered email address in the space provided.</li>
                                    <li>An email notification will be sent to your email</li>
                                    <li>Click on the link sent in the email</li>
                                    <li>The link redirects you to the Reset password page</li>
                                    <li>Input your preferred password and confirm the password then click on reset</li>
                                </ol>
                            </div>
                        </AccordionBody>
                    </AccordionItem>
                    {/* <AccordionItem>
                        <AccordionHeader targetId="3">
                            {' '}
                            <strong>How to View a Client</strong>{' '}
                        </AccordionHeader>
                        <AccordionBody accordionId="3">
                            <div className="bg-custom-green p-4">
                                <ol>
                                    <li>
                                        On the "All Clients" page click type the name of the client in the Search type
                                        the name of the client in the Search field and click “Submit”.
                                    </li>
                                    <li>Select “Forgot password” below the email and password field.</li>
                                    <li>On the selected client, click on “View Client”.</li>
                                </ol>
                            </div>
                        </AccordionBody>
                    </AccordionItem> */}
                    <AccordionItem>
                        <AccordionHeader targetId="4">
                            {' '}
                            <strong>How to View a Client</strong>{' '}
                        </AccordionHeader>
                        <AccordionBody accordionId="4">
                            <div className="bg-custom-green p-4">
                                <ol>
                                    <li>
                                        On the "All Clients" page type the name of the client in the Search field and
                                        click “Submit”.
                                    </li>
                                    <li>On the selected client, click on “View Client”.</li>
                                </ol>
                            </div>
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionHeader targetId="5">
                            {' '}
                            <strong>How to View a Client Booking</strong>{' '}
                        </AccordionHeader>
                        <AccordionBody accordionId="5">
                            <div className="bg-custom-green p-4">
                                <ol>
                                    <li>
                                        On the "All Clients" page type the name of the client in the Search field and
                                        click “Submit”.
                                    </li>
                                    <li>On the selected client, click on “View Client”.</li>
                                    <li>Click on the “Client’s Booking” tab.</li>
                                </ol>
                            </div>
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionHeader targetId="6">
                            {' '}
                            <strong>How to Book a Service</strong>{' '}
                        </AccordionHeader>
                        <AccordionBody accordionId="6">
                            <div className="bg-custom-green p-4">
                                <ol>
                                    <li>
                                        On the "All Clients" page type the name of the client in the Search field and
                                        click “Submit”.
                                    </li>
                                    <li>On the selected client, click on “View Client”.</li>
                                    <li>Click on the “Book Service”</li>
                                    <li>Fill out the client’s booking detail.</li>
                                    <li>Click on “Add Passenger”</li>
                                    <li>Check the “I agree to terms and conditions” and click “Continue</li>
                                    <li>Confirm details and you will get a successful message</li>
                                </ol>
                            </div>
                        </AccordionBody>
                    </AccordionItem>
                </AccordionItem>
                <AccordionItem>
                    <AccordionHeader targetId="8">
                        {' '}
                        <strong>How to Fund Wallet </strong>{' '}
                    </AccordionHeader>
                    <AccordionBody accordionId="8">
                        <div className="bg-custom-green p-4">
                            <ol>
                                <li>On the far right of the dashboard click on the button labelled "FUND" </li>
                                <li>
                                    On the pop-up form, insert an amount to fund wallet with and click "FUND WALLET".
                                    (Example: N500,000)
                                </li>
                                <li> An invoice will be sent to your email </li>
                                <li>
                                    {' '}
                                    Use the RRR number sent to your email to make payment to REMITA and your wallet will
                                    be funded with the amount on the generated invoice.{' '}
                                </li>
                            </ol>
                        </div>
                    </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                    <AccordionHeader targetId="9">
                        {' '}
                        <strong>How to add Multiple Passengers to a Booking</strong>{' '}
                    </AccordionHeader>
                    <AccordionBody accordionId="9">
                        <div className="bg-custom-green p-4">
                            <ol>
                                <li>
                                    On the "All Clients" page type the name of the client in the Search field and click
                                    “Submit”.
                                </li>
                                <li>On the selected client, click on “View Client”.</li>
                                <li>Click on the “Book Service”</li>
                                <li>Fill out the client’s booking details</li>
                                <li>Click on “Add Passengers”</li>
                                <li>Fill a new form for the additional passenger and click “Add Passenger”</li>
                                <li>Re-do Step 6 for as many passengers as you want to add</li>
                                <li>Click the “I agree to terms and conditions” and click “Continue”</li>
                                <li>Confirm details and you will get a successful message.</li>
                            </ol>
                        </div>
                    </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                    <AccordionHeader targetId="10">
                        {' '}
                        <strong>How do I Change a Booking Status?</strong>{' '}
                    </AccordionHeader>
                    <AccordionBody accordionId="10">
                        <div className="bg-custom-green p-4">
                            <ol>
                                <li>Click on "All Booking" page from your Dashboard</li>
                                <li>On the booking you want to change status for click “View Booking”</li>
                                <li>Select one or more Protocols Officers from the drop-down list</li>
                                <li>Click on “Assign Protocol”.</li>
                                <li>Click on “Change Status”</li>
                                <li>Select the status to be changed and click confirm.</li>
                            </ol>
                        </div>
                    </AccordionBody>
                </AccordionItem>
            </UncontrolledAccordion>
        </div>
    );
};

export default FAQ;
