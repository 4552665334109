import { Dispatch } from 'redux';
import { CreateServiceModel, UpdateServiceModel } from '../../models/request';
import { BookingsService, ServicesService } from '../../services';
import { NotificationTypes } from '../../util/constants';
import { generateLoader } from '../../util/misc';
import { NotificationsThunk } from '../notifications/notifications.thunk';
import { ServiceActions } from './services.actions';
import { history } from '../../util/history';
import { AddServiceAction, ClearAction, GetAllAction, GetOneAction, UpdateServiceAction } from './services.types';
import { Service } from '../../models/response';

export class ServiceThunk {
    public static getAllServices() {
        return async (dispatch: Dispatch<GetAllAction>) => {
            try {
                const response = await generateLoader(ServicesService.getAllServices(), dispatch, 'Fetching services');

                if (response.ok) {
                    dispatch(ServiceActions.allServices(response.data));
                    return;
                }

                throw new Error('Fetching services failed. Kindly Refresh');
            } catch (error) {
                // TODO: dispatch error to errorReducer
                // to be implemented
            }
        };
    }

    public static getService(service_id: any) {
        return async (dispatch: Dispatch<GetOneAction | ClearAction>) => {
            try {
                dispatch(ServiceActions.clearService());

                const response = await generateLoader(
                    ServicesService.getSingleService(service_id),
                    dispatch,
                    'Fetching service information'
                );

                if (response.ok) {
                    dispatch(ServiceActions.viewService(response.data));
                    return;
                }
            } catch (error) {}
        };
    }

    public static getClientService() {
        return async (dispatch: Dispatch<GetAllAction>) => {
            try {
                const response = await generateLoader(
                    ServicesService.getClientService(),
                    dispatch,
                    'Fetching service information'
                );

                if (response.ok) {
                    dispatch(ServiceActions.allServices(response.data));
                    return;
                }
            } catch (error) {}
        };
    }

    public static createService(serviceDetails: CreateServiceModel, closeModal?: () => void) {
        return async (dispatch: Dispatch<AddServiceAction>) => {
            try {
                const response = await generateLoader(
                    ServicesService.createService(serviceDetails),
                    dispatch,
                    'Creating service'
                );

                if (response.data) {
                    if (closeModal) closeModal();

                    NotificationsThunk.create(NotificationTypes.Success, 'Service created successfully')(dispatch);

                    dispatch(ServiceActions.addService(response.data));
                    return;
                }

                throw new Error('Service creation failed');
            } catch (error) {
                // TODO: dispatch error to errorReducer
                // to be implemented
            }
        };
    }

    public static updateService(serviceDetails: UpdateServiceModel, closeModal?: () => void) {
        return async (dispatch: Dispatch<UpdateServiceAction>) => {
            try {
                const response = await generateLoader(
                    ServicesService.updateService(serviceDetails),
                    dispatch,
                    'Upating service'
                );

                if (response.data) {
                    if (closeModal) closeModal();

                    NotificationsThunk.create(NotificationTypes.Success, 'Service updated successfully')(dispatch);

                    dispatch(ServiceActions.updateService(response.data));
                    return;
                }

                throw new Error('Service update failed');
            } catch (error) {
                // TODO: dispatch error to errorReducer
                // to be implemented
            }
        };
    }

    public static listBookings(serviceId: Service['id'], pageNumber?: number) {
        return async (dispatch: Dispatch) => {
            try {
                const response = await generateLoader(
                    BookingsService.getBookingsByServiceId(serviceId, pageNumber),
                    dispatch,
                    'Fetching bookings'
                );

                if (response.data) {
                    if (
                        response.meta &&
                        pageNumber &&
                        (pageNumber > response.meta.lastPage || pageNumber < response.meta.firstPage)
                    ) {
                        history.replace('?page=1');
                        return;
                    }

                    dispatch(ServiceActions.getServiceBookings(response.data, response.meta));
                    return;
                }

                throw new Error('Fetching bookings failedy. Kindly Refresh');
            } catch (error) {
                // TODO: dispatch error to errorReducer
                // to be implemented
            }
        };
    }
}
