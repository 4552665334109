import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { ModalButton } from '../../components/utilities/modal/modal-button';
import { FaAngleLeft } from 'react-icons/fa6';

interface FeedbackPopUpProps {
    isOpen: boolean;
    toggle: () => void;
    helpful?: string;
    process?: string;
    useAgain?: string;
    recoommendService?: string;
    rateQuality?: string;
    feedbackText?: string;
}

export const FeedbackModal: React.FC<FeedbackPopUpProps> = ({
    isOpen,
    toggle,
    helpful,
    process,
    useAgain,
    recoommendService,
    rateQuality,
    feedbackText,
}) => {
    return (
        <>
            <Modal isOpen={isOpen} toggle={toggle} size="lg">
                <div
                    style={{
                        display: 'flex',
                        width: '90%',
                        marginRight: 'auto',
                        marginLeft: 'auto',
                        flexDirection: 'column',
                    }}
                >
                    <div className="d-flex justify-content-between align-items-center py-4">
                        <button
                            style={{
                                width: '32px',
                                height: '32px',
                                borderRadius: '100%',
                                backgroundColor: '#18A95640',
                                border: 'none',
                            }}
                            onClick={toggle}
                        >
                            <FaAngleLeft />
                        </button>
                        <ModalButton onClick={toggle} className="font-weight-600 fs-5 border-0" />
                    </div>
                    <h2 style={{ fontSize: '20px', fontWeight: 'bold' }}>Client Feedback</h2>
                    <ModalBody>
                        <div className="row  d-flex flex-column">
                            <p style={{ fontSize: '16px' }}>Protocol staff were helpful, courteous and professional?</p>
                            <p style={{ fontSize: '14px', fontWeight: 'bold' }}> Response: {helpful}</p>
                        </div>

                        <div className="row  d-flex flex-column">
                            <p style={{ fontSize: '16px' }}>The entire protocol facilitation process was seamless?</p>
                            <p style={{ fontSize: '14px', fontWeight: 'bold' }}> Response: {process}</p>
                        </div>

                        <div className="row mb-1 d-flex flex-column">
                            <p style={{ fontSize: '16px' }}>
                                Will you use the FAAN Protocol service when next you are travelling?
                            </p>
                            <p style={{ fontSize: '14px', fontWeight: 'bold' }}> Response: {useAgain}</p>
                        </div>

                        <div className="row mb-1 d-flex flex-column">
                            <p style={{ fontSize: '16px' }}>
                                Will you recommend the FAAN Protocol service to your colleagues, friends and family?
                            </p>
                            <p style={{ fontSize: '14px', fontWeight: 'bold' }}> Response: {recoommendService}</p>
                        </div>

                        <div className="row mb-1 d-flex flex-column">
                            <p style={{ fontSize: '16px' }}>
                                How would you rate the overall quality of the protocol services provided by our team?
                            </p>
                            <p style={{ fontSize: '14px', fontWeight: 'bold' }}> Response: {rateQuality}</p>
                        </div>

                        <div className="row mb-1 d-flex flex-column">
                            <p style={{ fontSize: '16px' }}>
                                Do you have comments or suggestions that will help us improve our services?
                            </p>
                            <p style={{ fontSize: '14px', fontWeight: 'bold' }}> Response: {feedbackText}</p>
                        </div>
                    </ModalBody>
                </div>
            </Modal>
        </>
    );
};
