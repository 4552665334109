import { IsNotEmpty, IsPositive, MinLength } from 'class-validator';
import { BaseModel } from '../base-model';

export class CreateVcardModel implements BaseModel {
    @IsNotEmpty({ message: 'Field must not be empty!' })
    public cardNumber: string = '';

    @MinLength(3, {
        message: 'Must be at least $constraint1 character(s) long',
    })
    @IsNotEmpty({ message: 'Field must not be empty!' })
    public color: string = '';

    @IsNotEmpty({ message: 'Field must not be empty!' })
    public AirportId: number | string = 0;

    public beforeValidation() {}
}
