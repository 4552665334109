import React from 'react';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Cell, Column } from 'react-table';
import { CustomDropdown, CustomDropdownItem, CustomPrimaryButton, DataTable } from '../../../components';
import { Transaction } from '../../../models/response';
import { TransactionsActions, TransactionsThunk } from '../../../store';
import { ApiResponse } from '../../../util/api-client';
import { formatCurrency } from '../../../util/misc';
import { formatDate } from '../../../util/misc/formatters';
import { MoreVertical } from 'react-feather';
import { TransactionsService } from '../../../services/transactions.service';
import { NotificationsThunk } from '../../../store/notifications/notifications.thunk';
import { NotificationTypes } from '../../../util/constants';
interface Props {
    transactions: Transaction[];
    meta?: ApiResponse<Transaction[]>['meta'];
    isBooking?: boolean;
    refreshFundsServices?: () => void;
}

export const TransactionServicesTable: React.FC<Props> = ({ transactions, meta, isBooking, refreshFundsServices }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { search } = useLocation();
    const pageNumber = React.useMemo(() => +(queryString.parse(search) as any).page || 1, [search]);
    const [activeTab, setActiveTab] = React.useState(1);
    const [searchTerm, setSearchTerm] = React.useState('');
    const [dateFilter, setDateFilter] = React.useState({
        startDate: '',
        endDate: '',
    });

    const handleRefreshPayment = (invoiceNumber: string) => {
        TransactionsService.refreshInvoicePayment(invoiceNumber)
            .then((response: any) => {
                console.log('Payment refreshed successfully', response);
                dispatch(NotificationsThunk.create(NotificationTypes.Info, 'Invoice status updated successfully'));
                if (refreshFundsServices) {
                    refreshFundsServices();
                }
            })
            .catch((error: any) => {
                console.error('Error refreshing payment', error);
                dispatch(
                    NotificationsThunk.create(NotificationTypes.Error, 'Error Invoice Status Not Checked Successfully')
                );
            });
    };

    const extraBookingColumns: any = {
        Header: 'POS/Wallet',
    };

    const extraColumns: Column<Transaction>[] = isBooking
        ? [
              {
                  Header: 'BookedBy',
                  accessor: 'createdBy',
                  Cell: ({ row: { original } }: Cell<Transaction>) => {
                      return <span style={{ color: 'var(--primary-color)' }}>{original.createdBy ?? 'N/A'}</span>;
                  },
              },
              //   {
              //       Header: 'Airport',
              //       accessor: 'airportId',
              //       Cell: ({ row: { original } }: Cell<Transaction>) => {
              //           return <span style={{ color: 'var(--primary-color)' }}>{original.createdBy ?? 'N/A'}</span>;
              //       },
              //   },
              {
                  Header: 'RRR Number',
                  accessor: 'rrrNo',
                  Cell: ({ row: { original } }: Cell<Transaction>) => {
                      return <span style={{ color: 'var(--primary-color)' }}>{original.rrrNo ?? 'N/A'}</span>;
                  },
              },

              {
                  Header: 'POS/Wallet',
                  Cell: ({ row: { original } }: Cell<Transaction>) => {
                      return <span>{original.invoiceNo ? 'POS' : 'Wallet'}</span>;
                  },
              },
              {
                  Header: 'Invoice Number',
                  accessor: 'invoiceNo',
                  Cell: ({ row: { original } }: Cell<Transaction>) => {
                      return <span style={{ color: 'var(--primary-color)' }}>{original.invoiceNo ?? 'N/A'}</span>;
                  },
              },
              {
                  Header: 'Payment Status',
                  accessor: 'paymentStatus',
                  Cell: ({ row: { original } }: Cell<Transaction>) => {
                      // const color = original.paymentStatus ? 'var(--primary-color)' : '#D09611';
                      // return <span style={{ color }}>{original.paymentStatus ?? 'Unpaid'}</span>;

                      const color = original.paymentStatus === 'Paid' ? 'var(--primary-color)' : '#D09611';
                      return <span style={{ color }}>{original.paymentStatus ?? 'unPaid'}</span>;
                  },
              },
          ]
        : [
              //   {
              //       Header: 'Funded By',
              //       accessor: 'createdBy',
              //       Cell: ({ row: { original } }: Cell<Transaction>) => {
              //           return <span style={{ color: 'var(--primary-color)' }}>{original.createdBy ?? 'N/A'}</span>;
              //       },
              //   },
              //   {
              //       Header: 'RRR Number',
              //       accessor: 'rrrNo',
              //       Cell: ({ row: { original } }: Cell<Transaction>) => {

              //           if (original.transactionType !== 1) {
              //               return (
              //                   <span style={{ color: 'var(--primary-color)' }}>{original.rrrNo ?? 'Processing...'}</span>
              //               );
              //           }
              //           return null; // Hide the column when transactionType is 1
              //       },
              //   },
              //   {
              //       Header: 'Funded By',
              //       accessor: 'createdBy',
              //       Cell: ({ row: { original } }: Cell<Transaction>) => {
              //           return <span style={{ color: 'var(--primary-color)' }}>{original.createdBy ?? 'N/A'}</span>;
              //       },
              //   },
              //   {
              //       Header: 'RRR Number',
              //       accessor: 'rrrNo',
              //       Cell: ({ row: { original } }: Cell<Transaction>) => {
              //           if (original.transactionType !== 1) {
              //               return (
              //                   <span style={{ color: 'var(--primary-color)' }}>{original.rrrNo ?? 'Processing...'}</span>
              //               );
              //           }
              //           return null; // Hide the column when transactionType is 1
              //       },
              //   },

              {
                  Header: ({ column }) => {
                      // Check the condition for hiding the column header
                      if (transactions.some((row) => row.transactionType !== 1)) {
                          return 'Invoice Number'; // Show the column header
                      }
                      return null; // Hide the column header
                  },
                  accessor: 'invoiceNo',
                  Cell: ({ row: { original } }: Cell<Transaction>) => {
                      if (original.transactionType !== 1) {
                          return (
                              <span style={{ color: 'var(--primary-color)' }}>
                                  {original.invoiceNo ?? 'Processing...'}
                              </span>
                          );
                      }
                      return null; // Hide the column when transactionType is 1
                  },
              },
              {
                  Header: ({ column }) => {
                      // Check the condition for hiding the column header
                      if (transactions.some((row) => row.transactionType !== 1)) {
                          // const color = original.paymentStatus ? 'var(--primary-color)' : '#D09611';
                          // return <span style={{ color }}>{original.paymentStatus ?? 'Unpaid'}</span>;

                          return 'Payment Status'; // Show the column header
                      }
                      return null; // Hide the column header
                  },
                  accessor: 'paymentStatus',
                  Cell: ({ row: { original } }: Cell<Transaction>) => {
                      if (original.transactionType !== 1) {
                          const color = original.paymentStatus === 'Paid' ? 'var(--primary-color)' : '#D09611';
                          return <span style={{ color }}>{original.paymentStatus}</span>;
                      }
                      return null; // Hide the column when transactionType is 1
                  },
              },
          ];

    return (
        <DataTable
            columns={[
                {
                    Header: 'Date',
                    accessor: 'dateCreated',
                    Cell: ({ row: { original } }: Cell<Transaction>) => {
                        return <span>{formatDate(original.dateCreated)}</span>;
                    },
                },
                {
                    Header: 'Service',
                    accessor: 'transactType',
                    Cell: ({ row: { original } }: Cell<Transaction>) => {
                        return <span>{original.transactType}</span>;
                    },
                },
                {
                    Header: 'Client',
                    accessor: 'business_Name',
                },
                {
                    Header: 'Amount',
                    accessor: 'amount',
                    Cell: ({ row: { original } }: Cell<Transaction>) => {
                        return <span>{formatCurrency(original.amount)}</span>;
                    },
                },
                // {
                //     Header: 'BookedBy',
                //     accessor: 'createdBy',
                //     Cell: ({ row: { original } }: Cell<Transaction>) => {
                //         return <span style={{ color: 'var(--primary-color)' }}>{original.createdBy ?? 'N/A'}</span>;
                //     },
                // },
                ...extraColumns,
                {
                    Header: ({ column }) => {
                        // Check the condition for hiding the column name
                        if (column.id === 'transactionType' && transactions.some((row) => row.transactionType === 1)) {
                            return null; // Hide the column name
                        }
                        return 'Action'; // Show the column name for other cases
                    },
                    accessor: 'transactionType',
                    Cell: ({ row: { original: transaction } }: Cell<Transaction>) => {
                        if (transaction.transactionType === 1) {
                            return null; // Hide the column when transactionType is 1
                        }
                        return transaction.transactType === 'Funding' ? (
                            <CustomDropdown
                                className="mr-2"
                                triggerComponent={() => {
                                    return (
                                        <CustomPrimaryButton className="small">
                                            <MoreVertical size={12} />
                                        </CustomPrimaryButton>
                                    );
                                }}
                            >
                                <CustomDropdownItem>
                                    <CustomPrimaryButton
                                        className="small inverted"
                                        style={{ borderColor: 'white' }}
                                        onClick={() => {
                                            dispatch(TransactionsActions.retrieveType([transaction], undefined));
                                            history.push(
                                                `/app/transaction-service/${transaction.transactionId}?type=${transaction.transactionType}`
                                            );
                                        }}
                                    >
                                        View Service
                                    </CustomPrimaryButton>
                                </CustomDropdownItem>
                                <CustomDropdownItem>
                                    {transaction.paymentStatus !== 'Paid' && (
                                        <CustomPrimaryButton
                                            className="small inverted"
                                            style={{ borderColor: 'white' }}
                                            onClick={() =>
                                                transaction.invoiceNo &&
                                                handleRefreshPayment(transaction.invoiceNo.toString())
                                            }
                                        >
                                            <span>Refresh</span>
                                        </CustomPrimaryButton>
                                    )}
                                </CustomDropdownItem>
                            </CustomDropdown>
                        ) : transaction.transactionType !== 1 ? (
                            transaction.invoiceNo !== null ? (
                                <CustomDropdown
                                    className="mr-2"
                                    triggerComponent={() => {
                                        return (
                                            <CustomPrimaryButton className="small">
                                                <MoreVertical size={12} />
                                            </CustomPrimaryButton>
                                        );
                                    }}
                                >
                                    <CustomDropdownItem>
                                        <CustomPrimaryButton
                                            as={Link}
                                            className="small inverted"
                                            style={{ borderColor: 'white' }}
                                            to={`/app/transaction-service/${transaction.transactionId}?type=${transaction.transactionType}`}
                                        >
                                            View service
                                        </CustomPrimaryButton>
                                    </CustomDropdownItem>
                                    <CustomDropdownItem>
                                        {transaction.paymentStatus !== 'Paid' && (
                                            <CustomPrimaryButton
                                                className="small inverted"
                                                style={{ borderColor: 'white' }}
                                                onClick={() =>
                                                    transaction.invoiceNo &&
                                                    handleRefreshPayment(transaction.invoiceNo.toString())
                                                }
                                            >
                                                <span>Refresh</span>
                                            </CustomPrimaryButton>
                                        )}
                                    </CustomDropdownItem>
                                </CustomDropdown>
                            ) : (
                                <CustomPrimaryButton
                                    as={Link}
                                    className="small inverted"
                                    to={`/app/transaction-service/${transaction.transactionId}?type=${transaction.transactionType}`}
                                >
                                    View service
                                </CustomPrimaryButton>
                            )
                        ) : null;
                    },
                },
            ]}
            data={transactions}
            meta={meta}
        />
    );
};
