import { Booking, BookingServicesStat, ClientStats, User, UserActivity } from '../../models/response';
import { ApiResponse } from '../../util/api-client';
import { ReduxAction } from '../common/redux-action';

export interface UsersState {
    usersBookings: Booking[];
    userBookingMeta?: ApiResponse<Booking[]>['meta'];
    users: User[];
    protocols: User[];
    allProtocols: User[];
    usersMeta?: ApiResponse<User[]>['meta'];
    protocolsMeta?: ApiResponse<User[]>['meta'];
    clients: User[];
    clientsMeta: ApiResponse<User[]>['meta'];
    clientStats?: ClientStats;
    clientBookingStats?: BookingServicesStat;
    userDetails?: User;
    userActivities?: UserActivity[];
    userActivitiesMeta?: ApiResponse<UserActivity[]>['meta'];
    dashboardStats?: any;
}

export enum UsersActionNames {
    ADD_USER = 'USERS_ADD_USER',
    GET_CLIENTS = 'USERS_GET_CLIENTS',
    GET_PROTOCOLS = 'USERS_GET_PROTOCOLS',
    GET_ALL_PROTOCOLS = 'USERS_GET_ALL_PROTOCOLS',
    GET_CLIENT_STATS = 'USERS_GET_CLIENT_STATS',
    GET_USERS = 'USERS_GET_USERS',
    GET_USER = 'USERS_GET_USER',
    GET_USERBOOKINGS = 'USERS_GET_USERBOOKINGS',
    GET_DASHBOARD_STATS = 'USERS_GET_DASHBOARD_STATS',
    CHANGE_STATUS = 'USERS_CHANGE_STATUS',
    UPDATE = 'USERS_UPDATE',
    GET_CLIENT_BOOKING_STAT = 'GET_CLIENT_BOOKING_STAT',
    UPDATE_CLIENT = 'USERS_UPDATE_CLIENT',
    GET_ACTIVITIES = 'USERS_GET_ACTIVITIES',
}

export interface AddUserAction extends ReduxAction<typeof UsersActionNames.ADD_USER, User> {}
export interface GetClientsAction extends ReduxAction<typeof UsersActionNames.GET_CLIENTS, {}> {}
export interface GetClientBookingStatsAction extends ReduxAction<typeof UsersActionNames.GET_CLIENT_BOOKING_STAT, {}> {}
export interface GetUsersAction extends ReduxAction<typeof UsersActionNames.GET_USERS, {}> {}
export interface GetUserAction extends ReduxAction<typeof UsersActionNames.GET_USER, {}> {}
export interface GetProtocolAction extends ReduxAction<typeof UsersActionNames.GET_PROTOCOLS, {}> {}
export interface GetAllProtocolAction extends ReduxAction<typeof UsersActionNames.GET_ALL_PROTOCOLS, {}> {}
export interface GetUserBookings extends ReduxAction<typeof UsersActionNames.GET_USERBOOKINGS, {}> {}
export interface ChangeUserStatusAction extends ReduxAction<typeof UsersActionNames.CHANGE_STATUS, {}> {}
export interface UpdateUserAction extends ReduxAction<typeof UsersActionNames.UPDATE, {}> {}
export interface UpdateClientAction extends ReduxAction<typeof UsersActionNames.UPDATE_CLIENT, {}> {}
export interface GetUserActivities extends ReduxAction<typeof UsersActionNames.GET_ACTIVITIES, {}> {}
