import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import { AuthContext } from '../context/auth.context';
import { NotificationsThunk } from '../store/notifications/notifications.thunk';
import { NotificationTypes, Roles } from '../util/constants';

interface ManagedRouteProps extends RouteProps {
    role?: Roles | Roles[];
    authFunction?: () => boolean;
    isPrivate?: boolean;
}

export const ManagedRoute: React.FC<ManagedRouteProps> = ({ role, authFunction, isPrivate, ...props }) => {
    const { user, canAccess } = useContext(AuthContext.Context);
    const dispatch = useDispatch();
    const { pathname: currentLocation, ...location } = useLocation();

    // Access checks
    const UNAUTHORIZED = React.useMemo(
        () => (authFunction && !authFunction?.()) || (role && !canAccess(role, true)),
        // eslint-disable-next-line
        [user]
    );

    // eslint-disable-next-line
    const REQUIRES_LOGOUT = React.useMemo(() => user && !isPrivate, [user]);
    // eslint-disable-next-line
    const REQUIRES_AUTH = React.useMemo(() => !user && isPrivate, [user]);

    const warn = React.useCallback(
        (message: string) => {
            dispatch(NotificationsThunk.create(NotificationTypes.Warning, message));
        },
        [dispatch]
    );

    React.useEffect(() => {
        if (REQUIRES_AUTH) {
            return warn('Log in to access that page');
        }

        if (UNAUTHORIZED) {
            return warn('You are not authorized to access that page');
        }
    }, [UNAUTHORIZED, REQUIRES_AUTH, REQUIRES_LOGOUT, user, warn]);

    if (REQUIRES_AUTH) {
        return (
            <Redirect
                to={{
                    pathname: '/login',
                    state: { referrer: currentLocation },
                }}
            />
        );
    }

    if (UNAUTHORIZED) {
        return (
            <Redirect
                to={{
                    pathname: location.state?.referrer ?? '/app/dashboard',
                    state: location.state,
                }}
            />
        );
    }

    if (REQUIRES_LOGOUT) {
        return (
            <Redirect
                to={{
                    pathname: location.state?.referrer ?? '/app/dashboard',
                    state: {},
                }}
            />
        );
    }

    return <Route {...props} />;
};

const AuthedRoute = (isPrivate: boolean) => (props: ManagedRouteProps) => <ManagedRoute {...{ isPrivate, ...props }} />;

export const ProtectedRoute = AuthedRoute(true);
export const ExternalRoute = AuthedRoute(false);
