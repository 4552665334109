import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const ClientServiceCardStyleWrapper = styled(Link)`
    color: black !important;
    display: flex;
    flex-direction: column;
    border: 1px solid #dadada;
    border-radius: 7px;
    padding: 20px;
    cursor: pointer;
    margin-bottom: 1rem;
    min-width: 250px;

    .iconBox {
        margin-bottom: 10px;

        img {
            width: 50px;
        }
    }

    .contentBox {
        flex: 1;

        span {
            font-weight: bold;
        }
    }

    p {
        margin-bottom: 10px;
    }

    span {
        margin-top: 5px;
        color: var(--primary-color);
    }
`;
