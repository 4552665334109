import { Transaction, User } from '../models/response';
import { ApiClient, QueryParams } from '../util/api-client';
import { TransactionTypes } from '../util/constants';
import { FundWalletModel, WalletBalanceModel } from '../models/request';
import { convertCurrencyToDecimal } from '../util/misc/formatters';
import { RenewalsModel } from '../components/wallet/renewal';
import { PaymentsModel } from '../components/wallet/payment';

export class TransactionsService {
    public static list(page?: number) {
        const query = page ? `?pageNumber=${page}` : '';

        return ApiClient.get<Transaction[]>('transactions/services' + query);
    }

    public static retrieveByTransactionId(id: Transaction['transactionId']) {
        return ApiClient.get<Transaction[]>('transactions/services/' + id);
    }

    public static retrieveTransactionTypeById(id: User['id'], type: TransactionTypes, page?: number) {
        return ApiClient.get<Transaction[]>(`transactions/services-by-type/${type}/${id}?PageNumber=${page}`);
    }

    public static getTransactionByType(type: number, page: number) {
        return ApiClient.get<Transaction[]>(`transactions/services-by-type/${type}?PageNumber=${page}`);
    }

    public static getTransactionByDate(type: number, query: QueryParams, searchTerm?: string) {
        const path = `transactions/services-by-type-date/${type}`;
        const url = searchTerm ? `transactions/services-by-type-date/search/${type}/${searchTerm}` : path;
        return ApiClient.get<Transaction[]>(url, query);
    }

    public static fundWallet(model: FundWalletModel) {
        const covartedModel = convertCurrencyToDecimal(model.amount);
        model.amount = covartedModel;
        return ApiClient.post<FundWalletModel, Transaction>('users/fund-wallet', model);
    }

    public static getWalletBalance(client: string) {
        return ApiClient.get<WalletBalanceModel>(`transactions/wallet-balance/${client}`);
    }

    public static exportBookingTransaction() {
        return ApiClient.get<any>(`booking-services/export-bookings`);
    }

    public static renewalDate(model: RenewalsModel) {
        return ApiClient.post<RenewalsModel, Transaction>('renewals', model);
    }

    public static payment(clientId: string, id: number) {
        const endpoint = `renewals/pay/${clientId}/${id}`;
        return ApiClient.patch<Transaction[]>(endpoint);
    }

    public static payRenewalId(clientId: string, page: number) {
        return ApiClient.get<Transaction[]>(`renewals/get-client-renewal/${clientId}?PageNumber=${page}`);
    }

    public static refreshInvoicePayment(invoicenumber: string) {
        return ApiClient.get<Transaction[]>(`transactions/invoice-status/${invoicenumber}`);
    }
}
