import { VCard } from '../../models/response';
import { ReduxAction } from '../common/redux-action';

export interface VcardState {
    vcards: VCard[];
}

export enum VcardActionNames {
    GET_ALL = 'GET VCARDS STATS',
    GET_ONE = 'GET AIRPOTS VCARDS',
    ADD = 'CREATE VCARDS',
}

export interface GetAllVcards extends ReduxAction<typeof VcardActionNames.GET_ALL, VcardState> {}
export interface GetAirpotVcard extends ReduxAction<typeof VcardActionNames.GET_ONE, VcardState> {}
export interface CreateVcard extends ReduxAction<typeof VcardActionNames.ADD, VcardState> {}
