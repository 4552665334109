import { createAcronym } from './generateAcronym';

export const generateChartMetadata = (data: any[], titleKey: string, valueKey: string, useAcronym?: boolean) => {
    const lebels: string[] = [];
    const dataValue: number[] = [];

    data?.forEach((data: any) => {
        lebels.push(data[titleKey] ?? '');
        dataValue.push(data[valueKey]);
    });

    return { lebels, dataValue };
};
