import style from 'styled-components';

export const ViewClientPageStyleWrapper = style.div`

.infoBox {
    border: 1px solid #DADADA;
    padding: 20px 5px 20px 5px;
    margin-top: 1rem;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h4 {
        margin: 0;
        font-size: 18px;
        font-weight: bold;
    }

    p {
        font-size: 12px;
    }

    .circleBox {
        height: 60px;
        width: 60px;
        border-radius: 100%;
        background-color: #EAFAF1;
        color: #18A956;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        font-size: 20px;
        font-weight: bold;
    }
}
`;
