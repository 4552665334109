import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import {
    Collapse,
    Container,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Nav,
    Navbar,
    NavbarBrand,
    NavbarToggler,
    NavItem,
    NavLink,
    UncontrolledDropdown,
} from 'reactstrap';

import { CustomPrimaryButton } from '../index';
import './NavMenu.scss';

const LandingPageNav = () => {
    const [isOpen, setOpen] = useState(false);

    const toggle = useCallback(() => {
        setOpen(!isOpen);
    }, []);

    // useEffect(() => {
    //     document.body.addEventListener('click', toggle);
    //     return () => {
    //         document.body.removeEventListener('click', toggle);
    //     };
    // }, [toggle]);

    return (
        <header>
            <Navbar expand="lg" className="navbar-expand-sm navbar-toggleable-sm  border-0 bg-white" light fixed="top">
                <Container fluid={true}>
                    <NavbarBrand className="mr-lg-5 landing-nav" to="/" tag={Link}>
                        <img alt="..." src={require('../../assets/images/logo_faan.png')} />
                    </NavbarBrand>
                    <NavbarToggler onClick={toggle} className="mr-2" />
                    <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={isOpen} navbar>
                        <Nav className="align-items-lg-center ml-lg-auto" navbar>
                            <React.Fragment>
                                <NavItem>
                                    <NavLink href="#services">
                                        <strong> Our Services</strong>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href="#FAQ">
                                        <strong>FAQs</strong>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href="#partners">
                                        <strong>Partners</strong>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href="#contact">
                                        <strong> Contact</strong>
                                    </NavLink>
                                </NavItem>
                            </React.Fragment>
                        </Nav>
                    </Collapse>
                </Container>
            </Navbar>
        </header>
    );
};

export default LandingPageNav;
