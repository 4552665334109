import { IsPositive } from 'class-validator';
import { VCard } from '../../response';
import { BaseModel } from '../base-model';
import { CreateVcardModel } from './create-vcard.model';

export class UpdateVcardModel extends CreateVcardModel implements BaseModel {
    @IsPositive({ message: 'Invalid id' })
    public Id: number;

    constructor(vcard: VCard) {
        super();
        this.Id = +vcard.id;
        this.cardNumber = vcard.cardNumber;
        this.color = vcard.color;
        this.AirportId = vcard.airpot as any;
    }

    public finalize() {}
}
