import { capitalize } from 'lodash';
import moment from 'moment';
import React from 'react';
import { Cell } from 'react-table';
import { DataTable } from '../../../../components';
import { UserActivity } from '../../../../models/response';
import { ApiResponse } from '../../../../util/api-client';

interface ActivitiesTableProps {
    activities: UserActivity[];
    meta?: ApiResponse<UserActivity[]>['meta'];
    role: string;
}

export const ActivitiesTable: React.FC<ActivitiesTableProps> = ({ activities, meta }) => {
    const latest_activities = activities
        .sort((a, b) => Date.parse(b.dateCreated) - Date.parse(a.dateCreated))
        .slice(0, 7);

    return (
        <DataTable
            columns={[
                {
                    Header: 'Date Created',
                    Cell: ({ row: { original } }: Cell<UserActivity>) => (
                        <em>{moment(original.dateCreated).format('Do MMMM, YYYY')}</em>
                    ),
                },
                {
                    Header: 'User',
                    Cell: ({ row: { original: activity } }: Cell<UserActivity>) => {
                        const name =
                            (activity.user && activity.user.business_Name) ||
                            (activity.user && `${activity.user.firstName} ${activity.user.lastName}`);
                        return <span>{name}</span>;
                    },
                },
                {
                    Header: 'Email',
                    Cell: ({ row: { original: activity } }: Cell<UserActivity>) => {
                        const email = activity.user && activity.user.email;
                        return <span>{email}</span>;
                    },
                },
                {
                    Header: 'Activity',
                    accessor: 'description',
                },
            ]}
            meta={meta}
            data={latest_activities}
        />
    );
};
