import { ReduxAction } from '../common/redux-action';
import { VcardActionNames, VcardState } from './vcard.types';

const initialState: VcardState = {
    vcards: [],
};

export const vcardReducer = (state = initialState, action: ReduxAction<VcardActionNames>) => {
    switch (action.type) {
        case VcardActionNames.GET_ALL: {
            return { ...action.payload };
        }

        case VcardActionNames.GET_ONE: {
            return { ...action.payload };
        }

        default: {
            return state;
        }
    }
};
