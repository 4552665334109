import { Action, createBrowserHistory, Location, LocationState, Path } from 'history';
import * as queryString from 'query-string';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') as string;
export const history = createBrowserHistory({ basename: baseUrl });

class PathLocationManager {
    private pastLocations: Location[] = [];
    private readonly key = 'appLocationHistory';

    constructor() {
        this.pastLocations = [];
    }

    public push(location: Location) {
        this.pastLocations.push(location);
    }

    public pop() {
        this.pastLocations.pop();
    }

    public length() {
        return this.pastLocations.length;
    }

    public setLocation(index: number, location: Location) {
        this.pastLocations[index] = location;
    }

    public getLocation(index: number) {
        return this.pastLocations[index];
    }

    public setLocations(locations: Location[]) {
        this.pastLocations = locations;
    }

    private dumpToSessionStorage() {
        sessionStorage.setItem(this.key, JSON.stringify(this.pastLocations));
    }
}

const pastLocations = new PathLocationManager();

function updatePastLocations(location: Location, action: Action) {
    switch (action) {
        case 'PUSH':
            // first location when app loads and when pushing onto history
            pastLocations.push(location);
            break;
        case 'REPLACE':
            // only when using history.replace
            pastLocations.setLocation(pastLocations.length() - 1, location);
            break;
        case 'POP': {
            // happens when using the back button, or forward button
            pastLocations.pop();
            // location according to pastLocations
            const appLocation = pastLocations.getLocation(pastLocations.length() - 1);
            if (!(appLocation && appLocation.key === location.key)) {
                // If the current location doesn't match what the app thinks is the current location,
                // blow up the app history.
                pastLocations.setLocations([location]);
            }
            break;
        }
        default:
    }
}

history.listen(updatePastLocations);

export function isPreviousLocationWithinApp(): boolean {
    return pastLocations.length() > 1;
}

export function goBackOrPush(location: Path, state?: LocationState): void {
    if (isPreviousLocationWithinApp()) {
        history.goBack();
    } else {
        history.push(location, state);
    }
}

export function setSearch<SearchParams extends Record<string, string>>(param: keyof SearchParams, value: any) {
    if (!param) {
        return history.push({ search: '' });
    }

    const search = queryString.parse(history.location.search) as unknown as SearchParams;
    search[param] = value;

    history.push({
        search: `?${queryString.stringify(search)}`,
    });
}
