import React from 'react';
import styled from 'styled-components';
import { CustomPrimaryButton } from '../../../../../components';
import { formatCurrency } from '../../../../../util/misc';
import { history } from '../../../../../util/history';

const Container = styled.div`
    margin: 1rem 2rem;
    border: 1px solid #dddddd;
    padding: 1rem 5rem 2rem 2rem;
    border-radius: 20px;
`;

interface createBookingInvoiceProps {
    goBack: () => void;
    booking: any;
}

export const CreateBookingInvoice: React.FC<createBookingInvoiceProps> = ({ goBack, booking }) => {
    return (
        <Container>
            <div>
                <b>Payment Summary</b>
            </div>
            <div className="d-flex justify-content-between mt-5">
                <div>
                    <div>
                        <div className="h4">{booking?.transaction?.invoiceNumber}</div>
                        <b>Invoice No</b>
                    </div>
                    <div className="mt-2">
                        <div className="h4">{booking?.transaction?.paymentStatus ?? 'Unpaid'}</div>
                        <b>Status</b>
                    </div>

                    <div className="mt-2">
                        <div className="h4">{booking?.transaction?.rrrNo ?? '0'}</div>
                        <b>RRR</b>
                    </div>

                    <div className="mt-2">
                        <div className="h4">Federal Airport Authority of Nigeria</div>
                        <b>Agency Name</b>
                    </div>
                </div>
                <div className="">
                    <div>
                        <div className="h4">{formatCurrency(booking?.price)}</div>
                        <b>Invoice Amount</b>
                    </div>
                </div>
            </div>
            <div className="mt-5">
                <b>Use the RRR generated to pay on the POS</b>
                <ul>
                    <li>Ensure the Payment is successful</li>
                    <li>Then click on the Back to Transactions button below</li>
                    <li>This will redirect you to the transaction page</li>
                </ul>
                <div className="mt-5">
                    <CustomPrimaryButton onClick={() => history.replace('/app/transaction-services')}>
                        <span>Back to Transactions</span>
                    </CustomPrimaryButton>
                </div>
            </div>
        </Container>
        // </CustomModal>
    );
};
