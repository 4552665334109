import { Field, useField } from 'formik';
import React from 'react';
import styled from 'styled-components';

export const RadioContainer: React.ElementType = styled.label`
    display: flex;
    position: relative;
    align-items: center;
    flex-wrap: wrap;
    margin: 0;

    input {
        opacity: 0;
        filter: opacity(0);
        position: absolute;
        top: 0;
        left: 0;
        margin: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
    }

    i {
        position: relative;
        width: ${(props: any) => (props.size === 'medium' ? '20px' : '10px')};
        height: ${(props: any) => (props.size === 'medium' ? '20px' : '10px')};
        background: white;
        border: 1px solid #e2e2e2;
        padding: 1px;
        border-radius: 12px;
        transition: all 0.5s ease;
    }

    i::after {
        transition: background-color 0.15s ease;
        content: ' ';
        display: block;
        background: transparent;
        height: 100%;
        width: 100%;
        border-radius: 100%;
    }

    input:checked + i {
        background: white;
    }

    input:checked + i::after {
        background: var(--primary-color);
    }

    &.disabled {
        cursor: not-allowed;

        input + i {
            background: orange;
            border-color: orange;
        }

        input + i::after {
            border-color: orange;
        }
    }

    .form-radio__body {
        display: flex;
        height: 40px;
        border-radius: 7px;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #dadada;
        padding: 10px 15px;
        justify-content: center;
        position: relative;
        align-items: center;

        i {
            width: 20px;
            height: 20px;
            padding: 4px;
        }
    }

    .form-radio__body.valid {
        border-color: var(--primary-color);
    }

    .form-radio__body.invalid {
        border-color: #e03225;
    }

    .form-radio__label {
        margin-left: 15px;
    }

    .form-radio__validation-text {
        font-size: 13px;
        padding: 5px 0;
        color: #f44336;
        width: 100%;
        display: block;
    }
`;

interface FormRadioProps extends React.ComponentPropsWithoutRef<'input'> {
    name: string;
    disabled?: boolean;
    label: string;
    className?: string;
    value: any;
}

interface PresentationalFormRadioProps {
    checked: boolean | undefined;
    size?: string;
}

export const FormRadio: React.FC<FormRadioProps> = ({ disabled, className, label, ...props }) => {
    const [field, meta] = useField(props);

    const validState = meta.touched ? (meta.error ? false : true) : null;
    const validationClass = validState === null ? '' : validState ? ' valid' : ' invalid';

    return (
        <RadioContainer
            className={(disabled ? 'disabled ' : '') + className ?? ''}
            // onClick={helpers.setValue.bind(undefined, props.value)}
        >
            <div className={`form-radio__body` + validationClass}>
                <Field type="radio" disabled={disabled} {...props} checked={+field.value === +props.value} />
                <i />
                <span className="form-radio__label">{label}</span>
            </div>
            {/* {validState === false && (
                <span className="form-radio__validation-text">{meta.error}</span>
            )} */}
        </RadioContainer>
    );
};

export const PresentationalFormRadio: React.FC<PresentationalFormRadioProps> = ({ checked, size }) => {
    return (
        <RadioContainer size={size}>
            <input type="radio" defaultChecked={checked} disabled />
            <i />
        </RadioContainer>
    );
};
