import { find } from 'lodash';
import { Dispatch } from 'redux';
import { Transaction } from '../../models/response';
import { BookingsService } from '../../services';
import { TransactionsService } from '../../services/transactions.service';
import { QueryParams } from '../../util/api-client';
import { NotificationTypes, TransactionTypes } from '../../util/constants';
import { history } from '../../util/history';
import { generateLoader } from '../../util/misc';
import { NotificationsThunk } from '../notifications/notifications.thunk';
import { TransactionsActions } from './transactions.actions';

export class TransactionsThunk {
    public static list(pageNumber?: number) {
        return async (dispatch: Dispatch) => {
            try {
                const response = await generateLoader(
                    TransactionsService.list(pageNumber),
                    dispatch,
                    'Fetching transactions'
                );

                if (response.data) {
                    if (
                        response.data?.length &&
                        response.meta &&
                        pageNumber &&
                        (pageNumber > response.meta.lastPage || pageNumber < response.meta.firstPage)
                    ) {
                        history.replace('?page=1');
                        return;
                    }

                    dispatch(
                        TransactionsActions.list(
                            response.data.sort((a, b) => Date.parse(b.dateCreated) - Date.parse(a.dateCreated)),
                            response.meta
                        )
                    );
                    return;
                }
            } catch {
                NotificationsThunk.create(NotificationTypes.Error, 'Failed to fetch transactions')(dispatch);
            }
        };
    }

    public static fetchPayRenewalId(clientId: string, pageNumber: number) {
        return async (dispatch: Dispatch) => {
            try {
                const response = await generateLoader(
                    TransactionsService.payRenewalId(clientId, pageNumber),
                    dispatch,
                    'Fetching user Renewals'
                );

                if (response.status === 200 && response.data) {
                    if (
                        response.data?.length &&
                        response.meta &&
                        pageNumber &&
                        (pageNumber > response.meta.lastPage || pageNumber < response.meta.firstPage)
                    ) {
                        history.replace('?page=1');
                        return;
                    }

                    dispatch(
                        TransactionsActions.payRenewalId(
                            response.data.sort((a, b) => Date.parse(b.dateCreated) - Date.parse(a.dateCreated)),
                            response.meta
                        )
                    );
                    return;
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                NotificationsThunk.create(NotificationTypes.Error, 'Failed to fetch transactions')(dispatch);
            }
        };
    }

    public static retrieve(id: string | number) {
        return async (dispatch: Dispatch) => {
            try {
                const response = await generateLoader(TransactionsService.list(), dispatch, 'Fetching transaction');

                if (!response.data) {
                    throw new Error('Error fetching transaction. Kindly Refresh');
                }

                dispatch(TransactionsActions.list(response.data, response.meta));
                const transaction = find(response.data, { id: +id });

                if (!transaction) {
                    throw new Error('Error fetching transaction. Kindly Refresh');
                }

                if (transaction.transactType === 'Protocol Services') {
                    const response = await generateLoader(
                        BookingsService.retrieve(+transaction.transactionId),
                        dispatch,
                        'Fetching booking details'
                    );

                    if (!response.data) {
                        throw new Error('Booking does not exist');
                    }

                    dispatch(TransactionsActions.retrieve({ transaction, details: response.data }));
                } else {
                    // Awaiting end
                }
            } catch (error) {
                NotificationsThunk.create(NotificationTypes.Error, (error as any)?.message)(dispatch);
            }
        };
    }

    public static retrieveByTrasactionId(id: string | number, cb?: () => any) {
        return async (dispatch: Dispatch) => {
            try {
                dispatch(TransactionsActions.retrieve({ transaction: undefined }));

                const response = await generateLoader(
                    TransactionsService.retrieveByTransactionId('' + id),
                    dispatch,
                    'Fetching transaction'
                );

                if (!response.data) {
                    throw new Error('Error fetching transaction. Kindly Refresh');
                }

                dispatch(TransactionsActions.retrieve({ transaction: response.data[0] }));
                cb?.();
            } catch (error) {
                NotificationsThunk.create(NotificationTypes.Error, (error as any)?.message)(dispatch);
            }
        };
    }

    public static retrieveTransactionTypebyId(
        id: Transaction['transactionId'],
        type: TransactionTypes,
        cb?: () => any,
        page?: number
    ) {
        return async (dispatch: Dispatch) => {
            try {
                dispatch(TransactionsActions.retrieve({ transaction: undefined }));

                const response = await generateLoader(
                    TransactionsService.retrieveTransactionTypeById(id, type, page),
                    dispatch,
                    'Fetching transaction'
                );

                if (!response.data) {
                    throw new Error('Error fetching transaction. Kindly Refresh');
                }

                if (type === TransactionTypes.Registration) {
                    dispatch(TransactionsActions.retrieve({ transaction: response.data[0] }));
                } else {
                    dispatch(TransactionsActions.retrieveType(response.data, response.meta));
                }
                cb?.();
            } catch (error) {
                NotificationsThunk.create(NotificationTypes.Error, (error as any)?.message)(dispatch);
            }
        };
    }

    public static retrieveByTransactionType(id: number, query: QueryParams, searchTerm?: string) {
        return async (dispatch: Dispatch) => {
            try {
                const response = await generateLoader(
                    TransactionsService.getTransactionByDate(id, query, searchTerm),
                    dispatch,
                    'Fetching Transactions'
                );

                if (!response.data) {
                    throw new Error(' Error fetching transaction. Kindly Refresh');
                }

                dispatch(TransactionsActions.retrieveType(response.data, response.meta));
            } catch (error) {
                NotificationsThunk.create(NotificationTypes.Error, (error as any)?.message)(dispatch);
            }
        };
    }

    public static clearTransaction() {
        return async (dispatch: Dispatch) => {
            dispatch(TransactionsActions.retrieve({ transaction: undefined }));
        };
    }
}
