import { Allow, Matches, MinLength, ValidateIf } from 'class-validator';
import { CreateUserModel } from '.';
import { IsMobileNumber } from '../../../util/validation';
import { Profile } from '../../response';
import { BaseModel } from '../base-model';

export class EditUserProfileModel implements BaseModel {
    @Allow()
    public id: string;

    @Matches(/^[^\s].*[^\s]$/, { message: 'Cannot begin or end with a space' })
    @MinLength(3, {
        message: 'Must be at least $constraint1 characters long',
    })
    public firstname: string = '';

    @Matches(/^[^\s].*[^\s]$/, { message: 'Cannot begin or end with a space' })
    @MinLength(3, {
        message: 'Must be at least $constraint1 characters long',
    })
    @ValidateIf((o: CreateUserModel) => !!o.MiddleName)
    public middleName: string = '';

    @Matches(/^[^\s].*[^\s]$/, { message: 'Cannot begin or end with a space' })
    @MinLength(3, {
        message: 'Must be at least $constraint1 characters long',
    })
    public lastName: string = '';

    @IsMobileNumber({ message: 'Invalid phone number format' })
    public phoneNumber: string = '';

    constructor(user: Profile) {
        this.id = user.id;
        this.firstname = user.firstName;
        this.middleName = user.middleName;
        this.lastName = user.lastName;
        this.phoneNumber = user.phoneNumber;
    }

    beforeValidation() {
        // this.phoneNumber = this.phoneNumber.replace(/\w/, '');
    }
}
