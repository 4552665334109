import { BookingServicesStat, ClientStats, User } from '../../models/response';
import { ApiResponse } from '../../util/api-client';
import {
    AddUserAction,
    GetAllProtocolAction,
    GetClientsAction,
    GetProtocolAction,
    GetUserAction,
    GetUserActivities,
    GetUserBookings,
    GetUsersAction,
    UpdateClientAction,
    UpdateUserAction,
    UsersActionNames,
} from './users.types';

export class UsersActions {
    public static addUser(user: User): AddUserAction {
        return {
            type: UsersActionNames.ADD_USER,
            payload: user,
        };
    }

    public static getUsers(users: ApiResponse<User[]>): GetUsersAction {
        return {
            type: UsersActionNames.GET_USERS,
            payload: users,
        };
    }

    public static getProtocols(protocols: ApiResponse<User[]>): GetProtocolAction {
        return {
            type: UsersActionNames.GET_PROTOCOLS,
            payload: protocols,
        };
    }

    public static getAllProtocols(allProtocols: ApiResponse<User[]>): GetAllProtocolAction {
        return {
            type: UsersActionNames.GET_ALL_PROTOCOLS,
            payload: allProtocols,
        };
    }

    public static getClients(users: ApiResponse<User[]>): GetClientsAction {
        return {
            type: UsersActionNames.GET_CLIENTS,
            payload: users,
        };
    }

    public static getClientStats(stats: ClientStats) {
        return {
            type: UsersActionNames.GET_CLIENT_STATS,
            payload: stats,
        };
    }

    public static getClientBookingStats(stats: BookingServicesStat) {
        return {
            type: UsersActionNames.GET_CLIENT_BOOKING_STAT,
            payload: stats,
        };
    }

    public static getDashboardStats(stats: any) {
        return {
            type: UsersActionNames.GET_DASHBOARD_STATS,
            payload: stats,
        };
    }

    public static getUser(userDetails: any): GetUserAction {
        return {
            type: UsersActionNames.GET_USER,
            payload: userDetails,
        };
    }

    public static getUserBooking(usersBookings: any): GetUserBookings {
        return {
            type: UsersActionNames.GET_USERBOOKINGS,
            payload: usersBookings,
        };
    }

    public static updateUser(userDetails: User | undefined): UpdateUserAction {
        return {
            type: UsersActionNames.UPDATE,
            payload: userDetails,
        };
    }

    public static updateClient(clientDetails: User | undefined): UpdateClientAction {
        return {
            type: UsersActionNames.UPDATE_CLIENT,
            payload: clientDetails,
        };
    }

    public static getUserActivities(activities: any): GetUserActivities {
        return {
            type: UsersActionNames.GET_ACTIVITIES,
            payload: activities,
        };
    }
}
