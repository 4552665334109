import moment from 'moment';
import React from 'react';
import { MoreVertical } from 'react-feather';
import { useSelector } from 'react-redux';
import { Cell } from 'react-table';
import { CustomDropdown, CustomDropdownItem, CustomPrimaryButton, DataTable } from '../../components';
import { PresentationalFormSwitch } from '../../components/form';
import { User } from '../../models/response';
import { ApplicationState } from '../../store';
import { ApiResponse } from '../../util/api-client';
import { Roles } from '../../util/constants';

interface ProtocolsTableProps {
    users: User[];
    meta?: ApiResponse<User[]>['meta'];
    setActiveUser: React.Dispatch<React.SetStateAction<User | undefined>>;
    handleSetStatus: (...args: any[]) => void;
    handleModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ProtocolTable: React.FC<ProtocolsTableProps> = ({
    users,
    setActiveUser,
    meta,
    handleSetStatus,
    handleModal,
}) => {
    const { authUser } = useSelector((state: ApplicationState) => state.auth);

    return (
        <DataTable
            columns={[
                {
                    Header: 'Date Created',
                    accessor: 'dateCreated',
                    Cell: ({ row: { original } }: Cell<User>) => {
                        return <b>{moment(original.dateCreated).format('Do MMMM, YYYY')}</b>;
                    },
                },
                {
                    Header: 'First Name',
                    accessor: 'firstName',
                },
                {
                    Header: 'Last name',
                    accessor: 'lastName',
                },
                {
                    Header: 'Email',
                    accessor: 'email',
                },
                {
                    Header: 'Profile Status',
                    accessor: 'profile_Status',
                },
                // {
                //     Header: 'Airport',
                //     Cell: ({ row: { original: user } }: Cell<User>) => {
                //         return <span>{find(airports, { id: user.airportId })?.name}</span>;
                //     },
                // },
                {
                    Header: 'Role',
                    accessor: 'role',
                },
                {
                    Header: 'Actions',
                    Cell: ({ row: { original: user } }: Cell<User>) => {
                        return (
                            user.id !== authUser?.id && (
                                <React.Fragment>
                                    <CustomDropdown
                                        triggerComponent={() => {
                                            return (
                                                <CustomPrimaryButton className="small">
                                                    <MoreVertical size={12} />
                                                </CustomPrimaryButton>
                                            );
                                        }}
                                    >
                                        <CustomDropdownItem
                                            onClick={() => {
                                                setActiveUser(user);
                                                handleModal(true);
                                            }}
                                        >
                                            <span>Edit User</span>
                                        </CustomDropdownItem>
                                        <CustomDropdownItem
                                            onClick={handleSetStatus.bind(undefined, user, !user.account_Status)}
                                        >
                                            <span>{user.account_Status ? 'Deactivate' : 'Activate'}</span>
                                            <PresentationalFormSwitch checked={user.account_Status} />
                                        </CustomDropdownItem>
                                    </CustomDropdown>
                                </React.Fragment>
                            )
                        );
                    },
                },
            ]}
            data={users}
            meta={meta}
        />
    );
};
