import { FeedbackData } from '../../services/feedback.service';

export const FETCH_FEEDBACKS_REQUEST = 'FETCH_FEEDBACKS_REQUEST';
export const FETCH_FEEDBACKS_SUCCESS = 'FETCH_FEEDBACKS_SUCCESS';
export const FETCH_FEEDBACKS_FAILURE = 'FETCH_FEEDBACKS_FAILURE';

export const fetchFeedbacksRequest = () => ({
    type: FETCH_FEEDBACKS_REQUEST,
});

export const fetchFeedbacksSuccess = (feedbacks: FeedbackData[]) => ({
    type: FETCH_FEEDBACKS_SUCCESS,
    payload: feedbacks,
});

export const fetchFeedbacksFailure = (error: string) => ({
    type: FETCH_FEEDBACKS_FAILURE,
    payload: error,
});
