import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import 'filepond/dist/filepond.min.css';
import { Formik } from 'formik';
import { isMatch, uniqBy } from 'lodash';
import React from 'react';
import { Briefcase, Calendar, Lock, Mail, MapPin, Phone } from 'react-feather';
import { FilePond, registerPlugin } from 'react-filepond';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { EntryFormContainer } from '../../../components';
import { Breadcrumb, Breadcrumbs } from '../../../components/breadcrumbs/breadcrumbs';
import { FormBackButton, FormButton, FormFileUpload, FormStepper, FormTextInput } from '../../../components/form';
import Layout, { StickyBackground } from '../../../components/layout';
import { CreateCorporateOrganizationModel } from '../../../models/request';
import { NotificationsThunk } from '../../../store/notifications/notifications.thunk';
import { UsersThunk } from '../../../store/users/users.thunk';
import { BusinessNatures, ClientTypesMap, NotificationTypes } from '../../../util/constants';
import { validateFormValues } from '../../../util/validation';
import { RegistrationStages } from './common';
// import { FileStatus } from 'filepond';

registerPlugin(FilePondPluginFileValidateSize, FilePondPluginFileValidateType);

export const RegisterCorporateOrganizationPage: React.FC = () => {
    const breadcrumbs: Breadcrumb[] = [
        { name: 'Home', path: '/' },
        { name: 'Create account', path: window.location.href },
    ];

    const [currentStage, setCurrentStage] = React.useState<RegistrationStages>(RegistrationStages.FirstStage);
    const atLastStage = React.useMemo(() => currentStage === RegistrationStages.SecondStage, [currentStage]);
    const pond = React.useRef<FilePond>(null);

    const dispatch = useDispatch();
    const handleRegister = React.useCallback(
        async ({ Files: files, ...values }: CreateCorporateOrganizationModel) => {
            const formData = new FormData();

            if (files.length < 1) {
                dispatch(NotificationsThunk.create(NotificationTypes.Error, 'Please select at least one file'));
                return;
            }

            // filter(files, (file) => file.status !== FileStatus.LOAD_ERROR).forEach((file) => {
            //     formData.append(file.filenameWithoutExtension, file.file, file.filename);
            // });

            Object.entries(values).forEach(([key, value]) => {
                formData.append(key, value);
            });

            dispatch(UsersThunk.createCorporateOrganization(formData));
        },
        [dispatch]
    );

    // Set up FilePond
    React.useEffect(() => {
        if (pond.current) {
            (pond.current as any)._pond.setOptions({
                allowProcess: false,
                maxFileSize: '2MB',
                acceptedFileTypes: ['application/pdf', 'image/jpeg'],
                labelFileTypeNotAllowed: 'Only PDF and JPEG files are accepted',
            });
        }
    });

    return (
        <Layout>
            <StickyBackground />
            <div className="px-5 py-3">
                <Breadcrumbs breadcrumbs={breadcrumbs} />
                <EntryFormContainer>
                    <Formik
                        validate={validateFormValues}
                        initialValues={new CreateCorporateOrganizationModel()}
                        onSubmit={handleRegister}
                    >
                        {({ handleSubmit, isValid, values, setFieldValue, initialValues, submitCount }) => (
                            <form onSubmit={handleSubmit} className="d-flex flex-column align-items-center">
                                <header className="form__header mb-5">
                                    <span className="form__subheader">Corporate Organization</span>
                                    <FormBackButton currentStep={currentStage} setCurrentStep={setCurrentStage} />
                                    <h1 className="mb-3">Create an account</h1>
                                    <FormStepper steps={3} currentStep={currentStage} />
                                    {!isValid && submitCount > 0 && (
                                        <h6 className="error mt-2">Please check the form fields for errors</h6>
                                    )}
                                    {atLastStage && values.Files.length < 1 && (
                                        <h6 className="text-danger mt-2">
                                            File upload is mandatory. Either PDF or JPEG with the maximum size of 2MB
                                        </h6>
                                    )}
                                </header>
                                <main className="mb-4">
                                    {atLastStage ? (
                                        <React.Fragment>
                                            <div className="mb-6">
                                                <FormFileUpload
                                                    allowMultiple={true}
                                                    ref={pond}
                                                    files={values.Files}
                                                    maxFiles={10}
                                                    server="/api"
                                                    onupdatefiles={(files) => {
                                                        setFieldValue('Files', uniqBy(files, 'filename'));
                                                    }}
                                                />
                                            </div>
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <div className="form-row mb-0 mb-sm-2">
                                                <div className="col-12 col-md-6 mb-2 mb-sm-0">
                                                    <FormTextInput
                                                        name="Business_Name"
                                                        placeholder="BUSINESS NAME"
                                                        type="text"
                                                        icon={Briefcase}
                                                        className="wide"
                                                    />
                                                </div>
                                                <div className="col-12 col-md-6 mb-2 mb-sm-0">
                                                    <FormTextInput
                                                        name="Registered_Address"
                                                        placeholder="REGISTERED ADDRESS"
                                                        type="text"
                                                        icon={MapPin}
                                                        className="wide"
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-row mb-0 mb-sm-2">
                                                <div className="col-12 col-md-6 mb-2 mb-sm-0">
                                                    <FormTextInput
                                                        name="Business_Nature"
                                                        placeholder="NATURE OF BUSINESS"
                                                        type="text"
                                                        as="select"
                                                        icon={Briefcase}
                                                        className="wide"
                                                    >
                                                        <option value="" disabled>
                                                            NATURE OF BUSINESS
                                                        </option>{' '}
                                                        {BusinessNatures.map((value, i) => (
                                                            <option value={value} key={i}>
                                                                {value}
                                                            </option>
                                                        ))}
                                                    </FormTextInput>
                                                </div>
                                                <div className="col-12 col-md-6 mb-2 mb-sm-0">
                                                    <FormTextInput
                                                        name="Creation_Year"
                                                        placeholder="YEAR OF INCORPORATION"
                                                        type="text"
                                                        icon={Calendar}
                                                        className="wide"
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-row mb-0 mb-sm-2">
                                                <div className="col-12 col-md-6 mb-2 mb-sm-0">
                                                    <FormTextInput
                                                        name="Registration_Number"
                                                        placeholder="REGISTRATION NUMBER"
                                                        type="text"
                                                        icon={Briefcase}
                                                        className="wide"
                                                    />
                                                </div>
                                                <div className="col-12 col-md-6 mb-2 mb-sm-0">
                                                    <FormTextInput
                                                        name="PhoneNumber"
                                                        placeholder="MOBILE NUMBER"
                                                        type="text"
                                                        icon={Phone}
                                                        className="wide"
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-row mb-0 mb-sm-2">
                                                <div className="col-12 col-md-6 mb-2 mb-sm-0">
                                                    <FormTextInput
                                                        name="Email"
                                                        placeholder="EMAIL ADDRESS"
                                                        type="email"
                                                        icon={Mail}
                                                        className="wide"
                                                    />
                                                </div>
                                                <div className="col-12 col-md-6 mb-2 mb-sm-0">
                                                    <FormTextInput
                                                        name="ClientTypeId"
                                                        placeholder="CLIENT TYPE"
                                                        type="text"
                                                        as="select"
                                                        icon={Briefcase}
                                                        className="wide"
                                                    >
                                                        <option value={0} disabled>
                                                            CLIENT TYPE
                                                        </option>
                                                        {Object.entries(ClientTypesMap).map(([value, name], i) => (
                                                            <option value={value} key={i}>
                                                                {name}
                                                            </option>
                                                        ))}
                                                    </FormTextInput>
                                                </div>
                                            </div>
                                            <div className="form-row mb-4">
                                                <div className="col-12 col-md-6 mb-2 mb-sm-0">
                                                    <FormTextInput
                                                        name="Password"
                                                        placeholder="PASSWORD"
                                                        type="password"
                                                        icon={Lock}
                                                        className="wide"
                                                        toggleField
                                                    />
                                                </div>
                                                <div className="col-12 col-md-6 mb-2 mb-sm-0">
                                                    <FormTextInput
                                                        name="Confirm_Password"
                                                        placeholder="CONFIRM PASSWORD"
                                                        type="password"
                                                        icon={Lock}
                                                        className="wide"
                                                        toggleField
                                                    />
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    )}
                                </main>
                                <footer className="d-flex flex-column align-items-center">
                                    {atLastStage ? (
                                        <FormButton
                                            className="mb-4"
                                            type="submit"
                                            // disabled={
                                            //     filter(values.Files, (file) => file.status !== FileStatus.LOAD_ERROR)
                                            //         .length < 1
                                            // }
                                        >
                                            <span>Complete registration</span>
                                        </FormButton>
                                    ) : (
                                        <FormButton
                                            className="mb-4"
                                            type="button"
                                            disabled={!isValid || isMatch(initialValues, values)}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                setCurrentStage(RegistrationStages.SecondStage);
                                            }}
                                        >
                                            <span>Next Step</span>
                                        </FormButton>
                                    )}
                                    <span>
                                        Already have an account?{' '}
                                        <Link to="/login">
                                            <u>Log in</u>
                                        </Link>
                                    </span>
                                </footer>
                            </form>
                        )}
                    </Formik>
                </EntryFormContainer>
            </div>
        </Layout>
    );
};
