import { capitalize, orderBy } from 'lodash';
import moment from 'moment';
import React from 'react';
import { MoreVertical } from 'react-feather';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Cell } from 'react-table';
import { CustomDropdown, CustomDropdownItem, CustomPrimaryButton, DataTable } from '../../../../components';
import { PresentationalFormRadio } from '../../../../components/form';
import { AuthContext } from '../../../../context';
import { UpdateBookingModel } from '../../../../models/request';
import { AuthenticatedUser, Booking } from '../../../../models/response';
import { BookingsThunk } from '../../../../store/bookings/bookings.thunk';
import { ApiResponse } from '../../../../util/api-client';
import {
    BookingStatusAdminAvailableState,
    BookingStatuses,
    BookingStatusesMap,
    Roles,
} from '../../../../util/constants';
import { canCancelBooking, formatCurrency } from '../../../../util/misc';
import { formatDate } from '../../../../util/misc/formatters';

interface BookingsTableProps {
    bookings: Booking[];
    compact?: boolean;
    refreshFunction?: (...args: any[]) => any;
    meta?: ApiResponse<Booking[]>['meta'];
}

export const BookingsTable: React.FC<BookingsTableProps> = ({ bookings, meta, compact, refreshFunction }) => {
    const dispatch = useDispatch();
    const { user, isInAirport } = React.useContext(AuthContext.Context);

    const handleUpdateBooking = React.useCallback(
        (bookingDetails: UpdateBookingModel) => {
            dispatch(BookingsThunk.update(bookingDetails, refreshFunction));
        },
        [dispatch, refreshFunction]
    );

    const handleSetStatus = React.useCallback(
        (booking: Booking, status: BookingStatuses) => {
            if (booking.bookingStatus !== status) {
                booking.bookingStatus = status;
                handleUpdateBooking(new UpdateBookingModel(booking));
            }
        },
        [handleUpdateBooking]
    );

    const extraColumns = compact
        ? [
              {
                  Header: 'Date',
                  accessor: 'dateCreated',
                  Cell: ({ row: { original } }: Cell<Booking>) => {
                      return <em>{capitalize(moment(original.date_Of_Travel).fromNow())}</em>;
                  },
              },
          ]
        : [
              {
                  Header: 'Travel Date',
                  accessor: 'date_Of_Travel',
                  Cell: ({ row: { original } }: Cell<Booking>) => {
                      return <em>{formatDate(original.date_Of_Travel)}</em>;
                  },
              },
              {
                  Header: 'Client',
                  Cell: (cell: Cell<Booking>) => {
                      const _user = cell.row.original.applicationUser;

                      return user ? <span>{_user.business_Name || `${_user.firstName} ${_user.lastName}`}</span> : null;
                  },
              },
              {
                  Header: 'Service',
                  accessor: 'service.name' as any,
              },
          ];

    return (
        <DataTable
            columns={[
                ...extraColumns,
                {
                    Header: 'Booking Price',
                    Cell: (cell: Cell<Booking>) => <span>{formatCurrency(cell.row.original.price)}</span>,
                },
                {
                    Header: 'Passengers/ODCs/Family Members',
                    accessor: 'passengers' as any,
                    Cell: (cell: Cell<Booking>) => (
                        <span>
                            {cell.row.original.passengers.length > 0
                                ? cell.row.original.passengers.length
                                : cell.row.original.service.defaultOdc + cell.row.original.additionalOdc}
                        </span>
                    ), //additionalOdc + Service.defaultOdc
                },
                {
                    Header: 'Status',
                    accessor: 'bookingStatus',
                    Cell: (cell: Cell<Booking>) => (
                        <span>
                            {BookingStatusesMap[cell.row.original.bookingStatus]
                                .replace('_', ' ')
                                .replace('Canceled', 'Cancelled')}
                        </span>
                    ),
                },
                // {
                //     Header: 'Updated By',
                //     accessor: 'modifiedBy_FirstName',
                //     Cell: (cell: Cell<Booking>) => <span>{cell.row.original.modifiedBy_FirstName}</span>,
                // },
                {
                    Header: 'Actions',
                    Cell: ({ row: { original: booking } }: Cell<Booking>) => {
                        return (
                            <React.Fragment>
                                {BookingStatusAdminAvailableState[booking.bookingStatus]?.length > 0 &&
                                    isInAirport(booking.airportId, 'manage') &&
                                    (!!booking.bookingProtocols.length ? (
                                        <CustomDropdown
                                            className="mr-2"
                                            triggerComponent={() => (
                                                <CustomPrimaryButton className="small inverted">
                                                    <span>Change status</span>
                                                </CustomPrimaryButton>
                                            )}
                                        >
                                            <CustomDropdownItem className="justify-content-start">
                                                <PresentationalFormRadio checked />
                                                <span className="ml-2">
                                                    {BookingStatusesMap[booking.bookingStatus]
                                                        .replace('_', ' ')
                                                        .replace('Canceled', 'Cancelled')}
                                                </span>
                                            </CustomDropdownItem>
                                            {BookingStatusAdminAvailableState[booking.bookingStatus].map(
                                                (status, i) => (
                                                    <CustomDropdownItem
                                                        className="justify-content-start"
                                                        onClick={handleSetStatus.bind(undefined, booking, status)}
                                                        key={i}
                                                    >
                                                        <PresentationalFormRadio checked={false} />
                                                        <span className="ml-2">
                                                            {BookingStatusesMap[status]
                                                                .replace('_', ' ')
                                                                .replace('Canceled', 'Cancelled')}
                                                        </span>
                                                    </CustomDropdownItem>
                                                )
                                            )}
                                        </CustomDropdown>
                                    ) : null)}
                                <CustomDropdown
                                    className="mr-2"
                                    triggerComponent={() => {
                                        return (
                                            <CustomPrimaryButton className="small">
                                                <MoreVertical size={12} />
                                            </CustomPrimaryButton>
                                        );
                                    }}
                                >
                                    <CustomDropdownItem>
                                        <Link to={`/app/booking/${booking.id}`}>View Booking</Link>
                                    </CustomDropdownItem>
                                    {booking.bookingStatus === BookingStatuses.Processing && (
                                        <React.Fragment>
                                            {isInAirport(booking.airportId, 'manage') && (
                                                <CustomDropdownItem
                                                    onClick={handleSetStatus.bind(
                                                        undefined,
                                                        booking,
                                                        BookingStatuses.FlightCanceled
                                                    )}
                                                >
                                                    <span>Reject</span>
                                                </CustomDropdownItem>
                                            )}
                                            {canCancelBooking(booking, user as AuthenticatedUser) && (
                                                <CustomDropdownItem
                                                    onClick={handleSetStatus.bind(
                                                        undefined,
                                                        booking,
                                                        BookingStatuses.Canceled
                                                    )}
                                                >
                                                    <span>Cancel</span>
                                                </CustomDropdownItem>
                                            )}
                                            {user?.role === Roles.Admin && (
                                                <CustomDropdownItem
                                                    onClick={handleSetStatus.bind(
                                                        undefined,
                                                        booking,
                                                        BookingStatuses.Canceled
                                                    )}
                                                >
                                                    <span>Cancel</span>
                                                </CustomDropdownItem>
                                            )}
                                        </React.Fragment>
                                    )}
                                </CustomDropdown>
                            </React.Fragment>
                        );
                    },
                },
            ]}
            data={orderBy(bookings, (_) => new Date(_.dateCreated), 'desc')}
            meta={meta}
        />
    );
};
