import { IsEnum, IsPositive, MinLength, IsBoolean } from 'class-validator';
import { ClientTypes } from '../../../util/constants';
import { BaseModel } from '../base-model';
import { IsOptionalWhenPortharcourtServiceFalse } from '../../../util/validation/porthacourtvalidation';

export class CreateServiceModel implements BaseModel {
    @MinLength(3, {
        message: 'Must be at least $constraint1 character(s) long',
    })
    public Name: string = '';

    @MinLength(3, {
        message: 'Must be at least $constraint1 character(s) long',
    })
    public Description: string = '';

    @IsPositive({ message: 'Must be greater than zero' })
    public Price: number = 0;

    @IsOptionalWhenPortharcourtServiceFalse({ message: 'Must be greater than zero' })
    public defaultOdc?: number = 0;

    @IsEnum(ClientTypes, { message: 'Invalid selection' })
    public ServiceTypeId: number = 0;

    @IsBoolean({ message: 'Must be a boolean value' })
    public portharcourtService?: boolean = false;

    public beforeValidation() {
        this.ServiceTypeId = +this.ServiceTypeId;
    }
}
