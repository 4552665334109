import { filter } from 'lodash';
import React from 'react';
import { Plus } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, TabContent, TabPane } from 'reactstrap';
import styled from 'styled-components';
import { AppLayout, AuthLayout, CustomPrimaryButton, ServiceCardComponent, TabLink, TopBar } from '../../../components';
import { Breadcrumb } from '../../../components/breadcrumbs/breadcrumbs';
import { AuthContext } from '../../../context';
import { Service } from '../../../models/response';
import { ApplicationState } from '../../../store';
import { ServiceThunk } from '../../../store/services/services.thunk';
import { Roles } from '../../../util/constants';
import { CreateServiceModal } from './modals/create-service-modal';
import { UpdateServiceModal } from './modals/update-service-modal';
import { ServicesTable } from './services-table';

export const HeaderStyleWrapper = styled.div`
    display: flex;
    flex-direction: row;

    .left__header {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .right {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
`;

export const ServicePage: React.FC = () => {
    const breadcrumbs: Breadcrumb[] = [
        { name: 'Dashboard', path: '/app/dashboard' },
        { name: 'All Services', path: window.location.href },
    ];

    const [createModalOpen, setCreateModalOpen] = React.useState(false);
    const [updateModalOpen, setUpdateModalOpen] = React.useState(false);
    const [activeService, setActiveService] = React.useState<Service>();

    const links: TabLink[] = [{ index: 1, name: 'All Services' }];
    const [activeTab, setActiveTab] = React.useState(1);

    const dispatch = useDispatch();
    const { services, auth } = useSelector((state: ApplicationState) => state);
    const { canAccess, isClientOfType } = React.useContext(AuthContext.Context);
    const authUser = auth.authUser;

    React.useEffect(() => {
        dispatch(ServiceThunk.getAllServices());
    }, [dispatch]);

    const filtered_services = React.useMemo(
        () =>
            filter(
                services.services.sort((a, b) => Date.parse(b.dateCreated) - Date.parse(a.dateCreated)),
                (_) => canAccess(Roles.Admin) || isClientOfType(_.serviceTypeId)
            ),
        // eslint-disable-next-line
        [services, services.services]
    );

    return (
        <AuthLayout showSidebar={false}>
            <TopBar breadcrumbs={breadcrumbs} />
            <CreateServiceModal isOpen={createModalOpen} setIsOpen={setCreateModalOpen} />
            {activeService && (
                <UpdateServiceModal isOpen={updateModalOpen} setIsOpen={setUpdateModalOpen} service={activeService} />
            )}
            <AppLayout.Wrapper>
                <AppLayout.Header>
                    <HeaderStyleWrapper>
                        <div className="left__header">
                            <h3 className="header__title">All Services</h3>
                        </div>
                        {canAccess(Roles.Admin) && (
                            <div className="right">
                                <CustomPrimaryButton onClick={setCreateModalOpen.bind(undefined, true)}>
                                    <Plus size={14} strokeWidth={3} />
                                    <span>Create Service</span>
                                </CustomPrimaryButton>
                            </div>
                        )}
                    </HeaderStyleWrapper>
                </AppLayout.Header>

                {canAccess(Roles.Admin) && (
                    <AppLayout.Body>
                        <AppLayout.TabHeader links={links} activeTab={activeTab} setActiveTab={setActiveTab} />
                        <AppLayout.TabBody>
                            <TabContent activeTab={activeTab} className="tab__content">
                                <TabPane tabId={1}>
                                    {filtered_services?.length ? (
                                        <ServicesTable
                                            services={filtered_services}
                                            setActiveService={setActiveService}
                                            setUpdateModalOpen={setUpdateModalOpen}
                                        />
                                    ) : (
                                        <p>There are no services</p>
                                    )}
                                </TabPane>
                            </TabContent>
                        </AppLayout.TabBody>
                    </AppLayout.Body>
                )}
                {authUser?.role === Roles.Client && (
                    <Row>
                        {filtered_services?.length > 0 ? (
                            filtered_services.map((service) => (
                                <Col lg={4} key={service.id}>
                                    <ServiceCardComponent
                                        title={service.name}
                                        to={`/app/service/${service.id}/create-booking`}
                                        desc={service.description}
                                    />
                                </Col>
                            ))
                        ) : (
                            <p>There are no services</p>
                        )}
                    </Row>
                )}
            </AppLayout.Wrapper>
        </AuthLayout>
    );
};
