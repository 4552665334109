import * as queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import { ApplicationState, TransactionsThunk } from '../../store';
import { ViewBookingPage } from '../admin/bookings';
import { RegistrationRequestPage } from '../admin/manage-clients/registration-request/registration-request-page';
import { ViewFundingTransaction } from './views';

export const ViewTransactionServicePage: React.FC = () => {
    const dispatch = useDispatch();
    const [ready, setReady] = useState(false);
    const { transactionId } = useParams<{ transactionId: string }>();
    const transactions = useSelector((state: ApplicationState) => state.transactions);
    const { search } = useLocation();
    const { pageNumber, type } = React.useMemo(
        () => ({
            pageNumber: +(queryString.parse(search) as any).page || 1,
            type: +(queryString.parse(search) as any).type ?? 0,
        }),
        [search]
    );
    const { openTransaction: transaction } = transactions;

    useEffect(() => {
        if (type === 0) {
            dispatch(TransactionsThunk.retrieveByTrasactionId(transactionId, () => setReady(true)));
        } else if (type === 1) {
            dispatch(TransactionsThunk.retrieveTransactionTypebyId(transactionId, type, () => setReady(true)));
        }
    }, [dispatch, transactionId, pageNumber]);

    // if (!transaction || !ready) {
    //     return null;
    // }

    return (
        <React.Fragment>
            {type === 1 ? (
                <RegistrationRequestPage clientId={transaction?.transactionId} breadcrumbs={[]} abortFetchTransaction />
            ) : type === 2 ? (
                <ViewFundingTransaction bookingId={transaction?.transactionId} breadcrumbs={[]} abortFetchTransaction />
            ) : (
                <ViewBookingPage
                    isFunding={type === 2}
                    bookingId={transaction?.transactionId}
                    breadcrumbs={[]}
                    abortFetchTransaction
                />
            )}
        </React.Fragment>
    );
};
