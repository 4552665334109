import React from 'react';
import { Table } from 'reactstrap';
import { CustomTableStyleWrapper } from './custom-table.style';

type Props = {
    headData?: string[];
    children: React.ReactNode;
    showHead: boolean;
};

export const CustomTable: React.FC<Props> = (props) => {
    const { headData, children, showHead } = props;

    return (
        <CustomTableStyleWrapper>
            <Table responsive>
                {showHead && (
                    <thead>
                        <tr>
                            {headData &&
                                headData.length > 0 &&
                                headData.map((data: string) => <th key={data}>{data}</th>)}
                        </tr>
                    </thead>
                )}
                <tbody>{children}</tbody>
            </Table>
        </CustomTableStyleWrapper>
    );
};
