import { IsEmail, IsEnum, IsPositive, IsString, Matches, MinLength, ValidateIf } from 'class-validator';
import { Roles } from '../../../util/constants';
import { IsMobileNumber } from '../../../util/validation';
import { BaseModel } from '../base-model';

export class CreateUserModel implements BaseModel {
    @IsString()
    @Matches(/^[^\s].*[^\s]$/, { message: 'Cannot begin or end with a space' })
    @MinLength(3, {
        message: 'Must be at least $constraint1 characters long',
    })
    public Firstname: string = '';

    @IsString()
    @Matches(/^[^\s].*[^\s]$/, { message: 'Cannot begin or end with a space' })
    @MinLength(3, {
        message: 'Must be at least $constraint1 characters long',
    })
    @ValidateIf((o: CreateUserModel) => !!o.MiddleName)
    public MiddleName: string = '';

    @IsString()
    @Matches(/^[^\s].*[^\s]$/, { message: 'Cannot begin or end with a space' })
    @MinLength(3, {
        message: 'Must be at least $constraint1 characters long',
    })
    public LastName: string = '';

    @IsEmail(undefined, { message: 'Invalid email format' })
    public Email: string = '';

    @IsEnum(Roles, { message: 'Invalid selection' })
    public Role: string = '';

    @IsMobileNumber({ message: 'Invalid phone number format' })
    public PhoneNumber: string = '';

    @IsPositive({ message: 'Invalid selection' })
    public AirportId: number = 0;

    public beforeValidation() {
        if (this.Firstname.endsWith('  ')) {
            this.Firstname = this.Firstname.trim();
        }

        if (this.MiddleName?.endsWith('  ')) {
            this.MiddleName = this.MiddleName.trim();
        }

        if (this.LastName.endsWith('  ')) {
            this.LastName = this.LastName.trim();
        }

        this.AirportId = +this.AirportId;
    }
}

export class CreateProtocolModel implements BaseModel {
    @IsString()
    @Matches(/^[^\s].*[^\s]$/, { message: 'Cannot begin or end with a space' })
    @MinLength(3, {
        message: 'Must be at least $constraint1 characters long',
    })
    public FirstName: string = '';

    @IsString()
    @Matches(/^[^\s].*[^\s]$/, { message: 'Cannot begin or end with a space' })
    @MinLength(3, {
        message: 'Must be at least $constraint1 characters long',
    })
    public LastName: string = '';

    @IsEmail(undefined, { message: 'Invalid email format' })
    public Email: string = '';

    @IsEnum(Roles, { message: 'Invalid selection' })
    public Role: string = '';

    @IsMobileNumber({ message: 'Invalid phone number format' })
    public PhoneNumber: string = '';

    @IsPositive({ message: 'Invalid selection' })
    public AirportId: number = 0;

    public beforeValidation() {
        if (this.FirstName.endsWith('  ')) {
            this.FirstName = this.FirstName.trim();
        }

        if (this.LastName.endsWith('  ')) {
            this.LastName = this.LastName.trim();
        }

        this.AirportId = +this.AirportId;
    }
}

export class AssigneProtocolModel implements BaseModel {
    @IsEnum(Roles, { message: 'Invalid selection' })
    public protocol: string = '';

    // @IsPositive({ message: 'Invalid selection' })
    // public AirportId: number = 0;

    public beforeValidation() {
        // if (this.FirstName.endsWith('  ')) {
        //     this.FirstName = this.FirstName.trim();
        // }
        // if (this.LastName.endsWith('  ')) {
        //     this.LastName = this.LastName.trim();
        // }
        // this.AirportId = +this.AirportId;
    }
}
