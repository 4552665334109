import React from 'react';
import { ChevronLeft, Search, Send } from 'react-feather';
import { Link } from 'react-router-dom';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { history, isPreviousLocationWithinApp } from '../../util/history';
import { generateClassNames } from '../../util/misc/generate-class-names';
import { CustomPrimaryButton } from '../utilities/custom-primary-button/custom-primary-button';
import { AppLayoutStyle, Header } from './app-layout-style';

export interface TabLink {
    index: number;
    name: string;
    query?: [] | [string, any];
}

const Wrapper: React.FC = ({ children }) => {
    return <AppLayoutStyle>{children}</AppLayoutStyle>;
};

const Body: React.FC<{ className?: string }> = ({ children, className }) => {
    return <div className={generateClassNames('content-body', className)}>{children}</div>;
};

const TabHeader: React.FC<{
    links?: TabLink[];
    activeTab?: number;
    setActiveTab?: React.Dispatch<React.SetStateAction<number>>;
    searchable?: boolean;
    handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    value?: string;
    handleSearch?: () => void;
    handleExport?: () => void;
    isSearchNotOnChange?: boolean;
}> = ({
    activeTab,
    setActiveTab,
    links,
    children,
    searchable,
    handleChange,
    value,
    handleSearch,
    handleExport,
    isSearchNotOnChange,
}) => {
    return (
        <div className="tab-header bold" style={{ fontWeight: 600 }}>
            {links && activeTab !== undefined && setActiveTab && (
                <Nav className="tab-nav" tabs>
                    {links.map(({ index, name, query }) => (
                        <NavItem className="tab-nav__item" key={index}>
                            <NavLink
                                className="tab-nav__link"
                                active={index === activeTab}
                                onClick={() => {
                                    setActiveTab(index);

                                    if (query) {
                                        history.push(query.length ? `?${query[0]}=${query[1]}` : '?');
                                    }
                                }}
                            >
                                {name}
                            </NavLink>
                        </NavItem>
                    ))}
                    {searchable && (
                        <div className="tab-nav__serachContainer">
                            <div className="tab-nav__serachWrapper">
                                <Search size={18} strokeWidth={2} className="tab-nav__searchIcon" />
                                <input
                                    type="text"
                                    className="tab-nav__searchInput"
                                    placeholder="Search"
                                    onChange={handleChange}
                                    value={value}
                                />
                            </div>
                            {isSearchNotOnChange && (
                                <div>
                                    <CustomPrimaryButton
                                        style={{
                                            borderRadius: '10px',
                                            alignSelf: 'center',
                                            padding: '1.2rem .8rem',
                                            marginTop: '5px',
                                        }}
                                        onClick={handleSearch}
                                        className="inverted small"
                                    >
                                        <Send size={10} strokeWidth={3} />
                                        <span>{'Submit'}</span>
                                    </CustomPrimaryButton>
                                </div>
                            )}
                        </div>
                    )}
                    {handleExport && (
                        <CustomPrimaryButton
                            style={{
                                borderRadius: '10px',
                                alignSelf: 'center',
                                padding: '1.2rem .8rem',
                                marginTop: '5px',
                            }}
                            onClick={handleExport}
                            className="inverted small"
                        >
                            <Send size={10} strokeWidth={3} />
                            <span>{'Export'}</span>
                        </CustomPrimaryButton>
                    )}
                </Nav>
            )}
            {children}
        </div>
    );
};

const TabBody: React.FC<React.ComponentPropsWithoutRef<'div'>> = ({ className, ...props }) => {
    return <div className={generateClassNames('tab-body', className)} {...props}></div>;
};

const HeaderBackButton: React.FC<{
    showIf?: () => boolean;
    action?: () => void;
}> = ({ showIf, action }) => {
    return showIf?.() || isPreviousLocationWithinApp() ? (
        <Link
            className="header__back-button"
            to={'#'}
            onClick={(e) => {
                e.preventDefault();

                if (action && showIf?.()) {
                    action();
                    return;
                }

                history.goBack();
            }}
        >
            <ChevronLeft size={20} strokeWidth={3} />
        </Link>
    ) : null;
};

export const AppLayout = {
    Wrapper,
    Header,
    HeaderBackButton,
    Body,
    TabHeader,
    TabBody,
};
