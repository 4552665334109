import { IsIn, IsString, Matches, MinLength } from 'class-validator';
import { BusinessNatures } from '../../../util/constants';
import { BaseModel } from '../base-model';
import { CommonRegistrationModel } from './common-registration-model';

export class CreateCorporateOrganizationModel extends CommonRegistrationModel implements BaseModel {
    @IsString()
    @MinLength(3, {
        message: 'Must be at least $constraint1 characters long',
    })
    public Business_Name: string = '';

    @IsIn(BusinessNatures, { message: 'Invalid selection' })
    public Business_Nature: string = '';

    @Matches(/^[12]\d{3}$/, { message: 'Invalid year format' })
    public Creation_Year: string = '';

    @IsString()
    @MinLength(3, {
        message: 'Must be at least $constraint1 characters long',
    })
    public Registration_Number: string = '';
}
