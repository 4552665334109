import { IsAlphanumeric, IsNotEmpty, IsNumber, Min } from 'class-validator';
import { BaseModel } from '../../models/request/base-model';

export class RenewalsModel implements BaseModel {
    @IsNotEmpty({ message: 'Please enter date' })
    public startDate = '';

    @IsAlphanumeric()
    public applicationUserId = '';

    public beforeValidation() {
        this.startDate = this.startDate;
        this.applicationUserId = this.applicationUserId;
    }
}
