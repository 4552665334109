import { PDFDownloadLink } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { find } from 'lodash';
import moment from 'moment';
import React, { useContext } from 'react';
import { Download, File, MapPin, Send } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { Invoice } from '../../..';
import {
    AppLayout,
    AuthLayout,
    CustomDropdown,
    CustomDropdownItem,
    CustomPrimaryButton,
    CustomTable,
    TopBar,
} from '../../../../components';
import { Breadcrumb } from '../../../../components/breadcrumbs/breadcrumbs';
import { PresentationalFormRadio } from '../../../../components/form';
import { AuthContext } from '../../../../context';
import { ChangeUserStatusModel } from '../../../../models/request';
import { Booking, Transaction, User, UserFiles } from '../../../../models/response';
import { AirportsThunk, ApplicationState, TransactionsThunk } from '../../../../store';
import { NotificationsThunk } from '../../../../store/notifications/notifications.thunk';
import { UsersThunk } from '../../../../store/users/users.thunk';
import { ApiClient } from '../../../../util/api-client';
import { ClientStatuses, NotificationTypes, Roles } from '../../../../util/constants';
import { history } from '../../../../util/history';
import { formatDate } from '../../../../util/misc/formatters';
import { HeaderStyleWrapper } from '../../bookings/view-booking/view-booking-page.style';
import { RegistrationRequestStyleWrapper } from './registration-request.style';

interface Props {
    clientId?: string;
    breadcrumbs?: Breadcrumb[];
    abortFetchTransaction?: boolean;
}

export const RegistrationRequestPage: React.FC<Props> = ({
    clientId: id,
    breadcrumbs: crumbs,
    abortFetchTransaction,
}) => {
    const clientId: string = id ?? useParams<any>().client_id;
    const { userDetails: client } = useSelector(({ users }: ApplicationState) => users);
    const { openTransaction: transaction } = useSelector(({ transactions }: ApplicationState) => transactions);
    const { bookingDetails: booking } = useSelector(({ bookings }: ApplicationState) => bookings);
    const { airports } = useSelector(({ airports }: ApplicationState) => airports);
    const { canAccess } = useContext(AuthContext.Context);
    const dispatch = useDispatch();

    const breadcrumbs: Breadcrumb[] = crumbs ?? [
        { name: 'Dashboard', path: '/app/dashboard' },
        { name: 'All Clients', path: window.location.href },
    ];

    const handleSetStatus = React.useCallback(
        (status: boolean) => {
            if (client && client.account_Status !== status) {
                client.account_Status = status;
                dispatch(
                    UsersThunk.changeUserStatus(new ChangeUserStatusModel(client), () => {
                        if (status === true) history.replace(`/app/client/${client.id}`);
                    })
                );
            }
        },
        [dispatch, client]
    );

    const fetchFile = React.useCallback(
        async (file: UserFiles) => {
            const config: RequestInit = {
                ...ApiClient.getFetchConfig(),
            };

            try {
                dispatch(NotificationsThunk.create(NotificationTypes.Info, `Downloading [${file.name}]`));

                const response = await fetch(`${process.env.REACT_APP_BASE_URL}/users/clientfile/${file.id}`, config);

                try {
                    const downloadedFile = await response.blob();
                    saveAs(downloadedFile, file.name + file.fileType);
                } catch (e) {
                    dispatch(NotificationsThunk.create(NotificationTypes.Error, 'Error saving file'));
                }
            } catch (e) {
                dispatch(NotificationsThunk.create(NotificationTypes.Error, 'Error downloading file'));
            }
        },
        [dispatch]
    );

    const exportFile = React.useCallback(
        async (client) => {
            const config: RequestInit = {
                ...ApiClient.getFetchConfig(),
            };

            const name = client.firstName ? `${client.firstName} ${client.lastName}` : client.business_Name;

            try {
                dispatch(NotificationsThunk.create(NotificationTypes.Info, `Downloading document`));

                const response = await fetch(
                    `${process.env.REACT_APP_BASE_URL}/users/form/${client && client.id}`,
                    config
                );

                try {
                    const downloadedFile = await response.blob();
                    saveAs(downloadedFile, name + '.pdf');
                } catch (e) {
                    dispatch(NotificationsThunk.create(NotificationTypes.Error, 'Error saving file'));
                }
            } catch (e) {
                dispatch(NotificationsThunk.create(NotificationTypes.Error, 'Error downloading file'));
            }
        },
        [dispatch]
    );

    React.useEffect(() => {
        dispatch(UsersThunk.getUser(clientId));
        dispatch(AirportsThunk.list());

        if (!abortFetchTransaction) {
            dispatch(TransactionsThunk.retrieveByTrasactionId(clientId));
        }
    }, [dispatch, clientId, abortFetchTransaction]);

    return (
        <AuthLayout showSidebar={false}>
            <RegistrationRequestStyleWrapper>
                <TopBar breadcrumbs={breadcrumbs} />
                {true && (
                    <React.Fragment>
                        <AppLayout.Wrapper>
                            <AppLayout.Header>
                                <HeaderStyleWrapper>
                                    <div className="left__header d-flex align-items-center">
                                        <AppLayout.HeaderBackButton />
                                        <div className="d-flex flex-column">
                                            <h3 className="header__title">Client registration request</h3>
                                            {transaction ? (
                                                <React.Fragment>
                                                    <span className="mb-2">Invoice No: {transaction?.id ?? 'N/A'}</span>
                                                </React.Fragment>
                                            ) : null}
                                            <b
                                                style={{
                                                    color: 'var(--primary-color)',
                                                }}
                                            >
                                                {client?.transaction?.paymentStatus}
                                            </b>
                                        </div>
                                    </div>
                                    <div className="right text-right">
                                        <a
                                            download
                                            rel="noopener noreferrer"
                                            target="_blank"
                                            href={`${process.env.REACT_APP_BASE_URL}/users/form/${client && client.id}`}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                exportFile(client);
                                            }}
                                        >
                                            <CustomPrimaryButton className="inverted small">
                                                <Send size={10} strokeWidth={3} />
                                                <span>Export Form</span>
                                            </CustomPrimaryButton>
                                        </a>
                                    </div>
                                </HeaderStyleWrapper>
                            </AppLayout.Header>
                            <div className="contentBox">
                                <Row>
                                    <Col lg="9">
                                        <Row>
                                            <Col lg="6">
                                                <h3>
                                                    {client?.firstName
                                                        ? `${client.firstName} ${client.lastName}`
                                                        : client?.business_Name}
                                                </h3>
                                                <p>{moment(client?.dateCreated).format('LLLL')}</p>
                                            </Col>
                                        </Row>
                                        <p>
                                            <MapPin size={12} strokeWidth={3} className="mr-2" />
                                            <span>{client?.registered_Address}</span>
                                        </p>
                                        <Row>
                                            <Col lg="9">
                                                <CustomTable showHead={false}>
                                                    {client?.state && (
                                                        <tr>
                                                            <th>State</th>
                                                            <td>{client.state}</td>
                                                        </tr>
                                                    )}
                                                    <tr>
                                                        <th>Phone Number</th>
                                                        <td>{client?.phoneNumber}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Customer AIN</th>
                                                        <td>{client?.customerAIN}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Email Address</th>
                                                        <td>{client?.email}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Payment Status</th>
                                                        <td>{client?.paymentStatus ?? 'Unpaid'}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Updated By</th>
                                                        <td>{client?.updatedBy ?? 'N/A'}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Airport Name</th>
                                                        <td>
                                                            {find(airports, { id: client?.airportId })?.name ?? 'N/A'}
                                                        </td>
                                                    </tr>
                                                </CustomTable>
                                            </Col>
                                            <Col lg="6">
                                                {!!client?.clientFiles.length && (
                                                    <CustomTable showHead={false}>
                                                        <tr>
                                                            <th>Uploaded Files</th>
                                                            <td></td>
                                                        </tr>
                                                        {client?.clientFiles.map((file, i) => (
                                                            <tr key={i}>
                                                                <td colSpan={2}>
                                                                    <div className="downloadBox">
                                                                        <div className="iconBox">
                                                                            <img
                                                                                src={require('../../../../assets/images/doc.svg')}
                                                                                alt="doc"
                                                                            />
                                                                        </div>
                                                                        <div className="contentWrapper">
                                                                            <p>{file.name + file.fileType}</p>
                                                                        </div>
                                                                        <div className="linkBox text-right">
                                                                            <a
                                                                                download
                                                                                rel="noopener noreferrer"
                                                                                target="_blank"
                                                                                href={`${process.env.REACT_APP_BASE_URL}/users/clientfile/${file.id}`}
                                                                                onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    fetchFile(file);
                                                                                }}
                                                                            >
                                                                                <Download size={12} className="mr-2" />
                                                                                <span>Download</span>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                        <tr>
                                                            <td></td>
                                                        </tr>
                                                    </CustomTable>
                                                )}
                                            </Col>
                                        </Row>
                                        {canAccess([Roles.Accountant, Roles.Auditor, Roles.Commercial], true) ? null : (
                                            <div className="btnBox">
                                                {(client && client.profile_Status === ClientStatuses.New) ||
                                                    (client?.profile_Status === ClientStatuses.Processing && (
                                                        <CustomPrimaryButton
                                                            type="button"
                                                            onClick={handleSetStatus.bind(undefined, true)}
                                                        >
                                                            Approve
                                                        </CustomPrimaryButton>
                                                    ))}

                                                <CustomDropdown
                                                    triggerComponent={() =>
                                                        !canAccess([Roles.AirportProtocolCoordinator], true) ? (
                                                            <CustomPrimaryButton className="big inverted">
                                                                <span>Change Status</span>
                                                            </CustomPrimaryButton>
                                                        ) : null
                                                    }
                                                >
                                                    <CustomDropdownItem
                                                        className="justify-content-start"
                                                        onClick={handleSetStatus.bind(undefined, true)}
                                                    >
                                                        <PresentationalFormRadio
                                                            checked={client && client.account_Status}
                                                        />
                                                        <span className="ml-2">Active</span>
                                                    </CustomDropdownItem>
                                                    <CustomDropdownItem
                                                        className="justify-content-start"
                                                        onClick={handleSetStatus.bind(undefined, false)}
                                                    >
                                                        <PresentationalFormRadio
                                                            checked={client && !client.account_Status}
                                                        />
                                                        <span className="ml-2">Inactive</span>
                                                    </CustomDropdownItem>
                                                </CustomDropdown>
                                            </div>
                                        )}
                                    </Col>
                                </Row>
                            </div>
                        </AppLayout.Wrapper>
                    </React.Fragment>
                )}
            </RegistrationRequestStyleWrapper>
        </AuthLayout>
    );
};
