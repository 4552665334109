import { capitalize, orderBy } from 'lodash';
import moment from 'moment';
import React, { useCallback, useContext } from 'react';
import { MoreVertical } from 'react-feather';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Link, useParams } from 'react-router-dom';
import { Cell } from 'react-table';
import { Download, File, Phone, Send } from 'react-feather';
import { CustomDropdown, CustomDropdownItem, CustomPrimaryButton, DataTable } from '../../../components';
import { AuthContext } from '../../../context';
import { Transaction, User } from '../../../models/response';
import { NotificationsThunk, TransactionsActions } from '../../../store';
import { ApiClient, ApiResponse } from '../../../util/api-client';
import { NotificationTypes, Roles } from '../../../util/constants';
import { PaymentModal } from '../../../components/wallet';
import saveAs from 'file-saver';

interface PaymentTransactionTableProps {
    transactions: Transaction[];
    hideOptions?: boolean;
    refreshFunction?: (...args: any[]) => any;
    meta?: ApiResponse<Transaction[]>['meta'];
}

export const PaymentTransactionsTable: React.FC<PaymentTransactionTableProps> = ({
    transactions,
    meta,
    hideOptions,
}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { user } = useContext(AuthContext.Context)!;
    const [isPayment, setIsPaymentModal] = React.useState(false);
    const [selectedTransactionId, setSelectedTransactionId] = React.useState<number>(0);
    const { client_id } = useParams<any>();

    const pageNumber = 1;

    const handlePayNowClick = (id: number) => {
        setSelectedTransactionId(id);
        setIsPaymentModal(true);
    };

    const exportRenewalInvoice = useCallback(
        async (renewalId: number) => {
            const config: RequestInit = {
                ...ApiClient.getFetchConfig(),
            };

            try {
                dispatch(NotificationsThunk.create(NotificationTypes.Info, `Downloading document`));

                const response = await fetch(
                    `${process.env.REACT_APP_BASE_URL}/renewals/export-renewal-invoice?renewalid=${renewalId}`,
                    config
                );

                try {
                    const downloadedFile = await response.blob();
                    saveAs(downloadedFile, `Invoice_${renewalId}.pdf`);
                } catch (e) {
                    dispatch(NotificationsThunk.create(NotificationTypes.Error, 'Error saving file'));
                }
            } catch (e) {
                dispatch(NotificationsThunk.create(NotificationTypes.Error, 'Error downloading file'));
            }
        },
        [dispatch]
    );

    return (
        <>
            <PaymentModal
                isOpen={isPayment}
                setIsOpen={setIsPaymentModal}
                client_id={client_id}
                id={selectedTransactionId}
                pageNumber={pageNumber}
            />

            <DataTable
                columns={[
                    {
                        Header: 'Date',
                        Cell: ({ row: { original } }: Cell<Transaction>) => (
                            <em>{moment(original.startDate).format('Do MMMM, YYYY')}</em>
                        ),
                    },
                    {
                        Header: 'Amount',
                        accessor: 'amount' as any,
                        Cell: ({ row: { original } }: Cell<Transaction>) => (
                            <span>NGN {original.amount.toLocaleString()}</span>
                        ),
                    },
                    {
                        Header: 'Renewal Payment Status',
                        accessor: 'renewalPaymentStatus' as any,
                        Cell: ({ row: { original } }: Cell<Transaction>) => (
                            <span>{original.renewalPaymentStatus ?? 'Unpaid'}</span>
                        ),
                    },
                    {
                        Header: 'Payment',
                        Cell: ({ row: { original } }: Cell<Transaction>) =>
                            original.renewalPaymentStatus !== 'Paid' ? (
                                <CustomPrimaryButton className="small" onClick={() => handlePayNowClick(original.id)}>
                                    Pay Now
                                </CustomPrimaryButton>
                            ) : null,
                    },

                    {
                        Header: 'Get Receipt',
                        Cell: ({ row: { original } }: Cell<Transaction>) =>
                            original.renewalPaymentStatus === 'Paid' ? (
                                <CustomPrimaryButton
                                    className="inverted small"
                                    onClick={() => exportRenewalInvoice(original.id)}
                                >
                                    <File size={10} strokeWidth={3} />
                                    <span>Get Receipt</span>
                                </CustomPrimaryButton>
                            ) : null,
                    },
                ]}
                data={orderBy(transactions, (_) => new Date(_.dateCreated), 'desc')}
                meta={meta}
            />
        </>
    );
};
