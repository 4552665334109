import React from 'react';
import { SidebarMenu, TopNavigation } from '../index';
import { LayoutStyleWrapper } from './auth-layout.style';

type Props = {
    showSidebar?: boolean;
};

class AuthLayout extends React.Component<Props, {}> {
    public render() {
        return (
            <React.Fragment>
                <TopNavigation />
                <LayoutStyleWrapper>
                    <SidebarMenu />
                    <div className="layoutWrapper">
                        <div className="mainWrapper">
                            <div className="bodyContentSwitch">{this.props.children}</div>
                        </div>
                    </div>
                </LayoutStyleWrapper>
            </React.Fragment>
        );
    }
}

export default AuthLayout;
