import { Transaction } from '../../models/response';
import { ApiResponse } from '../../util/api-client';
import { ReduxAction } from '../common/redux-action';
import { TransactionRetrievalPayload, TransactionsActionNames } from './transactions.types';

export class TransactionsActions {
    public static list(
        transactions: Transaction[],
        meta: ApiResponse<Transaction[]>['meta']
    ): ReduxAction<TransactionsActionNames.LIST> {
        return {
            type: TransactionsActionNames.LIST,
            payload: { transactions, meta },
        };
    }

    public static retrieve(
        details: TransactionRetrievalPayload
    ): ReduxAction<TransactionsActionNames.RETRIEVE, TransactionRetrievalPayload> {
        return {
            type: TransactionsActionNames.RETRIEVE,
            payload: details,
        };
    }

    public static retrieveType(
        transactions: Transaction[],
        meta: ApiResponse<Transaction[]>['meta']
    ): ReduxAction<TransactionsActionNames.RETRIEVE_TYPE> {
        return {
            type: TransactionsActionNames.RETRIEVE_TYPE,
            payload: { transactions, meta },
        };
    }

    public static payRenewalId(
        transactions: Transaction[],
        meta: ApiResponse<Transaction[]>['meta']
    ): ReduxAction<TransactionsActionNames.PAY_RENEWAL_ID> {
        return {
            type: TransactionsActionNames.PAY_RENEWAL_ID,
            payload: { transactions, meta },
        };
    }
}
