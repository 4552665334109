import { Allow, IsEmail, IsEnum, Matches, MinLength, ValidateIf } from 'class-validator';
import { Genders, MeansOfId } from '../../../util/constants';
import { IsMobileNumber, ShouldMatchField } from '../../../util/validation';
import { CONTAINS_DIGIT, CONTAINS_SPECIAL_CHARACTER, CONTAINS_UPPERCASE, PASSWORD_MIN_LENGTH } from '../auth/constants';
import { BaseModel } from '../base-model';
import { CONTAINS_LOWERCASE } from './../auth/constants';

export class CreateIndividualModel implements BaseModel {
    @MinLength(3, {
        message: 'Must be at least $constraint1 characters long',
    })
    public FirstName: string = '';

    // @MinLength(3, {
    //     message: 'Must be at least $constraint1 characters long',
    // })
    // @ValidateIf((o: CreateIndividualModel) => !!o.MiddleName)
    // public MiddleName: string = '';

    @MinLength(3, {
        message: 'Must be at least $constraint1 characters long',
    })
    public LastName: string = '';

    @MinLength(3, {
        message: 'Must be at least $constraint1 characters long',
    })
    @ValidateIf((o: CreateIndividualModel) => !!o.Registered_Address)
    public Registered_Address: string = '';

    @IsEmail(undefined, { message: 'Invalid email format' })
    public Email: string = '';

    @IsMobileNumber({ message: 'Invalid phone number format' })
    public PhoneNumber: string = '';

    @MinLength(3, {
        message: 'Must be at least $constraint1 characters long',
    })
    @IsEnum(MeansOfId, { message: 'Invalid selection' })
    public MeansOfId: number = 0;

    @MinLength(3, {
        message: 'Must be at least $constraint1 characters long',
    })
    public Identification: string = '';

    // @ConvertToNumber()
    // @IsEnum(ClientTypes, { message: 'Invalid selection' })
    @Allow()
    public ClientTypeId: number = 0;

    @IsEnum(Genders, { message: 'Invalid selection' })
    @ValidateIf((o: CreateIndividualModel) => !!o.Gender)
    public Gender?: string = '';

    @Matches(CONTAINS_DIGIT, {
        message: 'Must contain a digit',
    })
    @Matches(CONTAINS_SPECIAL_CHARACTER, {
        message: 'Must contain a special character',
    })
    @Matches(CONTAINS_UPPERCASE, {
        message: 'Must contain an uppercase letter',
    })
    @Matches(CONTAINS_LOWERCASE, {
        message: 'Must contain an lowercase letter',
    })
    @MinLength(PASSWORD_MIN_LENGTH, {
        message: `Must be at least $constraint1 character(s) long`,
    })
    public Password: string = '';

    @ShouldMatchField('Password', { message: 'Must match password' })
    public Confirm_Password: string = '';

    public beforeValidation() {
        this.ClientTypeId = +this.ClientTypeId;
    }
}
