import { Dispatch } from 'redux';
import {
    ChangeUserStatusModel,
    CreateProtocolModel,
    CreateUserModel,
    EditUserModel,
    EditUserProfileModel,
    UpdateIndividualClientModel,
} from '../../models/request';
import { UpdateClientModel } from '../../models/request/users/update-client.model';
import { UsersService } from '../../services';
import { QueryParams } from '../../util/api-client';
import { ClientStatuses, NotificationTypes } from '../../util/constants';
import { history } from '../../util/history';
import { generateLoader } from '../../util/misc';
import { NotificationsThunk } from '../notifications/notifications.thunk';
import { UsersActions } from './users.actions';
import {
    AddUserAction,
    GetAllProtocolAction,
    GetClientsAction,
    GetProtocolAction,
    GetUserAction,
    GetUserActivities,
    GetUserBookings,
    GetUsersAction,
    UpdateClientAction,
    UpdateUserAction,
} from './users.types';

export class UsersThunk {
    public static createUser(userDetails: CreateUserModel | CreateProtocolModel, callback?: () => void) {
        return async (dispatch: Dispatch<AddUserAction>) => {
            try {
                const response = await generateLoader(
                    UsersService.createUser(userDetails),
                    dispatch,
                    'Creating user account'
                );

                if (response.data) {
                    callback?.();
                    NotificationsThunk.create(NotificationTypes.Success, response.message)(dispatch);
                    dispatch(UsersActions.addUser(response.data));
                    return;
                }

                NotificationsThunk.create(NotificationTypes.Error, 'User creation failed')(dispatch);
                throw new Error('User creation failed');
            } catch (error) {}
        };
    }

    public static UpdateUser(userDetails: EditUserModel, closeModelCalback?: () => void, callback?: () => void) {
        return async (dispatch: Dispatch<UpdateUserAction>) => {
            try {
                const response = await generateLoader(
                    UsersService.updateUser(userDetails),
                    dispatch,
                    'Updating user account'
                );

                if (response.ok) {
                    closeModelCalback?.();
                    callback?.();

                    NotificationsThunk.create(NotificationTypes.Success, 'User updated successfully')(dispatch);

                    dispatch(UsersActions.updateUser(response.data));

                    return;
                }

                NotificationsThunk.create(NotificationTypes.Error, 'Updating user account failed')(dispatch);
            } catch (error) {
                NotificationsThunk.create(NotificationTypes.Error, 'Something went wrong')(dispatch);
            }
        };
    }

    public static createCorporateOrganization(details: FormData) {
        return async (dispatch: Dispatch) => {
            try {
                const response = await generateLoader(
                    UsersService.createCorporateOrganization(details),
                    dispatch,
                    'Creating corporate organization account'
                );

                if (response.ok) {
                    history.replace('/registration-success', {
                        successfulRegistration: true,
                    });
                    return;
                }
            } catch (error) {
                NotificationsThunk.create(NotificationTypes.Error, 'Something went wrong')(dispatch);
            }
        };
    }

    public static createGovernmentOfficial(details: FormData) {
        return async (dispatch: Dispatch) => {
            try {
                const response = await generateLoader(
                    UsersService.createGovernmentOfficial(details),
                    dispatch,
                    'Creating government official account'
                );

                if (response.ok) {
                    history.replace('/registration-success', {
                        successfulRegistration: true,
                    });
                    return;
                }
            } catch (error) {
                NotificationsThunk.create(NotificationTypes.Error, 'Something went wrong')(dispatch);
            }
        };
    }

    public static createIndividual(details: FormData) {
        return async (dispatch: Dispatch) => {
            try {
                const response = await generateLoader(
                    UsersService.createIndividual(details),
                    dispatch,
                    'Creating individual account'
                );

                if (response.ok) {
                    history.replace('/registration-success', {
                        successfulRegistration: true,
                    });
                    return;
                }
            } catch (error) {
                NotificationsThunk.create(NotificationTypes.Error, 'Something went wrong')(dispatch);
            }
        };
    }

    public static getAllUsers(pageNumber?: number, searchTerm?: string) {
        return async (dispatch: Dispatch<GetUsersAction>) => {
            try {
                const response = await generateLoader(
                    UsersService.getUsers(pageNumber, searchTerm),
                    dispatch,
                    'Fetching users'
                );

                if (response.ok) {
                    if (
                        response.data?.length &&
                        response.meta &&
                        pageNumber &&
                        (pageNumber > response.meta.lastPage || pageNumber < response.meta.firstPage)
                    ) {
                        history.replace('?page=1');
                        return;
                    }

                    dispatch(UsersActions.getUsers(response));
                    return;
                }

                throw new Error();
            } catch (error) {
                NotificationsThunk.create(
                    NotificationTypes.Error,
                    'Fetching all users failed. Kindly Refresh'
                )(dispatch);
            }
        };
    }

    public static getProtocols(pageNumber?: number, searchTerm?: string) {
        return async (dispatch: Dispatch<GetProtocolAction>) => {
            try {
                const response = await generateLoader(
                    UsersService.getProtocols(pageNumber, searchTerm),
                    dispatch,
                    'Fetching Protocols'
                );

                if (response.ok) {
                    if (
                        response.data?.length &&
                        response.meta &&
                        pageNumber &&
                        (pageNumber > response.meta.lastPage || pageNumber < response.meta.firstPage)
                    ) {
                        history.replace('?page=1');
                        return;
                    }

                    dispatch(UsersActions.getProtocols(response));
                    return;
                }

                throw new Error();
            } catch (error) {
                NotificationsThunk.create(
                    NotificationTypes.Error,
                    'Fetching all protocols failed. Kindly Refresh'
                )(dispatch);
            }
        };
    }

    public static getAllProtocols() {
        return async (dispatch: Dispatch<GetAllProtocolAction>) => {
            try {
                const response = await generateLoader(UsersService.getAllProtocols(), dispatch, 'Fetching Protocols');

                if (response.ok) {
                    dispatch(UsersActions.getAllProtocols(response));
                    return;
                }

                throw new Error();
            } catch (error) {
                NotificationsThunk.create(
                    NotificationTypes.Error,
                    'Fetching all protocols failed. Kindly Refresh'
                )(dispatch);
            }
        };
    }

    public static getClients(filter?: ClientStatuses, pageNumber?: number, searchTerms?: string) {
        return async (dispatch: Dispatch<GetClientsAction>) => {
            try {
                const response = await generateLoader(
                    UsersService.getClients(filter, pageNumber, searchTerms),
                    dispatch,
                    'Fetching clients'
                );

                if (response.data) {
                    if (
                        response.data?.length &&
                        response.meta &&
                        pageNumber &&
                        (pageNumber > response.meta.lastPage || pageNumber < response.meta.firstPage)
                    ) {
                        history.replace('?page=1');
                        return;
                    }

                    dispatch(UsersActions.getClients(response));
                    return;
                }

                throw new Error();
            } catch (error) {
                NotificationsThunk.create(
                    NotificationTypes.Error,
                    'Fetching all clients failed. Kindly Refresh'
                )(dispatch);
            }
        };
    }

    public static getDashboardStats() {
        return async (dispatch: Dispatch) => {
            try {
                const response = await generateLoader(UsersService.getDashboardStats(), dispatch, 'Fetching stats');

                if (response.data) {
                    dispatch(UsersActions.getDashboardStats(response.data));
                    return;
                }

                throw new Error();
            } catch (error) {
                NotificationsThunk.create(NotificationTypes.Error, 'Fetching stats failed. Kindly Refresh')(dispatch);
            }
        };
    }

    public static getClientBookingStats() {
        return async (dispatch: Dispatch) => {
            try {
                const response = await generateLoader(UsersService.getClientBookingStats(), dispatch, 'Fetching Stats');

                if (response.data) {
                    dispatch(UsersActions.getClientBookingStats(response.data));
                    return;
                }
            } catch (error) {
                NotificationsThunk.create(NotificationTypes.Error, 'Error fetching stats. Kindly Refresh')(dispatch);
            }
        };
    }

    public static getClientStats() {
        return async (dispatch: Dispatch) => {
            try {
                const response = await generateLoader(UsersService.getClientStats(), dispatch, 'Fetching client stats');

                if (response.data) {
                    dispatch(UsersActions.getClientStats(response.data));
                    return;
                }

                throw new Error();
            } catch (error) {
                NotificationsThunk.create(
                    NotificationTypes.Error,
                    'Fetching client stats failed. Kindly Refresh'
                )(dispatch);
            }
        };
    }

    public static getUser(user_id: string | undefined) {
        return async (dispatch: Dispatch<GetUserAction>) => {
            try {
                const response = await generateLoader(
                    UsersService.viewUser(user_id),
                    dispatch,
                    'Fetching user information'
                );

                if (response.ok) {
                    dispatch(UsersActions.getUser(response.data));
                    return;
                }

                NotificationsThunk.create(
                    NotificationTypes.Error,
                    'Fetching user details failed. Kindly Refresh'
                )(dispatch);
            } catch (error) {
                NotificationsThunk.create(NotificationTypes.Error, 'Something went wrong. Kindly Refresh')(dispatch);
            }
        };
    }

    public static getUserBoking(user_id: string | undefined, page?: number) {
        return async (dispatch: Dispatch<GetUserBookings>) => {
            try {
                const response = await generateLoader(
                    UsersService.getUserBooking(user_id, page),
                    dispatch,
                    'Fetching user Bookings'
                );

                if (response.ok) {
                    dispatch(UsersActions.getUserBooking(response));
                    return;
                }

                NotificationsThunk.create(
                    NotificationTypes.Error,
                    'Fetching user bookings failed. Kindly Refresh'
                )(dispatch);
            } catch (error) {
                NotificationsThunk.create(NotificationTypes.Error, 'Something went wrong. Kindly Refresh')(dispatch);
            }
        };
    }

    public static changeUserStatus(userDetails: ChangeUserStatusModel, callback?: () => void) {
        return async (dispatch: Dispatch<UpdateUserAction>) => {
            try {
                const response = await generateLoader(
                    UsersService.changeUserStatus(userDetails),
                    dispatch,
                    'Updating status'
                );

                if (response.ok) {
                    callback?.();

                    NotificationsThunk.create(NotificationTypes.Success, 'Status changed successfully')(dispatch);

                    dispatch(UsersActions.updateUser(response.data));
                    return;
                }

                NotificationsThunk.create(NotificationTypes.Error, 'Updating status failed. Kindly Refresh')(dispatch);
            } catch (error) {
                NotificationsThunk.create(NotificationTypes.Error, 'Something went wrong. Kindly Refresh')(dispatch);
            }
        };
    }

    public static updateClient(clientDetails: UpdateClientModel, type: number, callback?: () => void) {
        return async (dispatch: Dispatch<UpdateClientAction>) => {
            try {
                const response = await generateLoader(
                    UsersService.updateClient(clientDetails, type),
                    dispatch,
                    `Updating client's details`
                );

                if (response.ok) {
                    if (callback) callback();
                    NotificationsThunk.create(NotificationTypes.Success, 'Client updated successfully')(dispatch);

                    dispatch(UsersActions.updateClient(response.data));
                    return;
                }

                NotificationsThunk.create(
                    NotificationTypes.Error,
                    'Updating client status failed. Kindly Refresh'
                )(dispatch);
            } catch (error) {
                NotificationsThunk.create(NotificationTypes.Error, 'Something went wrong. Kindly Refresh')(dispatch);
            }
        };
    }

    public static userActivities(role: string, queryParams?: QueryParams) {
        return async (dispatch: Dispatch<GetUserActivities>) => {
            try {
                const response = await UsersService.userActivities(role, queryParams);

                if (response && response.ok) {
                    dispatch(UsersActions.getUserActivities(response));
                    return;
                }

                NotificationsThunk.create(
                    NotificationTypes.Error,
                    'Fetching user activities failed. Kindly Refresh'
                )(dispatch);
            } catch (error) {
                NotificationsThunk.create(
                    NotificationTypes.Error,
                    'Error fetching activity logs. Kindly Refresh'
                )(dispatch);
            }
        };
    }

    public static updateIndividualClient(clientDetails: UpdateIndividualClientModel, callback?: () => void) {
        return async (dispatch: Dispatch<UpdateClientAction>) => {
            try {
                const response = await generateLoader(
                    UsersService.updateIndividualClient(clientDetails),
                    dispatch,
                    `Updating client's details`
                );

                if (response.ok) {
                    if (callback) callback();
                    NotificationsThunk.create(NotificationTypes.Success, 'Client updated successfully')(dispatch);

                    dispatch(UsersActions.updateClient(response.data));
                    return;
                }

                NotificationsThunk.create(
                    NotificationTypes.Error,
                    'Updating client status failed. Kindly Refresh'
                )(dispatch);
            } catch (error) {
                NotificationsThunk.create(NotificationTypes.Error, 'Something went wrong. Kindly Refresh')(dispatch);
            }
        };
    }

    public static UpdateUserProfile(userDetails: EditUserProfileModel, callback?: () => void) {
        return async (dispatch: Dispatch<UpdateUserAction>) => {
            try {
                const response = await generateLoader(
                    UsersService.updateUserProfile(userDetails),
                    dispatch,
                    'Updating profile'
                );

                if (response && response.ok) {
                    callback?.();

                    NotificationsThunk.create(NotificationTypes.Success, 'Profile updated successfully')(dispatch);

                    dispatch(UsersActions.updateUser(response.data));

                    return;
                }

                NotificationsThunk.create(NotificationTypes.Error, 'Updating profile failed. Kindly Refresh')(dispatch);
            } catch (error) {
                NotificationsThunk.create(NotificationTypes.Error, 'Something went wrong. Kindly Refresh')(dispatch);
            }
        };
    }
}
