import React from 'react';
import './chart.style.scss';

import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';

import { Bar, Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top' as const,
        },
        title: {
            display: false,
            text: '',
        },
    },
};

export const BarChart: React.FC<{ labels: string[]; datas: any; title: string }> = ({ labels, datas, title }) => {
    const data = {
        labels,
        datasets: [
            {
                label: title,
                // barPercentage: 6,
                barThickness: 70,
                // maxBarThickness: 100,
                // minBarLength: 2,
                // data: [1000000, 300000, 40000, 1222222],
                data: datas,
                backgroundColor: '#4DBD7D',
            },
        ],
    };
    return (
        <div className="d-flex justify-content-center">
            <div className="barChart">
                <Bar options={options} data={data} />
            </div>
        </div>
    );
};

export const PieChart: React.FC<{ datas: number[]; labels: string[] }> = ({ labels, datas }) => {
    const data = {
        labels,
        datasets: [
            {
                data: datas,
                backgroundColor: ['#4DBD7D', '#7031AC', '#BD4D8D', '#E4BF58', '#4174C9'],
                borderColor: ['#4DBD7D', '#7031AC', '#BD4D8D', '#E4BF58', '#4174C9'],
                borderWidth: 1,
            },
        ],
    };
    return (
        <div className="d-flex justify-content-center mt-2">
            <div className="pieChart">
                <Pie options={options} data={data} />
            </div>
        </div>
    );
};
