import { Formik } from 'formik';
import React from 'react';
import { Lock, Mail } from 'react-feather';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { EntryFormContainer } from '../../../components';
import { Breadcrumb, Breadcrumbs } from '../../../components/breadcrumbs/breadcrumbs';
import { FormButton, FormTextInput } from '../../../components/form';
import Layout, { StickyBackground } from '../../../components/layout';
import { UserLoginModel } from '../../../models/request';
import { AuthThunk } from '../../../store/auth/auth.thunk';
import { validateFormValues } from '../../../util/validation';

export const LoginPage: React.FC = () => {
    const breadcrumbs: Breadcrumb[] = [
        { name: 'Home', path: '/' },
        { name: 'Login', path: window.location.href },
    ];

    const dispatch = useDispatch();
    const handleLogIn = React.useCallback(
        (login: UserLoginModel) => {
            // dispatch an action to call the api service
            dispatch(AuthThunk.logIn(login));
        },
        [dispatch]
    );

    return (
        <Layout>
            <StickyBackground />
            <div className="px-5 py-3">
                <Breadcrumbs breadcrumbs={breadcrumbs} />
                <EntryFormContainer>
                    <Formik
                        validate={validateFormValues}
                        validateOnChange
                        initialValues={new UserLoginModel()}
                        onSubmit={handleLogIn}
                    >
                        {({ handleSubmit, isValid, submitCount }) => (
                            <form onSubmit={handleSubmit} className="d-flex flex-column align-items-center">
                                <header className="form__header mb-5">
                                    <h1>Login</h1>
                                    {!isValid && submitCount > 0 && (
                                        <h6 className="error mt-2">Please check the form fields for errors</h6>
                                    )}
                                </header>
                                <main className="d-flex flex-column align-items-center mb-5">
                                    <FormTextInput
                                        className="mb-3 wide"
                                        name="Email"
                                        placeholder="EMAIL ADDRESS"
                                        type="email"
                                        icon={Mail}
                                    />
                                    <FormTextInput
                                        className="mb-3 wide"
                                        name="Password"
                                        placeholder="PASSWORD"
                                        type="password"
                                        icon={Lock}
                                        toggleField
                                    />
                                    <Link to="/forget-password">Forgot password</Link>
                                </main>
                                <footer className="d-flex flex-column align-items-center">
                                    <FormButton className="mb-4" type="submit">
                                        <span>Login</span>
                                    </FormButton>
                                </footer>
                            </form>
                        )}
                    </Formik>
                </EntryFormContainer>
            </div>
        </Layout>
    );
};
