import { saveAs } from 'file-saver';
import React from 'react';
import { File, Send } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { TabContent, TabPane } from 'reactstrap';
import { AppLayout, AuthLayout, Breadcrumb, CustomPrimaryButton, TopBar } from '../../../../components';
import { AuthContext } from '../../../../context';
import { Booking } from '../../../../models/response';
import { BookingsService } from '../../../../services';
import { AirportsThunk, ApplicationState, TransactionsThunk } from '../../../../store';
import { BookingActions } from '../../../../store/bookings/bookings.actions';
import { BookingsThunk } from '../../../../store/bookings/bookings.thunk';
import { NotificationsThunk } from '../../../../store/notifications/notifications.thunk';
import { ApiClient } from '../../../../util/api-client';
import { BookingStatuses, BookingStatusesMap, NotificationTypes } from '../../../../util/constants';
import { history } from '../../../../util/history';
import { formatDate } from '../../../../util/misc/formatters';
import { FundsTransactionsTable } from '../../../transaction-services';
import { HeaderStyleWrapper, ViewBookingInformationComponent } from './view-booking-page.style';
import { ViewPorthacourtBookingInformationComponent } from './view-pbooking-page.style';

interface Props {
    bookingId?: string;
    breadcrumbs: Breadcrumb[];
    isFunding?: boolean;
    abortFetchTransaction?: boolean;
}

export const ViewBookingPage: React.FC<Props> = ({
    bookingId,
    isFunding,
    breadcrumbs: crumbs,
    abortFetchTransaction,
}) => {
    const id = bookingId ?? useParams<{ booking_id: string }>().booking_id;
    const { bookingDetails: booking } = useSelector((state: ApplicationState) => state.bookings);
    const {
        openTransaction: transaction,
        transactionType,
        transactionTypeMeta,
    } = useSelector((state: ApplicationState) => state.transactions);
    const { user, isInAirport } = React.useContext(AuthContext.Context);
    const dispatch = useDispatch();

    const exportFile = React.useCallback(
        async (_booking: Booking) => {
            const config: RequestInit = {
                ...ApiClient.getFetchConfig(),
            };

            try {
                dispatch(NotificationsThunk.create(NotificationTypes.Info, `Downloading document`));

                const response = await fetch(
                    `${process.env.REACT_APP_BASE_URL}/booking-services/export/${booking && booking.id}`,
                    config
                );

                try {
                    const downloadedFile = await response.blob();
                    const { applicationUser: _user } = _booking;
                    const documentName = _user?.business_Name ?? `${_user?.firstName} ${user?.lastName}`;
                    saveAs(downloadedFile, documentName + '.pdf');
                } catch (e) {
                    dispatch(NotificationsThunk.create(NotificationTypes.Error, 'Error saving file'));
                }
            } catch (e) {
                dispatch(NotificationsThunk.create(NotificationTypes.Error, 'Error downloading file'));
            }
        },
        [dispatch, booking, user]
    );

    React.useEffect(() => {
        if (id) dispatch(BookingsThunk.retrieve(+id));
        dispatch(AirportsThunk.list());

        if (!abortFetchTransaction) {
            dispatch(TransactionsThunk.retrieveByTrasactionId(+(id as string)));
        }
    }, [dispatch, id, abortFetchTransaction]);

    React.useEffect(() => {
        if (booking && !isInAirport(booking.airportId, 'view') && booking.applicationUserId !== user?.id) {
            dispatch(
                NotificationsThunk.create(NotificationTypes.Warning, 'You are not authorized to view this booking')
            );
            dispatch(BookingActions.clear());
            history.push('/app/transaction-services');
        }
    }, [booking, user, isInAirport, dispatch]);

    const breadcrumbs: Breadcrumb[] = crumbs ?? [
        { name: 'Dashboard', path: '/app/dashboard' },
        { name: 'All Bookings', path: '/app/transaction-services' },
        {
            name: `${(booking && booking.service && booking.service.name) || '[Service name]'}`,
            path: window.location.href,
        },
    ];

    const porthacourtProcess = booking?.service?.portharcourtService;

    return (
        <AuthLayout showSidebar={false}>
            <TopBar breadcrumbs={breadcrumbs} />
            {booking && (
                <React.Fragment>
                    <AppLayout.Wrapper>
                        <AppLayout.Header>
                            <HeaderStyleWrapper>
                                <div className="left__header d-flex align-items-center">
                                    <AppLayout.HeaderBackButton />
                                    <div className="d-flex flex-column">
                                        <h3 className="header__title">{booking.service?.name || '[Service name]'}</h3>
                                        <span className="mb-1">
                                            <strong>Movement No:</strong> {transaction?.id ?? 'N/A'}
                                        </span>
                                    </div>
                                </div>
                                <div className="right text-right">
                                    {transaction &&
                                        ![BookingStatuses.Processing].includes(booking.bookingStatus) &&
                                        (transaction.paymentStatus &&
                                        transaction?.paymentStatus.toLocaleLowerCase() === 'paid' ? (
                                            <CustomPrimaryButton
                                                className="inverted small ml-2"
                                                onClick={() =>
                                                    BookingsService.exportBooking(
                                                        booking.batchCode,
                                                        `${transaction.business_Name} - ${
                                                            transaction.transactType
                                                        } - ${formatDate(transaction.dateCreated)}`,
                                                        dispatch,
                                                        'receipt'
                                                    )
                                                }
                                            >
                                                <File size={10} strokeWidth={3} />
                                                Get Receipt
                                            </CustomPrimaryButton>
                                        ) : (
                                            <CustomPrimaryButton
                                                className="inverted small ml-2"
                                                onClick={() =>
                                                    BookingsService.exportBooking(
                                                        booking.batchCode,
                                                        `${transaction.business_Name} - ${
                                                            transaction.transactType
                                                        } - ${formatDate(transaction.dateCreated)}`,
                                                        dispatch,
                                                        'invoice'
                                                    )
                                                }
                                            >
                                                <File size={10} strokeWidth={3} />
                                                Get Invoice
                                            </CustomPrimaryButton>
                                        ))}
                                    <CustomPrimaryButton
                                        className="inverted small ml-2"
                                        onClick={(e: any) => {
                                            e.preventDefault();
                                            exportFile(booking);
                                        }}
                                    >
                                        <Send size={10} strokeWidth={3} />
                                        <span>Export</span>
                                    </CustomPrimaryButton>
                                </div>
                            </HeaderStyleWrapper>
                        </AppLayout.Header>
                        <AppLayout.Body>
                            <AppLayout.TabHeader>
                                <div className="my-4 d-flex justify-content-between">
                                    <span>Service booking details</span>
                                    <b>{(booking.passengers && booking.passengers.length) || '[x]'} Passenger(s)</b>
                                </div>
                            </AppLayout.TabHeader>
                            <AppLayout.TabBody>
                                <TabContent activeTab={1} className="tab__content">
                                    <TabPane tabId={1}>
                                        {isFunding ? (
                                            <FundsTransactionsTable
                                                transactions={transactionType}
                                                meta={transactionTypeMeta}
                                            />
                                        ) : porthacourtProcess ? (
                                            <ViewPorthacourtBookingInformationComponent
                                                booking={booking}
                                                transaction={transaction}
                                            />
                                        ) : (
                                            <ViewBookingInformationComponent
                                                booking={booking}
                                                transaction={transaction}
                                            />
                                        )}
                                    </TabPane>
                                </TabContent>
                            </AppLayout.TabBody>
                        </AppLayout.Body>
                    </AppLayout.Wrapper>
                </React.Fragment>
            )}
        </AuthLayout>
    );
};
