import { Formik, FormikHelpers } from 'formik';
import { find, indexOf } from 'lodash';
import React, { useState } from 'react';
import { Calendar, Check, Info, Trash2, X } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { Cell } from 'react-table';
import { Col, Row } from 'reactstrap';
import { AppLayout, CustomPrimaryButton, DataTable } from '../../../../../components';
import { FormButton, FormDatePicker, FormTextInput } from '../../../../../components/form';
import { FormCheckBox } from '../../../../../components/form/form-check-box';
import { CustomModal } from '../../../../../components/utilities/modal/modal';
import { CreatePorthacourtBooking } from '../../../../../models/request';
import { Service } from '../../../../../models/response';
import { ApplicationState, ServiceThunk } from '../../../../../store';
import { AirportsThunk } from '../../../../../store/airports/airports.thunk';
import { validateFormValues } from '../../../../../util/validation';
import { formatCurrency } from '../../../../../util/misc';

interface Props {
    service: Service;
    client: string;
    setPassengerBooking: (booking: CreatePorthacourtBooking) => void;
    proceed: () => void;
}

export const CreateBookingFormP: React.FC<Props> = ({ service, setPassengerBooking, proceed, client }) => {
    const [passengerBooking, setPassengerbooking] = React.useState<CreatePorthacourtBooking>(
        new CreatePorthacourtBooking()
    );
    const [passengerBookings, setPassengerBookings] = React.useState<CreatePorthacourtBooking[]>([]);
    const [isTermsOpen, setTermsOpen] = useState(false);
    const isMountedRef = React.useRef(false);
    const [lastPassengerBooking, setLastPassengerBooking] = React.useState<CreatePorthacourtBooking>(
        new CreatePorthacourtBooking()
    );
    const [bookingAdded, setBookingAdded] = React.useState(false);
    const dispatch = useDispatch();
    const { airports } = useSelector((state: ApplicationState) => state.airports);
    const { services: srvice } = useSelector((state: ApplicationState) => state);

    console.log('Service', service);
    const defaultODCValue = service ? service.defaultOdc : 0;
    console.log('ODC value', defaultODCValue);

    React.useEffect(() => {
        isMountedRef.current = true;
        return () => {
            isMountedRef.current = false;
        };
    }, []);

    const verifyBookings = () => {
        setPassengerbooking(passengerBooking);
        proceed();
    };

    React.useEffect(() => {
        dispatch(ServiceThunk.getAllServices());
    }, [dispatch]);

    React.useEffect(() => {
        dispatch(AirportsThunk.list());
    }, [dispatch]);

    if (isTermsOpen) {
        return (
            <CustomModal isOpen={isTermsOpen} title="Booking Terms and Conditions" setIsOpen={setTermsOpen}>
                <div className="py-4">Terms are Unavailable</div>
            </CustomModal>
        );
    }

    const handleAdditionalODCChange = (event: React.ChangeEvent<HTMLInputElement>, setFieldValue: any) => {
        const value = parseInt(event.target.value);
        setFieldValue('additionalOdc', value);
    };

    const addBookingToTable = (booking: CreatePorthacourtBooking) => {
        // Check if the booking already exists in passengerBookings
        const existingBookingIndex = passengerBookings.findIndex(
            (existingBooking) =>
                existingBooking.AirportId === booking.AirportId &&
                // existingBooking.ServiceId === booking.ServiceId &&
                existingBooking.additionalOdc === booking.additionalOdc
        );

        // If the booking already exists, inform the user or handle it as needed
        if (existingBookingIndex !== -1) {
            // Handle the case of a duplicate booking
            console.log('This booking already exists!');
            // Optionally, you can prevent adding the duplicate booking or take other actions
        } else {
            // If the booking doesn't exist, add it to passengerBookings
            setPassengerBookings([...passengerBookings, booking]);
            // Set bookingAdded to true
            setBookingAdded(true);
        }
    };

    const removeBookingFromTable = (bookingToRemove: CreatePorthacourtBooking) => {
        setPassengerBookings(passengerBookings.filter((booking) => booking !== bookingToRemove));
        setBookingAdded(false);
    };

    return (
        <React.Fragment>
            {/* Create Passenger Booking */}
            <AppLayout.Body className="mb-5">
                <AppLayout.TabHeader>
                    <span className="mb-4">Airport Department Service </span>
                    <div className="my-4">
                        <span>Kindly fill out the information for booking service for porthacourt</span>
                    </div>
                </AppLayout.TabHeader>
                <Formik
                    validate={validateFormValues}
                    enableReinitialize
                    initialValues={new CreatePorthacourtBooking()}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        const booking: CreatePorthacourtBooking = {
                            clientId: client,
                            AirportId: values.AirportId,
                            ServiceId: service.id,
                            additionalOdc: values.additionalOdc,
                        };

                        setPassengerBooking(booking);
                        addBookingToTable(booking);
                        resetForm();

                        console.log('Passenger Booking:', booking);

                        setSubmitting(false);
                    }}
                >
                    {({ handleSubmit, values, setFieldValue, handleChange }) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <div className="tab__content__row">
                                    <div className="mb-3">
                                        <b>Booking Details</b>
                                    </div>
                                    <div className="row mb-3">
                                        <Col sm={6} lg={3}>
                                            <FormTextInput name="AirportId" label="Airport" as="select" compact>
                                                <option disabled value="">
                                                    SELECT AN AIRPORT
                                                </option>
                                                {airports.map(({ id, name }) => (
                                                    <option value={id} key={id}>
                                                        {name}
                                                    </option>
                                                ))}
                                            </FormTextInput>
                                        </Col>
                                        {/* <Col sm={6} lg={3}>
                                            <FormDatePicker
                                                name="Date_Of_Travel"
                                                label="Booking date"
                                                icon={Calendar}
                                                minDate={new Date()}
                                                compact
                                            />
                                        </Col> */}
                                    </div>

                                    <div className="mb-3">
                                        <b>ODC's Details</b>
                                    </div>
                                    <div className="row mb-3">
                                        <Col sm={6} lg={3}>
                                            <span style={{ fontSize: 14 }}> Default ODC Number</span>
                                            <FormTextInput
                                                name="defaultODCValue"
                                                compact
                                                value={defaultODCValue || 0}
                                                disabled
                                            />
                                        </Col>
                                        <Col sm={6} lg={3}>
                                            <span style={{ fontSize: 14 }}>Additional ODC(s)</span>
                                            <FormTextInput
                                                name="additionalOdc"
                                                type="number"
                                                value={values.additionalOdc}
                                                compact
                                                handleChange={handleAdditionalODCChange}
                                            />
                                        </Col>
                                    </div>
                                    <div className="d-flex flex-column align-items-end ">
                                        <div className="">
                                            <span className="mr-2">
                                                <b>Sub total</b>
                                            </span>
                                            <span>{formatCurrency(service.price)}</span>
                                        </div>
                                        <div className="mb-2">
                                            <span className="mr-2">
                                                <b>+</b>
                                            </span>
                                            <span>
                                                {formatCurrency(
                                                    (values.additionalOdc ?? 0) > 0
                                                        ? ((values.additionalOdc ?? 0) - 1) * 300000 + 200000
                                                        : 0
                                                )}
                                            </span>
                                        </div>
                                        <div className="mb-5">
                                            <span className="mr-2">
                                                <b>Total</b>
                                            </span>
                                            <span>
                                                {formatCurrency(
                                                    service.price +
                                                        ((values.additionalOdc ?? 0) > 0
                                                            ? ((values.additionalOdc ?? 0) - 1) * 300000 + 200000
                                                            : 0)
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <CustomPrimaryButton type="submit" disabled={bookingAdded}>
                                            <span>Add booking</span>
                                        </CustomPrimaryButton>
                                    </div>
                                </div>
                            </form>
                        );
                    }}
                </Formik>
            </AppLayout.Body>
            {/* Passenger listings */}
            <AppLayout.Body className="mb-5">
                {passengerBookings.length ? (
                    <div className="tab__content__row">
                        <div className="mb-3">
                            <b>Booking added </b>
                        </div>
                        <DataTable
                            columns={[
                                {
                                    Header: 'S/N',
                                    Cell: (cell: Cell<CreatePorthacourtBooking>) => cell.row.index + 1,
                                },
                                // {
                                //     Header: 'Service Type',
                                //     accessor: (row: CreatePorthacourtBooking) => row.Service?.serviceTypeId ?? '',
                                //     Cell: ({ row: { original } }: any) => ClientTypesMap[original.serviceTypeId as ClientTypes],
                                // },

                                {
                                    Header: 'Airport',
                                    accessor: 'AirportId',
                                    Cell: ({ row: { original: booking } }: Cell<CreatePorthacourtBooking>) => (
                                        <span>{find(airports, { id: booking.AirportId })?.name}</span>
                                    ),
                                },

                                // {
                                //     Header: 'Default ODC',
                                //     accessor: (values: CreatePorthacourtBooking) => values.defaultOdc,
                                //     // Render the value within the table cell
                                //     Cell: ({ value }: { value: number }) => <span>{4}</span>,
                                // },
                                {
                                    Header: 'Additional ODC.',
                                    accessor: 'additionalOdc',
                                },
                                {
                                    Header: 'Booking date',
                                    // accessor: 'Date_Of_Travel',
                                    // Cell: ({ row: { original: passenger } }: Cell<CreatePassengerBooking>) =>
                                    //     formatDate(passenger.Date_Of_Travel),
                                },

                                {
                                    Header: 'Action',

                                    // Cell: (cell: Cell<CreatePassengerBooking>) => (
                                    //         <React.Fragment>
                                    //             <CustomPrimaryButton
                                    //                 className="small inverted"
                                    //                 onClick={removePassenger.bind(undefined, cell.row.index)}
                                    //             >
                                    //                 <Trash2 size={14} />
                                    //             </CustomPrimaryButton>
                                    //         </React.Fragment>
                                    //     ),
                                    // },
                                    Cell: (cell: Cell<CreatePorthacourtBooking>) => (
                                        <React.Fragment>
                                            <CustomPrimaryButton
                                                className="small inverted"
                                                onClick={() => removeBookingFromTable(cell.row.original)}
                                            >
                                                <Trash2 size={14} />
                                            </CustomPrimaryButton>
                                        </React.Fragment>
                                    ),
                                },
                            ]}
                            data={passengerBookings}
                        />
                    </div>
                ) : (
                    <div className="tab__content__row">
                        <span>You have not added any booking</span>
                    </div>
                )}
            </AppLayout.Body>
            <Formik
                validate={(values) => {
                    return values.Accepted ? {} : { Accepted: 'You must accept the terms and conditions' };
                }}
                initialValues={{ Accepted: false }}
                onSubmit={verifyBookings}
            >
                {({ handleSubmit, errors }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="d-flex align-items-center gap-2">
                            <FormCheckBox name="Accepted" hideError label={<span>I agree to the </span>} />
                            <button className="text-btn" type="button" onClick={() => setTermsOpen(true)}>
                                terms and conditions
                            </button>
                        </div>
                        {errors && errors.Accepted && <span className="form-error-message">{errors.Accepted}</span>}
                        <FormButton type="submit" className="small mt-5" disabled={!bookingAdded}>
                            <span>Continue</span>
                        </FormButton>
                    </form>
                )}
            </Formik>

            {/* </AppLayout.Body> */}
        </React.Fragment>
    );
};
