import * as queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { TabContent, TabPane } from 'reactstrap';
import { AppLayout, AuthLayout, Breadcrumb, TabLink, TopBar } from '../../../components';
import { AuthenticatedUser } from '../../../models/response';
import { ApplicationState, TransactionsThunk } from '../../../store';
import { BookingsTransactionsTable, FundsTransactionsTable, RegistrationTransactionsTable } from '../components';

export const ClientTransactionServices: React.FC<{
    user: AuthenticatedUser;
}> = ({ user }) => {
    const [dateFilter, setDateFilter] = useState({
        startDate: '06/10/2021',
        endDate: '06/1/2022',
    });
    const [activeTab, setActiveTab] = useState(1);
    const { transactionType, transactionTypeMeta } = useSelector(({ transactions }: ApplicationState) => transactions);
    const dispatch = useDispatch();
    const { search } = useLocation();
    const pageNumber = React.useMemo(() => +(queryString.parse(search) as any).page || 1, [search]);

    const links: TabLink[] = [
        { name: 'Bookings', index: 1, query: ['page', 1] },
        { name: 'Funds', index: 2, query: ['page', 1] },
        { name: 'Registrations', index: 3, query: ['page', 1] },
    ];
    const breadcrumbs: Breadcrumb[] = [
        { name: 'Dashboard', path: '/app/dashboard' },
        { name: 'Transactions', path: '/app/transaction-services' },
    ];

    useEffect(() => {
        const type = activeTab === 1 ? 0 : activeTab === 2 ? 2 : 1;
        dispatch(TransactionsThunk.retrieveByTransactionType(type, { pageNumber, dateFilter }));
    }, [activeTab, dispatch, pageNumber, dateFilter]);

    return (
        <AuthLayout showSidebar={false}>
            <TopBar breadcrumbs={breadcrumbs} />
            <AppLayout.Wrapper>
                <AppLayout.Header>
                    <div className="left__header">
                        <h3 className="header__title">All Transactions</h3>
                    </div>
                </AppLayout.Header>

                <AppLayout.Body>
                    <AppLayout.TabHeader links={links} activeTab={activeTab} setActiveTab={setActiveTab} />

                    <AppLayout.TabBody>
                        <TabContent activeTab={activeTab} className="tab__content">
                            <TabPane tabId={1}>
                                {transactionType.length ? (
                                    <BookingsTransactionsTable
                                        transactions={transactionType}
                                        meta={transactionTypeMeta}
                                    />
                                ) : (
                                    <p>There are no bookings to display</p>
                                )}
                            </TabPane>
                            <TabPane tabId={2}>
                                {transactionType.length ? (
                                    <FundsTransactionsTable transactions={transactionType} meta={transactionTypeMeta} />
                                ) : (
                                    <p>There are no fund transactions to display</p>
                                )}
                            </TabPane>
                            <TabPane tabId={3}>
                                {transactionType.length ? (
                                    <RegistrationTransactionsTable
                                        transactions={transactionType}
                                        meta={transactionTypeMeta}
                                    />
                                ) : (
                                    <p>There are no registration transactions to display</p>
                                )}
                            </TabPane>
                        </TabContent>
                    </AppLayout.TabBody>
                </AppLayout.Body>
            </AppLayout.Wrapper>
        </AuthLayout>
    );
};
