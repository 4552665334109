import { CustomerSurveyModel } from '../models/request';
import { AuthenticatedUser } from '../models/response';
import { ApiClient } from '../util/api-client';

interface Feedback {
    id: number;
    friendlinessRank: number;
    knowledgeRank: number;
    quicknessRank: number;
    isHelpful: any;
    processFollowed: any;
    wouldUseAgain: any;
    wouldRecommendService: any;
    qualityRating: any;
    feedbackText: string;
    dateCreated: string;
    applicationUserId: string;
}

export interface ApplicationUser {
    firstName: string;
    organization_Name: string;
    middleName: string | null;
    lastName: string;
    business_Name: string;
    organization_CategoryId: number;
    organization_Category: null | string;
    business_Nature: string;
    creation_Year: number;
    registered_Address: string;
    registration_Number: string;
    account_Status: boolean;
    gender: string | null;
    bookingServices: string | null;
    feedbacks: Feedback[];
    activation_Codes: string | null;
    meansOfId: string;
    identification: string;
    name_Of_Government_Officials_Office: string | null;
    state: string | null;
    profileStatus: string;
    clientTypeId: number;
    clientType: string | null;
    airportId: string | null;
    airportName?: string;
    dateCreated: string;
    airport: string | null;
    avitechId: number;
    customerAIN: string;
    clientFiles: string | null;
    updatedById: string | null;
    receivedFeedback: string | null;
    date_Of_Feedback: string | null;
    student: string | null;
    name_Of_School: string | null;
    id?: string;
    userName?: string;
    normalizedUserName?: string;
    email?: string;
    normalizedEmail?: string;
    emailConfirmed?: boolean;
    passwordHash?: string;
    securityStamp?: string;
    concurrencyStamp?: string;
    phoneNumber?: string;
    phoneNumberConfirmed?: boolean;
    twoFactorEnabled?: boolean;
    lockoutEnd?: string | null;
    lockoutEnabled?: boolean;
    accessFailedCount?: number;
}

export interface FeedbackData {
    airportName: string;
    id: number;
    friendlinessRank: number;
    knowledgeRank: number;
    quicknessRank: number;
    isHelpful: any;
    processFollowed: any;
    wouldUseAgain: any;
    wouldRecommendService: any;
    qualityRating: any;
    feedbackText: string;
    dateCreated: string;
    applicationUserId: string;
    applicationUser: ApplicationUser;
}

export class FeedbackService {
    public static async sumbitSurvey(survey: CustomerSurveyModel) {
        return ApiClient.post<CustomerSurveyModel, AuthenticatedUser>('feedbacks', survey);
    }

    public static async getFeedbacks() {
        return ApiClient.get<FeedbackData[]>('feedbacks');
    }
}
