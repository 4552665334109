import { Formik } from 'formik';
import { isMatch } from 'lodash';
import React from 'react';
import { Check } from 'react-feather';
import { useDispatch } from 'react-redux';
import { Col, ModalBody, ModalFooter } from 'reactstrap';
import { CustomPrimaryButton } from '../../../../components';
import { FormTextInput } from '../../../../components/form';
import { CustomModal } from '../../../../components/utilities/modal/modal';
import { UpdateClientModel } from '../../../../models/request';
import { User } from '../../../../models/response';
import { NotificationsThunk } from '../../../../store/notifications/notifications.thunk';
import { BusinessNatures, ClientTypesMap, NotificationTypes } from '../../../../util/constants';
import { validateFormValues } from '../../../../util/validation';

interface UpdateClientModalProps {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    client: User;
    handleUpdateClient: (userDetails: UpdateClientModel) => void;
}

export const UpdateClientModal: React.FC<UpdateClientModalProps> = ({
    isOpen,
    setIsOpen,
    client,
    handleUpdateClient,
}) => {
    const dispatch = useDispatch();

    return (
        <CustomModal isOpen={isOpen} setIsOpen={setIsOpen} title="Edit Client">
            <Formik
                validate={validateFormValues}
                enableReinitialize
                initialValues={new UpdateClientModel(client)}
                onSubmit={(clientDetails) => {
                    if (isMatch(new UpdateClientModel(client), clientDetails)) {
                        dispatch(NotificationsThunk.create(NotificationTypes.Info, 'No changes were made'));
                        return setIsOpen(false);
                    }

                    handleUpdateClient(clientDetails);
                }}
            >
                {({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <ModalBody>
                            <div className="form-row">
                                <div className="col-6">
                                    <FormTextInput name="business_Name" label="Business Name" type="text" compact />
                                </div>
                                <div className="col-6">
                                    <FormTextInput
                                        name="Business_Nature"
                                        placeholder="NATURE OF BUSINESS"
                                        type="text"
                                        as="select"
                                        label="Nature of Business"
                                        compact
                                    >
                                        <option value="" disabled>
                                            NATURE OF BUSINESS
                                        </option>
                                        {BusinessNatures.map((value, i) => (
                                            <option value={value} key={i}>
                                                {value}
                                            </option>
                                        ))}
                                    </FormTextInput>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-6">
                                    <FormTextInput
                                        name="registration_Number"
                                        label="Registration Number"
                                        type="text"
                                        compact
                                    />
                                </div>
                                <div className="col-6">
                                    <FormTextInput name="phoneNumber" label="Phone Number" type="text" compact />
                                </div>
                            </div>

                            <div className="form-row">
                                <Col sm={6}>
                                    <FormTextInput
                                        name="registered_Address"
                                        label="Registration Address"
                                        type="text"
                                        compact
                                    />
                                </Col>
                                {client.organization_CategoryId === 2 && (
                                    <Col sm={6}>
                                        <FormTextInput name="clientTypeId" label="Client type" as="select" compact>
                                            <option value="" disabled>
                                                Client Type
                                            </option>
                                            {Object.entries(ClientTypesMap).map(([value, name]) => (
                                                <option value={value} key={value}>
                                                    {name}
                                                </option>
                                            ))}
                                        </FormTextInput>
                                    </Col>
                                )}
                            </div>
                        </ModalBody>
                        <ModalFooter className="d-flex justify-content-between align-items-center">
                            <CustomPrimaryButton type="submit">
                                <Check size={14} strokeWidth={3} />
                                <span>Edit Client</span>
                            </CustomPrimaryButton>
                            <button
                                className="modal-footer__button"
                                type="button"
                                onClick={setIsOpen.bind(undefined, false)}
                            >
                                <span>Close</span>
                            </button>
                        </ModalFooter>
                    </form>
                )}
            </Formik>
        </CustomModal>
    );
};
