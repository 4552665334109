import styled from 'styled-components';

export const AppLayoutStyle = styled.div`
    .tab-header {
        border-bottom: 1px solid #dadada;
        padding: 0rem 2rem 0rem 2rem;
    }

    .tab-nav {
        border: 0;
    }

    @media (max-width: 600px) {
        .tab-nav__item {
            margin-right: 1rem;
            border: 0;
        }
    }

    @media (min-width: 600px) {
        .tab-nav__item {
            margin-right: 3rem;
            border: 0;
        }
    }

    .tab-nav__link {
        font-weight: normal;
        font-size: 14px;
        color: #222222 !important;
        padding: 1.5rem 0px 1.5rem;
        border: 0;
        cursor: pointer;
        width: auto;
        text-align: center;
    }

    .tab-nav__link.active {
        border-bottom: 2px solid #18a956;
        color: #18a956 !important;
    }

    .tab-nav__serachContainer {
        display: flex;
        gap: 1rem;
        align-self: center;
    }

    .tab-nav__serachWrapper {
        border: 2px solid #dadada;
        align-self: center;
        padding: 0.5rem 2rem 0.5rem 0.5rem;
        border-radius: 10px;
        display: flex;
        align-items: center;
    }

    .tab-nav__searchIcon {
        cursor: pointer;
        color: gray;
    }

    .tab-nav__searchInput {
        border: 0;
        outline: none;
        margin-left: 3px;
    }

    .tab-body {
        padding: 1rem 2rem 1rem 2rem;
    }

    .content-body {
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #dadada;
        border-radius: 7px;
        margin-top: 1rem;
        height: 100%;
    }

    .tab__content__row {
        padding: 2rem;
        border-bottom: 1px solid #dadada;

        :last-of-type,
        :last-child {
            border: none;
        }
    }
`;

export const Header = styled.div`
    @media (min-width: 601px) {
        margin-bottom: 2rem;
        align-items: flex-start;
    }

    @media (max-width: 600px) {
    }

    .header__title {
        font-weight: bold;
        font-size: 19px;
        margin: 0;
    }

    .header__back-button {
        height: 35px;
        width: 35px;
        display: flex;
        margin-right: 10px;
        align-items: center;
        justify-content: center;
        background: #f0f0f0;
        color: black;
        border-radius: 100%;
    }
`;
