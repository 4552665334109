import * as queryString from 'query-string';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Col, TabContent, TabPane } from 'reactstrap';
import { AppLayout, AuthLayout, Breadcrumb, TabLink, TopBar } from '../../../../components';
import { ApplicationState } from '../../../../store';
import { UsersThunk } from '../../../../store/users/users.thunk';
import { ActivitiesTable } from './user-activities-table';

export const UserActivitiesPage: React.FC = () => {
    const { search } = useLocation();
    const dispatch = useDispatch();
    const auth = useSelector(({ auth }: ApplicationState) => auth);
    const { userActivitiesMeta, userActivities: activities } = useSelector(({ users }: ApplicationState) => users);
    const breadcrumbs: Breadcrumb[] = [
        { name: 'Dashboard', path: '/app/dashboard' },
        { name: 'All Activities', path: window.location.href },
    ];

    const links: TabLink[] = [{ index: 1, name: 'All Activities' }];
    const [activeTab, setActiveTab] = React.useState(1);

    const role = (auth && auth.authUser?.role) || 'Admin';
    const page = React.useMemo(() => +(queryString.parse(search) as any).page || 1, [search]);

    React.useEffect(() => {
        dispatch(UsersThunk.userActivities(role, { PageNumber: page }));
    }, [dispatch, role, page]);

    return (
        <AuthLayout>
            <TopBar breadcrumbs={breadcrumbs} />
            <AppLayout.Wrapper>
                <AppLayout.Header>
                    <Col lg="8">
                        <h3 className="header__title">All Activities</h3>
                    </Col>
                </AppLayout.Header>
                <AppLayout.Body>
                    <AppLayout.TabHeader links={links} activeTab={activeTab} setActiveTab={setActiveTab} />
                    <AppLayout.TabBody>
                        <TabContent activeTab={activeTab} className="tab__content">
                            <TabPane tabId={1}>
                                {activities && activities.length ? (
                                    <ActivitiesTable activities={activities} meta={userActivitiesMeta} role={role} />
                                ) : (
                                    <p>There are no activities</p>
                                )}
                            </TabPane>
                        </TabContent>
                    </AppLayout.TabBody>
                </AppLayout.Body>
            </AppLayout.Wrapper>
        </AuthLayout>
    );
};
