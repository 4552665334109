import { IsAlphanumeric, IsNotEmpty, IsNumber, Min } from 'class-validator';
import { BaseModel } from '../base-model';

export class FundWalletModel implements BaseModel {
    @IsNumber()
    @Min(50000, { message: 'Amount cannot be lower than 50000' })
    @IsNotEmpty({ message: 'Please enter an amount' })
    public amount = '';

    @IsAlphanumeric()
    public clientId = '';

    public beforeValidation() {
        this.amount = this.amount;
        this.clientId = this.clientId;
    }
}
