import moment from 'moment';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { Link } from 'react-router-dom';
import { Col, Row, TabContent, TabPane } from 'reactstrap';
import styled from 'styled-components';
import {
    AppLayout,
    AuthLayout,
    CustomPrimaryButton,
    FlagTime,
    SignOutSwitchComponent,
    TabLink,
} from '../../../../components';
import { AuthContext } from '../../../../context';
import { EditUserProfileModel, UpdateClientModel, UpdateIndividualClientModel } from '../../../../models/request';
import { AirportsThunk, ApplicationState } from '../../../../store';
// import { AuthThunk } from '../../../../store/auth/auth.thunk';
import { UsersThunk } from '../../../../store/users/users.thunk';
import { ClientTypesMap, Roles } from '../../../../util/constants';
import { UpdateClientModal } from '../../manage-clients/modals/update-client-modal';
import { EditUserProfileModal } from '../modals/edit-profile-modal';
import { HeaderStyleWrapper } from '../../bookings/view-booking/view-booking-page.style';
import { UpdateIndividualClientModal } from '../../manage-clients/modals/update-individual-client-modal';
import { User } from '../../../../models/response';

const StyleWrapper = styled.div`
    .detail-row {
        display: grid;
        grid-template-columns: 200px 1fr;
        padding: 20px 0;
        font-size: 14px;
        border-bottom: 1px solid #eaeaea;
    }

    .detail-row-others {
        display: flex;
        flex-direction: column;
        padding: 20px 0;
        font-size: 14px;
        border-bottom: 1px solid #eaeaea;
    }
`;

interface DetailRow {
    property: string;
    value?: string | number | undefined;
    bold?: boolean;
    link?: string;
    isLogout?: boolean;
}

export const UserProfilePage: React.FC = () => {
    const links: TabLink[] = [
        { index: 1, name: 'My Profile' },
        // { index: 2, name: 'Other' },
    ];
    const [activeTab, setActiveTab] = React.useState(1);
    const [EditModalOpen, setEditModalOpen] = React.useState(false);
    const [ClientModalOpen, setClientModalOpen] = React.useState(false);
    const { canAccess } = React.useContext(AuthContext.Context);

    const { auth, users, airports } = useSelector((state: ApplicationState) => state);
    const userProfile = users.userDetails;
    const business_name = userProfile?.business_Name;
    const full_name = userProfile && `${userProfile.firstName} ${userProfile.lastName}`;
    const role = userProfile?.role;

    const dispatch = useDispatch();

    const fetchUser = React.useCallback(() => {
        dispatch(UsersThunk.getUser(auth.authUser?.id));
    }, [dispatch, auth.authUser]);

    const handleUpdateUserProfile = React.useCallback(
        (userDetails: EditUserProfileModel) => {
            dispatch(
                UsersThunk.UpdateUserProfile(userDetails, () => {
                    setEditModalOpen(false);
                    fetchUser();
                })
            );
        },
        [dispatch, fetchUser]
    );

    const handleUpdateClient = React.useCallback(
        (userDetails: UpdateClientModel) => {
            const type = userProfile!.organization_CategoryId;
            dispatch(
                UsersThunk.updateClient(userDetails, type, () => {
                    setClientModalOpen(false);
                    fetchUser();
                })
            );
        },
        [dispatch, userProfile, fetchUser]
    );

    const handleUpdateIndividualClient = React.useCallback(
        (userDetails: UpdateIndividualClientModel) => {
            dispatch(
                UsersThunk.updateIndividualClient(userDetails, () => {
                    setClientModalOpen(false);
                    fetchUser();
                })
            );
        },
        [dispatch, fetchUser]
    );

    React.useEffect(() => {
        fetchUser();
        dispatch(AirportsThunk.list());
    }, [dispatch, auth, fetchUser]);

    const details: DetailRow[] = (
        role !== Roles.Client
            ? [
                  { property: 'Full Name', value: full_name },
                  { property: 'Airport', value: airports.airports.find((x) => x.id === userProfile?.airportId)?.name },
                  { property: 'Role', value: role },
              ]
            : (userProfile?.firstName
                  ? [{ property: 'Full Name', value: full_name }]
                  : [
                        { property: 'Business Name', value: business_name },
                        { property: 'Nature of Business', value: userProfile?.business_Nature },
                    ]
              ).concat([
                  { property: 'Client Type', value: ClientTypesMap[userProfile!.clientTypeId] },
                  { property: 'Address', value: userProfile?.registered_Address },
              ])
    ).concat([
        { property: 'Email Address', value: userProfile?.email },
        { property: 'Phone Number', value: userProfile?.phoneNumber },
        { property: 'Date Registered', value: moment(userProfile?.dateCreated).format('Do MMMM, YYYY') },
    ]);

    // const others: DetailRow[] = [
    //     // { property: 'Help', link: '/' },
    //     // { property: 'Contact Us', link: '/' },
    //     // { property: 'Privacy Policy', link: '/' },
    //     // { property: 'Terms & Conditions', link: '/' },
    //     { property: 'Logout', isLogout: true },
    // ];

    return (
        <AuthLayout showSidebar={false}>
            <React.Fragment>
                {userProfile && canAccess([Roles.Admin, Roles.AirportManager, Roles.AirportProtocolCoordinator]) && (
                    <EditUserProfileModal
                        editModalOpen={EditModalOpen}
                        setEditModalOpen={setEditModalOpen}
                        handleUpdateUser={handleUpdateUserProfile}
                        activeUser={userProfile}
                    />
                )}

                {userProfile && canAccess(Roles.Client) && !userProfile.firstName ? (
                    <UpdateClientModal
                        isOpen={ClientModalOpen}
                        setIsOpen={setClientModalOpen}
                        client={userProfile}
                        handleUpdateClient={handleUpdateClient}
                    />
                ) : (
                    <UpdateIndividualClientModal
                        isOpen={ClientModalOpen}
                        setIsOpen={setClientModalOpen}
                        client={userProfile as User}
                        handleUpdateClient={handleUpdateIndividualClient}
                    />
                )}

                <Row className="d-flex">
                    <Col lg={3}>
                        <FlagTime />
                    </Col>
                    <Col lg={9} className="d-flex justify-content-end">
                        <SignOutSwitchComponent />
                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col lg={6}>
                        <AppLayout.Wrapper>
                            <AppLayout.Header>
                                <HeaderStyleWrapper>
                                    <div className="left__header">
                                        <h3 className="header__title">My Profile</h3>
                                    </div>
                                    <div className="right d-flex justify-content-end">
                                        {canAccess([
                                            Roles.Admin,
                                            Roles.AirportManager,
                                            Roles.AirportProtocolCoordinator,
                                        ]) && (
                                            <CustomPrimaryButton onClick={setEditModalOpen.bind(undefined, true)}>
                                                <span>Edit Profile</span>
                                            </CustomPrimaryButton>
                                        )}

                                        {role === Roles.Client && (
                                            <CustomPrimaryButton onClick={setClientModalOpen.bind(undefined, true)}>
                                                <span>Edit Profile</span>
                                            </CustomPrimaryButton>
                                        )}
                                    </div>
                                </HeaderStyleWrapper>
                            </AppLayout.Header>
                            <AppLayout.Body>
                                <AppLayout.TabHeader links={links} activeTab={activeTab} setActiveTab={setActiveTab} />
                                <AppLayout.TabBody>
                                    <TabContent activeTab={activeTab} className="tab__content">
                                        <TabPane tabId={1}>
                                            <StyleWrapper>
                                                {details.map(({ property, value }, i) => {
                                                    return (
                                                        <div className="detail-row" key={i}>
                                                            <b>{property}</b>
                                                            <span>{value}</span>
                                                        </div>
                                                    );
                                                })}
                                            </StyleWrapper>
                                        </TabPane>
                                        {/* <TabPane tabId={2}>
                                            <StyleWrapper>
                                                {others.map(({ property, isLogout, link }, i) => {
                                                    const display_link = isLogout ? (
                                                        <Link style={{ color: 'red' }} to="#" onClick={handleLogout}>
                                                            <b>{property}</b>
                                                        </Link>
                                                    ) : (
                                                        <Link style={{ color: '#000' }} to={`${link}`} target="_blank">
                                                            <b>{property}</b>
                                                        </Link>
                                                    );
                                                    return (
                                                        <div className="detail-row-others" key={i}>
                                                            {display_link}
                                                        </div>
                                                    );
                                                })}
                                            </StyleWrapper>
                                        </TabPane> */}
                                    </TabContent>
                                </AppLayout.TabBody>
                            </AppLayout.Body>
                        </AppLayout.Wrapper>
                    </Col>
                </Row>
            </React.Fragment>
        </AuthLayout>
    );
};
