import { capitalize, orderBy } from 'lodash';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { Cell } from 'react-table';
import { CustomPrimaryButton, DataTable } from '../../../../components';
import { Booking } from '../../../../models/response';

interface ClientTableProps {
    bookings: Booking[];
    meta?: any;
}

export const ClientPageTable: React.FC<ClientTableProps> = ({ bookings, meta }) => {
    return (
        <DataTable
            hideHead
            meta={meta}
            columns={[
                {
                    Header: 'Date',
                    accessor: 'dateCreated',
                    Cell: ({ row: { original } }: Cell<Booking>) => {
                        // return <em>{capitalize(moment(original.dateCreated).fromNow())}</em>;
                        return <em>{moment(original.dateCreated).format('Do MMMM, YYYY')}</em>;
                    },
                },
                {
                    Header: 'Service',
                    Cell: ({ row: { original: booking } }: Cell<Booking>) => {
                        return <span>{booking.service.name}</span>;
                    },
                },
                {
                    Header: 'Status',
                    Cell: ({ row: { original: booking } }: Cell<Booking>) => {
                        return <span style={{ color: '#D09611' }}>{booking.bookingStatus}</span>;
                    },
                },
                {
                    Header: 'Actions',
                    Cell: ({ row: { original: booking } }: Cell<Booking>) => {
                        return (
                            <React.Fragment>
                                <CustomPrimaryButton
                                    as={Link}
                                    to={{
                                        pathname: `/app/booking/${booking.id}`,
                                        state: { intent: 'bookings' },
                                    }}
                                    className="small inverted mr-2"
                                >
                                    <span>View Booking</span>
                                </CustomPrimaryButton>
                            </React.Fragment>
                        );
                    },
                },
            ]}
            data={orderBy(bookings, (_) => new Date(_.dateCreated), 'desc')}
        />
    );
};
