import { CreatePorthacourtBooking } from '../../models/request/bookings/create-porthacourt-booking.model';
import { generateLoader } from '../../util/misc';
import { NotificationsThunk } from '../notifications/notifications.thunk';
import { NotificationTypes } from '../../util/constants';
import { BookingsService } from '../../services';
import { Dispatch } from 'redux';
import { store } from '../..';
import { AddPBookingAction } from './pbooking.types';
import { history } from '../../util/history';

export class PBookingsThunk {
    public static createporthacourtbooking(details: CreatePorthacourtBooking, client: string, closeModal?: () => void) {
        return async (dispatch: Dispatch<AddPBookingAction>) => {
            return new Promise(async (resolve, reject) => {
                try {
                    const newDetails = { ...details, clientId: client };

                    const response = await generateLoader(
                        BookingsService.createporthacourtbooking(newDetails),
                        dispatch,
                        'Creating porthacourt booking'
                    );

                    if (response.data) {
                        if (closeModal) closeModal();
                        NotificationsThunk.create(
                            NotificationTypes.Success,
                            'Porthacourt Booking made successfully'
                        )(dispatch);
                        history.replace('/app/bookings');
                        return resolve(response.data);
                    } else {
                        // Handle case where response data is empty
                        const errorMessage = response.message || 'Unknown error';
                        throw new Error(errorMessage);
                    }
                } catch (error) {
                    console.error('Error creating porthacourt booking:', error);
                    NotificationsThunk.create(NotificationTypes.Error, 'Porthacourt Booking creation failed')(dispatch);
                    reject(error); // Reject the promise with the error
                }
            });
        };
    }
}
