import { Formik } from 'formik';
import { isMatch } from 'lodash';
import React from 'react';
import { Briefcase, Book, Check } from 'react-feather';
import { useDispatch } from 'react-redux';
import { ModalBody, ModalFooter } from 'reactstrap';
import { CustomPrimaryButton } from '../../../../components';
import { FormTextInput } from '../../../../components/form';
import { CustomModal } from '../../../../components/utilities/modal/modal';
import { UpdateIndividualClientModel } from '../../../../models/request';
import { User } from '../../../../models/response';
import { NotificationsThunk } from '../../../../store/notifications/notifications.thunk';
import { MeansOfIdList, NotificationTypes } from '../../../../util/constants';
import { validateFormValues } from '../../../../util/validation';

interface Props {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    client: User;
    handleUpdateClient: (userDetails: UpdateIndividualClientModel) => void;
}

export const UpdateIndividualClientModal: React.FC<Props> = ({ isOpen, setIsOpen, client, handleUpdateClient }) => {
    const dispatch = useDispatch();

    return (
        <CustomModal isOpen={isOpen} setIsOpen={setIsOpen} title="Edit Client">
            <Formik
                validate={validateFormValues}
                enableReinitialize
                initialValues={new UpdateIndividualClientModel(client)}
                onSubmit={(clientDetails) => {
                    if (isMatch(new UpdateIndividualClientModel(client), clientDetails)) {
                        dispatch(NotificationsThunk.create(NotificationTypes.Info, 'No changes were made'));
                        return setIsOpen(false);
                    }

                    handleUpdateClient(clientDetails);
                }}
            >
                {({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <ModalBody>
                            <div className="form-row">
                                <div className="col-6">
                                    <FormTextInput disabled name="firstName" label="First Name" type="text" compact />
                                </div>
                                <div className="col-6">
                                    <FormTextInput disabled name="lastName" label="Last Name" type="text" compact />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-6">
                                    <FormTextInput name="phoneNumber" label="Phone Number" type="text" compact />
                                </div>
                                <div className="col-6">
                                    <FormTextInput
                                        name="meansOfId"
                                        placeholder="MEANS OF ID"
                                        label="Means of ID"
                                        type="text"
                                        as="select"
                                        compact
                                        icon={Briefcase}
                                    >
                                        <option value={0} disabled>
                                            MEANS OF ID
                                        </option>
                                        {MeansOfIdList.map((value, i) => (
                                            <option value={value} key={i}>
                                                {value}
                                            </option>
                                        ))}
                                    </FormTextInput>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-6">
                                    <FormTextInput
                                        name="identification"
                                        label="Identification number"
                                        placeholder="IDENTIFICATION NUMBER"
                                        type="text"
                                        icon={Book}
                                        compact
                                    />
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter className="d-flex justify-content-between align-items-center">
                            <CustomPrimaryButton type="submit">
                                <Check size={14} strokeWidth={3} />
                                <span>Edit Client</span>
                            </CustomPrimaryButton>
                            <button
                                className="modal-footer__button"
                                type="button"
                                onClick={setIsOpen.bind(undefined, false)}
                            >
                                <span>Close</span>
                            </button>
                        </ModalFooter>
                    </form>
                )}
            </Formik>
        </CustomModal>
    );
};
