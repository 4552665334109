import { VCard } from '../../models/response';
import { CreateVcard, GetAirpotVcard, GetAllVcards, VcardActionNames } from './vcard.types';

export class VcardActions {
    public static get(vcards: VCard[]): GetAllVcards {
        return {
            type: VcardActionNames.GET_ALL,
            payload: { vcards },
        };
    }

    public static getOne(vcards: VCard[]): GetAirpotVcard {
        return {
            type: VcardActionNames.GET_ONE,
            payload: { vcards },
        };
    }

    public static add(vcards?: VCard): CreateVcard {
        return {
            type: VcardActionNames.ADD,
            payload: vcards as any,
        };
    }
}
