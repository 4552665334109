import React, { useEffect } from 'react';
import { TopBar } from '../../components';
import { Breadcrumb } from '../../components';
import { AuthLayout } from '../../components';
import { Card, CardBody, TabContent, Table, TabPane } from 'reactstrap';
import { AppLayout } from '../../components';
import { VscFeedback } from 'react-icons/vsc';
import { FeedbackModal } from './feedback-modal';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FeedbackThunk } from '../../store/feedback';
import { ApplicationState } from '../../store';
import { formatDate } from '../../util/misc/formatters';
export const ClientFeedback: React.FC = () => {
    const breadcrumbs: Breadcrumb[] = [
        { name: 'Dashboard', path: '/app/dashboard' },
        { name: 'Client-Feedback', path: window.location.href },
    ];

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(FeedbackThunk.getFeedbacks());
    }, [dispatch]);

    const { feedbacks } = useSelector((state: ApplicationState) => state.feedbacks);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedFeedback, setSelectedFeedback] = useState<number | null>(null);

    const handleViewFeedback = (feedbackId: number) => {
        setSelectedFeedback(feedbackId);
        setIsModalOpen(true);
    };

    const poor = feedbacks.filter((feedback) => feedback.qualityRating === 'Poor').length;
    const fair = feedbacks.filter((feedback) => feedback.qualityRating === 'Fair').length;
    const good = feedbacks.filter((feedback) => feedback.qualityRating === 'Good').length;
    const veryGood = feedbacks.filter((feedback) => feedback.qualityRating === 'Very_Good').length;
    const excellent = feedbacks.filter((feedback) => feedback.qualityRating === 'Excellent').length;

    return (
        <AuthLayout showSidebar={false}>
            <TopBar breadcrumbs={breadcrumbs} />
            <AppLayout.Wrapper>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '65vw',
                        flexWrap: 'wrap',
                    }}
                >
                    <span style={{ fontSize: '20px' }}>Feedback</span>
                    {['Poor', 'Fair', 'Good', 'Very Good', 'Excellent'].map((item) => (
                        <Card
                            style={{
                                width: '150px',
                                border: '1px solid #D9D9D9',
                                height: '62px',
                                borderRadius: '5px',
                                display: 'flex',
                                justifyContent: 'center',
                                padding: '5px 5px',
                            }}
                        >
                            <CardBody className="d-flex justify-content-between p-2">
                                <div style={{ fontSize: '14px' }}>
                                    <VscFeedback
                                        style={{
                                            fontSize: '20px',
                                            color:
                                                item === 'Poor' || item === 'Fair'
                                                    ? '#FF0000'
                                                    : item === 'Good'
                                                    ? '#FFF735'
                                                    : '#18A956',
                                        }}
                                    />
                                    <span className="ml-2">{item}</span>
                                </div>
                                <div style={{ fontSize: '14px' }}>
                                    {item === 'Poor'
                                        ? poor
                                        : item === 'Fair'
                                        ? fair
                                        : item === 'Good'
                                        ? good
                                        : item === 'Very Good'
                                        ? veryGood
                                        : excellent}
                                </div>
                            </CardBody>
                        </Card>
                    ))}
                </div>
                <AppLayout.Body>
                    <Table borderless style={{ color: 'black', fontSize: '14px', fontWeight: '400' }}>
                        <thead>
                            <tr style={{ borderTop: '0', borderBottom: '1px solid #DADADA' }}>
                                <td style={{ fontSize: '14px' }}>Client's feedback</td>
                            </tr>
                        </thead>
                        <tbody style={{ paddingLeft: '10px !important' }}>
                            <tr style={{ borderTop: '0', borderBottom: '1px solid #DADADA' }}>
                                <th>Date</th>
                                <th>Client Name</th>
                                <th>Airport</th>
                                <th>Feedback</th>
                                <th>Rating</th>
                                <th>Action</th>
                            </tr>
                            {feedbacks
                                .slice()
                                .reverse()
                                .map((feedback) => (
                                    <tr key={feedback.id}>
                                        <td style={{ fontWeight: 'bold' }}>
                                            <em>{formatDate(feedback.dateCreated)}</em>
                                        </td>
                                        <td>
                                            {feedback.applicationUser.firstName} {feedback.applicationUser.lastName}
                                        </td>
                                        <td>{feedback.airportName !== '' ? feedback.airportName : '---'}</td>
                                        <td>
                                            {feedback.qualityRating === 'Poor' || feedback.qualityRating === 'Fair'
                                                ? 'Negative Feedack'
                                                : 'Positive Feedback'}
                                        </td>
                                        <td>
                                            {feedback.qualityRating === 'Poor'
                                                ? 'Poor'
                                                : feedback.qualityRating === 'Fair'
                                                ? 'Fair'
                                                : feedback.qualityRating === 'Good'
                                                ? 'Good'
                                                : feedback.qualityRating === 'Very_Good'
                                                ? 'Very Good'
                                                : feedback.qualityRating === 'Excellent'
                                                ? 'Excellent'
                                                : '---'}
                                        </td>
                                        <td>
                                            <button
                                                style={{
                                                    padding: '4px 8px',
                                                    border: '1px solid #18A956',
                                                    color: '#18A956',
                                                    borderRadius: '6px',
                                                    backgroundColor: 'transparent',
                                                }}
                                                onClick={() => handleViewFeedback(feedback.id)}
                                            >
                                                View Feedback
                                            </button>
                                        </td>
                                    </tr>
                                ))}

                            {isModalOpen && (
                                <FeedbackModal
                                    isOpen={isModalOpen}
                                    toggle={() => setIsModalOpen(false)}
                                    helpful={
                                        feedbacks.find((f) => f.id === selectedFeedback)?.isHelpful === 'Yes'
                                            ? 'yes'
                                            : feedbacks.find((f) => f.id === selectedFeedback)?.isHelpful === 'No'
                                            ? 'no'
                                            : '---'
                                    }
                                    process={
                                        feedbacks.find((f) => f.id === selectedFeedback)?.processFollowed === 'Yes'
                                            ? 'yes'
                                            : 'no'
                                    }
                                    useAgain={
                                        feedbacks.find((f) => f.id === selectedFeedback)?.wouldUseAgain === 'Yes'
                                            ? 'yes'
                                            : 'no'
                                    }
                                    rateQuality={
                                        feedbacks.find((f) => f.id === selectedFeedback)?.qualityRating === 'Poor'
                                            ? 'poor'
                                            : feedbacks.find((f) => f.id === selectedFeedback)?.qualityRating === 'Fair'
                                            ? 'fair'
                                            : feedbacks.find((f) => f.id === selectedFeedback)?.qualityRating === 'Good'
                                            ? 'good'
                                            : feedbacks.find((f) => f.id === selectedFeedback)?.qualityRating ===
                                              'Very_Good'
                                            ? 'very good'
                                            : feedbacks.find((f) => f.id === selectedFeedback)?.qualityRating ===
                                              'Excellent'
                                            ? 'excellent'
                                            : '---'
                                    }
                                    feedbackText={feedbacks.find((f) => f.id === selectedFeedback)?.feedbackText}
                                    recoommendService={
                                        feedbacks.find((f) => f.id === selectedFeedback)?.wouldRecommendService ===
                                        'Yes'
                                            ? 'yes'
                                            : 'no'
                                    }
                                />
                            )}
                        </tbody>
                    </Table>
                </AppLayout.Body>
            </AppLayout.Wrapper>
        </AuthLayout>
    );
};
