import { Dispatch } from 'redux';
import { ReduxAction } from '../common/redux-action';
import { ReportingAction, ReportingActionNames, ReportingIntervalAction, ReportingState } from './reporting.types';
import { ReportingService } from '../../services';
import { generateLoader } from '../../util/misc';
import { Reporting } from '../../models/response';

const initialState: ReportingState = {
    reportings: {} as any,
    reportingsIntervals: {} as any,
};

export class ReportinThunk {
    public static getReportings(dateFilter?: { startDate: string; endDate: string }) {
        return async (dispatch: Dispatch) => {
            try {
                const response = await generateLoader(ReportingService.getReporting(dateFilter), dispatch);

                if (response.data) {
                    dispatch(ReportingActions.getReporting(response.data));
                    return;
                }

                throw new Error('Get failed');
            } catch (error) {
                // TODO: dispatch error to errorReducer
                // to be implemented
            }
        };
    }

    public static getReportingsInterval(interval: { startDate: string; endDate: string }) {
        return async (dispatch: Dispatch) => {
            try {
                const response = await generateLoader(ReportingService.getReportinginterval(interval), dispatch);

                if (response.data) {
                    dispatch(ReportingActions.getReportingInterval(response.data));
                    return;
                }

                throw new Error('Get failed');
            } catch (error) {
                // TODO: dispatch error to errorReducer
                // to be implemented
            }
        };
    }
}

export class ReportingActions {
    public static getReporting(reportings: Reporting): ReportingAction {
        return {
            type: ReportingActionNames.REPRTING_GET,
            payload: { reportings },
        };
    }

    public static getReportingInterval(reportingsIntervals: any): ReportingIntervalAction {
        return {
            type: ReportingActionNames.REPRTING_GET_INTERVAL,
            payload: { reportingsIntervals },
        };
    }
}

export const reportingReducer = (state = initialState, action: ReduxAction<ReportingActionNames>) => {
    switch (action.type) {
        case ReportingActionNames.REPRTING_GET: {
            return { ...action.payload };
        }

        case ReportingActionNames.REPRTING_GET_INTERVAL: {
            return { ...state, ...action.payload };
        }

        default: {
            return state;
        }
    }
};
