import React from 'react';
import { ModalBody } from 'reactstrap';
import styled from 'styled-components';

export const Document = styled.iframe`
    width: 100%;
    height: 100%;
    overflow: auto;
    border-radius: 9px;
    border: none;
`;

interface Props {
    file?: any;
}

export const File = ({ file }: Props) => {
    return (
        <ModalBody>
            <Document src={file}></Document>
        </ModalBody>
    );
};
