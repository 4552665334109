import { capitalize } from 'lodash';
import moment from 'moment';
import React, { useMemo } from 'react';
import { Clipboard, Grid, Users } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Cell } from 'react-table';
import styled from 'styled-components';
import { AuthLayout, DataTable, FlagTime, SignOutSwitchComponent } from '../../../components';
import { AuthenticatedUser, Booking, User } from '../../../models/response';
import { ApplicationState } from '../../../store';
import { BookingsThunk } from '../../../store/bookings/bookings.thunk';
import { UsersThunk } from '../../../store/users/users.thunk';
import { ClientStatuses, Roles } from '../../../util/constants';
import { DashboardPageStyleWrapper } from '../components';

export const AdminDashbboardStyle = styled(DashboardPageStyleWrapper as any)`
    /* .icon-row__col {
        flex: 1;
        margin-right: 10px;
        cursor: pointer;
    }

    .icon-row__item {
        padding: 15px 0px;
        border: 1px solid #dadada;
        border-radius: 7px;
        display: flex;
        flex-direction: row;

        .col-md-8 {
            span {
                padding: 7px auto;
                font-weight: bold;
                font-size: 14px;
            }
        }

        .col-md-4 {
            span {
                font-weight: bold;
                font-size: 20px;
            }
        }
    }
    */

    .deck-container__deck {
        border: 1px solid #dadada;
        border-radius: 7px;
        flex: 1;
        margin-right: 10px;

        a {
            color: var(--primary-color);
        }

        p {
            font-size: 13px;
            font-weight: bold;

            a {
                color: var(--primary-color);
                font-size: 13px;
            }
        }
    }
    /*
    @media only screen and (max-width: 970px) {
        .icon-row {
            padding: 0 30px;
        }
    } */
`;

interface TopCardLink {
    name: string;
    icon: React.FC<any>;
    count: number;
    color?: string;
}

export const AdminDashboardPage: React.FC = () => {
    const { users, bookings, auth } = useSelector((state: ApplicationState) => state);
    const clients = users.clients.sort((a, b) => Date.parse(b.dateCreated) - Date.parse(a.dateCreated));
    const user = auth.authUser as AuthenticatedUser;
    const role = auth.authUser?.role;
    const dashboardStats = users.dashboardStats;

    const filteredBookings = React.useMemo(() => {
        switch (user?.role) {
            case Roles.Admin:
            case Roles.AirportManager:
                return bookings.bookings.slice(0, 6);
            default:
                return [];
        }
    }, [bookings.bookings, user]);

    const shared_links = useMemo(
        () => [
            { name: 'Clients', icon: Users, count: users.clientStats?.total ?? 0, color: '#18A956' },
            {
                name: 'Active Clients',
                icon: Users,
                count: users.clientStats?.active ?? 0,
                color: '#B1B71C',
            },
        ],
        [users.clientStats]
    );

    const admin_links = [
        ...shared_links,
        { name: 'Services', icon: Grid, count: dashboardStats?.services ?? 'N/A', color: '#18A956' },
        {
            name: `Today's Bookings`,
            icon: Clipboard,
            count: dashboardStats?.todaybookings ?? 'N/A',
            color: '#ED0000',
        },
    ];

    const manager_links = useMemo(
        () => [
            ...shared_links,
            {
                name: 'Processing',
                icon: Users,
                count: users.clientStats?.new ?? 0,
                color: '#18A956',
            },
            {
                name: 'Declined',
                icon: Users,
                count: users.clientStats?.inActive ?? 0,
                color: '#18A956',
            },
        ],
        [users.clientStats, shared_links]
    );

    const cards: TopCardLink[] =
        role === Roles.Admin ? admin_links : role === Roles.AirportManager ? manager_links : [];

    const dispatch = useDispatch();
    React.useEffect(() => {
        dispatch(BookingsThunk.list());
        dispatch(UsersThunk.getClients());
        dispatch(UsersThunk.getClientStats());
        dispatch(UsersThunk.getDashboardStats());
    }, [dispatch]);

    return (
        <AuthLayout showSidebar={false}>
            <AdminDashbboardStyle>
                <div className="d-flex flex-row top__content">
                    <div className="flag">
                        <FlagTime />
                    </div>
                    <div className="switch d-flex justify-content-end">
                        <SignOutSwitchComponent />
                    </div>
                </div>
                <div className="mt-3 icon-row row">
                    {cards?.map(({ name, icon: Icon, count, color }, i) => (
                        <div className="icon-row__col mb-3 col" key={i}>
                            <div className="icon-row__item row">
                                <div className="d-flex justify-content-start col-md-8 pt-1">
                                    <Icon size={20} className="mr-2" style={{ color }} />
                                    <span className="oneline__text">{name}</span>
                                </div>
                                <div className="d-flex flex-row-reverse text-right col-md-4">
                                    <span>{count}</span>
                                </div>
                            </div>
                        </div>
                    ))}
                    <div className="w-100"></div>
                    <div className="col deck-container__deck p-3 mb-4">
                        <div className="row">
                            <div className="col">
                                <p>Latest Clients</p>
                            </div>
                            <div className="col d-flex flex-row-reverse">
                                <p>
                                    <Link to="/app/clients">
                                        View all <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                                    </Link>
                                </p>
                            </div>
                        </div>
                        <div>
                            <DataTable
                                hideHead
                                columns={[
                                    {
                                        accessor: 'dateCreated',
                                        Cell: ({ row: { original } }: Cell<User>) => {
                                            return <b>{capitalize(moment(original.dateCreated).fromNow())}</b>;
                                        },
                                    },
                                    {
                                        accessor: 'business_Name',
                                        Cell: ({ row: { original } }: Cell<User>) => {
                                            return (
                                                <b>
                                                    {original.business_Name ??
                                                        `${original.firstName} ${original.lastName}`}
                                                </b>
                                            );
                                        },
                                    },
                                    {
                                        Header: 'Actions',
                                        Cell: ({ row: { original: user } }: Cell<User>) => {
                                            return <span style={{ color: '#D09611' }}>{user.profile_Status}</span>;
                                        },
                                    },
                                    {
                                        accessor: 'profile_Status',
                                        Cell: ({ row: { original: client } }: Cell<User>) => {
                                            return (
                                                <Link
                                                    to={`${
                                                        [ClientStatuses.New, ClientStatuses.Processing].includes(
                                                            client.profile_Status
                                                        )
                                                            ? `/app/client/approval/${client.id}`
                                                            : `/app/client/${client.id}`
                                                    }`}
                                                    style={{
                                                        color: 'var(--primary-color)',
                                                    }}
                                                >
                                                    <span>
                                                        {[ClientStatuses.New, ClientStatuses.Processing].includes(
                                                            client.profile_Status
                                                        )
                                                            ? 'View Request'
                                                            : 'View Client'}
                                                    </span>
                                                </Link>
                                            );
                                        },
                                    },
                                ]}
                                data={clients.slice(0, 6)}
                            />
                        </div>

                        <p className="text-center">
                            <Link to="/app/clients" style={{ color: '#000' }}>
                                See all <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                            </Link>
                        </p>
                    </div>
                    <div className="col deck-container__deck p-3">
                        <div className="row">
                            <div className="col">
                                <p>Latest Bookings</p>
                            </div>
                            <div className="col d-flex flex-row-reverse">
                                <p>
                                    <Link to="/app/bookings">
                                        View all <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                                    </Link>
                                </p>
                            </div>
                        </div>
                        <div>
                            <DataTable
                                hideHead
                                columns={[
                                    {
                                        accessor: 'dateCreated',
                                        Cell: ({ row: { original } }: Cell<Booking>) => {
                                            return <b>{capitalize(moment(original.dateCreated).fromNow())}</b>;
                                        },
                                    },
                                    {
                                        Header: 'Client',
                                        Cell: (cell: Cell<Booking>) => {
                                            const user = cell.row.original.applicationUser;
                                            return user ? (
                                                <span>
                                                    {user.business_Name || `${user.firstName} ${user.lastName}`}
                                                </span>
                                            ) : null;
                                        },
                                    },
                                    {
                                        Header: 'Status',
                                        Cell: ({ row: { original: booking } }: Cell<Booking>) => {
                                            return (
                                                <span style={{ color: '#D09611' }}>
                                                    {booking.bookingStatus.replace('_', ' ')}
                                                </span>
                                            );
                                        },
                                    },
                                    {
                                        Header: 'Action',
                                        Cell: ({ row: { original: booking } }: Cell<Booking>) => {
                                            return (
                                                <Link
                                                    style={{ color: 'var(--primary-color)' }}
                                                    to={`/app/booking/${booking.id}`}
                                                >
                                                    View
                                                </Link>
                                            );
                                        },
                                    },
                                ]}
                                data={filteredBookings}
                            />
                            <p className="text-center">
                                <Link to="/app/bookings" style={{ color: '#000' }}>
                                    See all <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                                </Link>
                            </p>
                        </div>
                    </div>
                </div>
            </AdminDashbboardStyle>
        </AuthLayout>
    );
};
