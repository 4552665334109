import { saveAs } from 'file-saver';
import { Field, Formik } from 'formik';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useCallback } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { TabContent, TabPane } from 'reactstrap';
import styled from 'styled-components';
import { AppLayout, AuthLayout, TabLink, TopBar } from '../../components';
import { Button } from '../../components/form';
import { Booking, Transaction } from '../../models/response';
import { ApplicationState, NotificationsThunk, TransactionsThunk } from '../../store';
import { setSearch } from '../../util/history';
import { TransactionServicesTable } from './components';
import { ApiClient } from '../../util/api-client';
import { NotificationTypes } from '../../util/constants';

const HeaderStyleWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > form {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        gap: 20px;
        padding: 0;

        label {
            display: flex;
            font-weight: 500;
            flex-direction: column;
        }
    }
`;

export const OtherUsersTransactionServices: React.FC = () => {
    const dispatch = useDispatch();
    const [value, setValue] = useState('');
    const [dateFilter, setDateFilter] = useState({
        startDate: '',
        endDate: '',
    });
    const [activeTab, setActiveTab] = React.useState(1);
    const [searchTerm, setSearchTerm] = React.useState('');
    const { transactionType, transactionTypeMeta } = useSelector((state: ApplicationState) => state.transactions);
    const { search } = useLocation();
    const pageNumber = React.useMemo(() => +(queryString.parse(search) as any).page || 1, [search]);

    const links: TabLink[] = [
        { name: 'Booking Services', index: 1, query: ['page', 1] },
        { name: 'Funds Services', index: 3, query: ['page', 1] },
        { name: 'Registration Services', index: 2, query: ['page', 1] },
    ];

    useEffect(() => {
        dispatch(TransactionsThunk.retrieveByTransactionType(activeTab - 1, { pageNumber, ...dateFilter }, searchTerm));
    }, [dispatch, activeTab, pageNumber, dateFilter]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };

    const handleSearch = () => {
        dispatch(TransactionsThunk.retrieveByTransactionType(activeTab - 1, { pageNumber, ...dateFilter }, searchTerm));
    };

    const filterdTransactionType: Transaction[] = transactionType.filter((transaction: Transaction) => {
        return transaction.customerName && transaction.customerName.toLowerCase().indexOf(value.toLowerCase()) !== -1;
    });

    const exportBookingFile = async () => {
        const config: RequestInit = {
            ...ApiClient.getFetchConfig(),
        };

        try {
            dispatch(NotificationsThunk.create(NotificationTypes.Info, `Downloading document`));

            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/transactions/export-booking-transactions`,
                config
            );

            try {
                const downloadedFile = await response.blob();
                const documentName = 'Booking transactions';
                saveAs(downloadedFile, documentName + '.csv');
            } catch (e) {
                dispatch(NotificationsThunk.create(NotificationTypes.Error, 'Error saving file'));
            }
        } catch (e) {
            dispatch(NotificationsThunk.create(NotificationTypes.Error, 'Error downloading file'));
        }
    };

    const exportFundsFile = async () => {
        const config: RequestInit = {
            ...ApiClient.getFetchConfig(),
        };

        try {
            dispatch(NotificationsThunk.create(NotificationTypes.Info, `Downloading document`));

            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/transactions/export-funds-transactions`,
                config
            );

            try {
                const downloadedFile = await response.blob();
                const documentName = 'Fund transactions';
                saveAs(downloadedFile, documentName + '.csv');
            } catch (e) {
                dispatch(NotificationsThunk.create(NotificationTypes.Error, 'Error saving file'));
            }
        } catch (e) {
            dispatch(NotificationsThunk.create(NotificationTypes.Error, 'Error downloading file'));
        }
    };

    const exportRegFile = async () => {
        const config: RequestInit = {
            ...ApiClient.getFetchConfig(),
        };

        try {
            dispatch(NotificationsThunk.create(NotificationTypes.Info, `Downloading document`));

            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/transactions/export-registration-transactions`,
                config
            );

            try {
                const downloadedFile = await response.blob();
                const documentName = 'Registrations transactions';
                saveAs(downloadedFile, documentName + '.csv');
            } catch (e) {
                dispatch(NotificationsThunk.create(NotificationTypes.Error, 'Error saving file'));
            }
        } catch (e) {
            dispatch(NotificationsThunk.create(NotificationTypes.Error, 'Error downloading file'));
        }
    };

    const refreshFundsServices = () => {
        dispatch(TransactionsThunk.retrieveByTransactionType(2, { pageNumber, ...dateFilter }, searchTerm));
        console.log('Refreshing Funding tab');
    };

    // console.log("ac", activeTab)

    const handleExport = () => {
        switch (activeTab) {
            case 1:
                exportBookingFile();
                break;
            case 2:
                exportRegFile();
                break;
            case 3:
                exportFundsFile();
                break;
            default:
                break;
        }
    };

    return (
        <AuthLayout showSidebar={false}>
            <TopBar breadcrumbs={[]} />
            <AppLayout.Wrapper>
                <AppLayout.Header>
                    <HeaderStyleWrapper>
                        All Transaction
                        <Formik
                            initialValues={{ startDate: '', endDate: '' }}
                            onSubmit={async (values) => {
                                ReactDOM.unstable_batchedUpdates(() => {
                                    setSearch('page', 1);
                                    setDateFilter({ startDate: values.startDate, endDate: values.endDate });
                                });
                            }}
                        >
                            {({ handleSubmit, values }) => {
                                return (
                                    <form onSubmit={handleSubmit}>
                                        <h4>Filter By:</h4>
                                        <label className="label">
                                            Start Date:
                                            <Field type="date" name="startDate" />
                                        </label>
                                        <label className="label">
                                            End Date:
                                            <Field type="date" name="endDate" />
                                        </label>
                                        <Button
                                            style={{
                                                border: '1px solid currentColor',
                                                borderRadius: '5px',
                                                marginBottom: 8,
                                            }}
                                            disabled={!values.endDate.length || !values.startDate.length}
                                            type="submit"
                                        >
                                            Filter
                                        </Button>
                                    </form>
                                );
                            }}
                        </Formik>
                    </HeaderStyleWrapper>
                </AppLayout.Header>
                <AppLayout.Body>
                    <AppLayout.TabHeader
                        links={links}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        handleChange={handleChange}
                        handleSearch={handleSearch}
                        value={searchTerm}
                        handleExport={handleExport}
                        searchable
                        isSearchNotOnChange
                    />

                    <AppLayout.TabBody>
                        <TabContent activeTab={activeTab} className="tab__content">
                            <TabPane tabId={1}>
                                {filterdTransactionType.length > 0 ? (
                                    <TransactionServicesTable
                                        isBooking
                                        transactions={filterdTransactionType}
                                        meta={transactionTypeMeta}
                                    />
                                ) : (
                                    <p>There are no transactions</p>
                                )}
                            </TabPane>

                            <TabPane tabId={2}>
                                {filterdTransactionType.length > 0 ? (
                                    <TransactionServicesTable
                                        transactions={filterdTransactionType}
                                        meta={transactionTypeMeta}
                                    />
                                ) : (
                                    <p>There are no transactions</p>
                                )}
                            </TabPane>

                            <TabPane tabId={3}>
                                {filterdTransactionType.length > 0 ? (
                                    <TransactionServicesTable
                                        transactions={filterdTransactionType}
                                        meta={transactionTypeMeta}
                                        refreshFundsServices={refreshFundsServices}
                                    />
                                ) : (
                                    <p>There are no transactions</p>
                                )}
                            </TabPane>
                        </TabContent>
                    </AppLayout.TabBody>
                </AppLayout.Body>
            </AppLayout.Wrapper>
        </AuthLayout>
    );
};
