import { FeedbackData } from '../../services/feedback.service';
import { FETCH_FEEDBACKS_REQUEST, FETCH_FEEDBACKS_SUCCESS, FETCH_FEEDBACKS_FAILURE } from './feedback.actions';

export interface FeedbackState {
    feedbacks: FeedbackData[];
    loading: boolean;
    error: any;
}

const initialState: FeedbackState = {
    feedbacks: [],
    loading: false,
    error: null,
};

export const feedbackReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case FETCH_FEEDBACKS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case FETCH_FEEDBACKS_SUCCESS:
            return {
                ...state,
                loading: false,
                feedbacks: action.payload,
            };
        case FETCH_FEEDBACKS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};
