import { Formik } from 'formik';
import { find, last } from 'lodash';
import React, { useState } from 'react';
import { Check, Info, X } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { Cell } from 'react-table';
import { Col, Row } from 'reactstrap';
import styled from 'styled-components';
import Select from 'react-select';

import { CustomDropdown, CustomDropdownItem, CustomPrimaryButton, DataTable } from '../../../../components';
import { FormTextInput, PresentationalFormRadio } from '../../../../components/form';
import { AuthContext } from '../../../../context';
import { AssigneProtocolModel, UpdateBookingModel } from '../../../../models/request';
import { AuthenticatedUser, Booking, Transaction } from '../../../../models/response';
import { Passenger } from '../../../../models/response/bookings/passenger';
import { AirportsThunk, ApplicationState, UsersThunk } from '../../../../store';
import { BookingsThunk } from '../../../../store/bookings/bookings.thunk';
import {
    BookingStatusAdminAvailableState,
    BookingStatuses,
    BookingStatusesMap,
    Roles,
} from '../../../../util/constants';
import { canCancelBooking, formatCurrency } from '../../../../util/misc';
import { formatDate, formatTime } from '../../../../util/misc/formatters';
import { validateFormValues } from '../../../../util/validation';
import { useParams } from 'react-router';

const StyleWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;

    .detail-row {
        display: grid;
        grid-template-columns: 200px 1fr;
        padding: 20px 0;
        font-size: 14px;
        border-bottom: 2px solid #eaeaea;
    }
`;

export const HeaderStyleWrapper = styled.div`
    display: flex;
    flex-direction: row;

    .left__header {
        flex: 1;
        display: flex;
        flex-direction: row;
    }

    .right {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
`;

interface DetailRow {
    property: string;
    value: string | number | [];
}

interface Props {
    booking: Booking;
    transaction?: Transaction;
}

interface Options {
    value: string;
    label: string;
}

export const ViewPorthacourtBookingInformationComponent: React.FC<Props> = ({ booking, transaction }) => {
    const { isInAirport, user } = React.useContext(AuthContext.Context);
    const airports = useSelector((state: ApplicationState) => state.airports);
    const { allProtocols } = useSelector((state: ApplicationState) => state.users);
    const dispatch = useDispatch();

    const id = booking.id ?? useParams<{ booking_id: string }>().booking_id;

    const [values, setValues] = useState([]);

    const options: Options[] = [];

    allProtocols.forEach((protocol) =>
        options.push({ value: protocol.id, label: protocol.firstName + ' ' + protocol.lastName })
    );

    const bookingProtocols: string[] = [];

    booking.bookingProtocols.forEach((user: any) =>
        bookingProtocols.push(user.applicationUser.firstName + ' ' + user.applicationUser.lastName)
    );

    console.log('Bookings', booking);
    console.log('Transaction', transaction);

    // const travelDateIsPassedOrEqualToCurrentDate = (targetDate: Date) => {
    //     return new Date().getTime() <= new Date(targetDate).getTime();
    // };

    const canCancel = React.useMemo(() => {
        return canCancelBooking(booking, user as AuthenticatedUser);
        // eslint-disable-next-line
    }, [booking, booking.bookingStatus, user]);

    const canPay = React.useMemo(
        () =>
            !transaction?.paymentVerified &&
            user?.role === Roles.Client &&
            booking.bookingStatus !== BookingStatuses.Canceled,
        [transaction, booking, user]
    );

    const handleSetStatus = React.useCallback(
        async (status: BookingStatuses) => {
            if (booking) {
                if (booking.bookingStatus !== status) {
                    booking.bookingStatus = status;
                    await dispatch(BookingsThunk.update(new UpdateBookingModel(booking)));
                    if (id) await dispatch(BookingsThunk.retrieve(+id));
                }
            }
        },
        [dispatch, booking]
    );

    const handleAssignProtocol = async () => {
        const updateBooking: any = {
            id: booking.id,
            bookingStatus: booking.bookingStatus,
            protocols: [],
        };

        Object.keys(values).forEach((key: any) => {
            updateBooking.protocols.push(values[key]['value']);
        });

        await dispatch(BookingsThunk.update(new UpdateBookingModel(updateBooking)));
        if (id) await dispatch(BookingsThunk.retrieve(+id));
    };

    const fetchAllProtocols = React.useCallback(() => {
        dispatch(UsersThunk.getAllProtocols());
    }, [dispatch]);

    React.useEffect(() => {
        fetchAllProtocols();
        // dispatch(AirportsThunk.list());
    }, [dispatch, fetchAllProtocols]);

    const details: DetailRow[] = React.useMemo(
        () => [
            { property: 'Airport ', value: find(airports.airports, { id: booking.airportId })?.name ?? 'N/A' },
            { property: 'Booking date ', value: formatDate(booking.dateCreated) },
            { property: 'Additional ODC', value: booking?.additionalOdc },
            { property: 'Default ODC', value: booking?.service?.defaultOdc },
            {
                property: 'Total number of ODC',
                value: (booking?.additionalOdc ?? 0) + (booking?.service?.defaultOdc ?? 0),
            },
            {
                property: 'Flight time porthacourt',
                value: 'N/A',
            },

            {
                property: 'Updated By',
                value: booking.modifiedBy_FirstName ?? '',
            },

            {
                property: 'Booked By',
                value: booking.bookedBy_FirstName ?? '',
            },

            {
                property: 'Status',
                value: BookingStatusesMap[booking.bookingStatus]
                    .replace('Canceled', 'Cancelled')
                    .replace('Flight_Canceled', 'Flight_Cancelled'),
            },
            {
                property: 'Protocol`(s)',
                value: [...(new Set(bookingProtocols) as any)].join(', '),
            },
        ],
        [booking, airports.airports]
    );

    return (
        <StyleWrapper>
            <Row>
                <Col size={12} xl={5}>
                    {details.map(({ property, value }, i) => {
                        return (
                            <div className="detail-row" key={i}>
                                <b>{property}</b>
                                <span>{value}</span>
                            </div>
                        );
                    })}
                </Col>
                <Col size={12} xl={7} className="d-flex flex-column align-items-end mt-5 mt-xl-0">
                    {/* <DataTable
                        columns={[
                            {
                                Header: 'First name',
                                accessor: 'firstName',
                                Cell: ({ row: { original } }: Cell<Passenger>) => {
                                return <span style={{ color: 'var(--primary-color)' }}>{original.firstName ?? 'N/A'}</span>;
                  },
                               
                            },
                            {
                                Header: 'Last name',
                                accessor: 'lastName',
                                Cell: ({ row: { original } }: Cell<Passenger>) => {
                                return <span style={{ color: 'var(--primary-color)' }}>{original.lastName || 'N/A'}</span>;
                            },
                            },
                                {
                                Header: 'Designation',
                                accessor: 'designation',
                            },
                            {
                                Header: 'Airline',
                                accessor: 'airline',
                            },
                            {
                                Header: 'Wheel chair',
                                accessor: 'wheel_Chair',
                                Cell: ({ row: { original: passenger } }: Cell<Passenger>) =>
                                    passenger.wheel_Chair ? (
                                        <Check size={14} color="var(--primary-color)" strokeWidth={2} />
                                    ) : (
                                        <X size={14} strokeWidth={2} />
                                    ),
                            },
                            {
                                Header: 'Special requirements',
                                accessor: 'special_Requirement',
                                Cell: ({ row: { original: passenger } }: Cell<Passenger>) => {
                                    const id = `passenger${passenger.id}`;

                                    return (
                                        <div id={id} title={passenger.special_Requirement ?? ''}>
                                            {passenger.special_Requirement ? (
                                                <Info size={14} strokeWidth={2} />
                                            ) : (
                                                <X size={14} strokeWidth={2} />
                                            )}
                                        </div>
                                    );
                                },
                            },
                            {
                                Header: 'Additional services',
                                accessor: 'additionalService',
                                Cell: ({ row: { original: passenger } }: Cell<Passenger>) =>
                                    passenger?.additionalService?.name ?? '',
                            },
                        ]}
                        data={booking.passengers || []}
                    /> */}
                    <div className="d-flex mt-4 justify-content-end">
                        <div className="mt-3">
                            <b className="mr-3">Total price</b>
                            <b>{formatCurrency(booking.price)}</b>
                        </div>
                    </div>
                </Col>
            </Row>
            {booking.bookingStatus === BookingStatuses.Processing &&
                ![Roles.Accountant, Roles.Auditor, Roles.Commercial].includes(user?.role as any) && (
                    <div style={{ width: '40%' }} className="d-flex mt-4 justify-content-between">
                        <div>Select protocol</div>
                        <div>
                            <Select
                                closeMenuOnSelect={false}
                                isMulti
                                options={options}
                                onChange={(values: any) => {
                                    setValues(values);
                                }}
                            />
                        </div>
                    </div>
                )}
            <div className="d-flex mt-4">
                {canCancel && (
                    <CustomPrimaryButton onClick={handleSetStatus.bind(undefined, BookingStatuses.Canceled)}>
                        <span>Cancel</span>
                    </CustomPrimaryButton>
                )}
                {/* {canPay && (
                    <CustomPrimaryButton onClick={makePayment} target="_blank" disabled={!transaction}>
                        <span>Pay now</span>
                    </CustomPrimaryButton>
                )} */}
                {BookingStatusAdminAvailableState[booking.bookingStatus].length > 0 &&
                    isInAirport(booking.airportId, 'manage') &&
                    (!!booking.bookingProtocols.length ? (
                        <CustomDropdown
                            className="mr-2"
                            triggerComponent={() => (
                                <CustomPrimaryButton type="submit">
                                    <span>Change status</span>
                                </CustomPrimaryButton>
                            )}
                        >
                            <CustomDropdownItem className="justify-content-start">
                                <PresentationalFormRadio checked />
                                <span className="ml-2">{booking.bookingStatus.replace('_', ' ')}</span>
                            </CustomDropdownItem>
                            {BookingStatusAdminAvailableState[booking.bookingStatus].map((status, i) => (
                                <CustomDropdownItem
                                    className="justify-content-start"
                                    onClick={handleSetStatus.bind(undefined, status)}
                                    key={i}
                                >
                                    <PresentationalFormRadio checked={false} />
                                    <span className="ml-2">{status.replace('_', ' ')}</span>
                                </CustomDropdownItem>
                            ))}
                        </CustomDropdown>
                    ) : null)}
                {booking.bookingStatus === BookingStatuses.Processing &&
                    ![Roles.Accountant, Roles.Auditor, Roles.Commercial].includes(user?.role as any) && (
                        <div>
                            <CustomPrimaryButton
                                disabled={!values.length ? true : false}
                                className="inverted"
                                onClick={handleAssignProtocol.bind(undefined)}
                            >
                                <span>ASSIGN PROTOCOL</span>
                            </CustomPrimaryButton>
                        </div>
                    )}
            </div>
        </StyleWrapper>
    );
};
