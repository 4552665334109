import React from 'react';
import ReactDOM from 'react-dom';
import { Field, Formik } from 'formik';

import { AuthLayout, Breadcrumb, TopBar } from '../../../components';
import { ChartsTable, RevenueCard } from './components';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState, ReportinThunk } from '../../../store';
import { setSearch } from '../../../util/history';
import { Button } from '../../../components/form';
import styled from 'styled-components';

const HeaderStyleWrapper = styled.div`
    > form {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        gap: 20px;
        padding: 0;

        .input {
            border-radius: 10px;
            border-width: 1px;
        }

        label {
            display: flex;
            font-weight: 500;
            flex-direction: column;
            font-weight: 700;
        }
    }
`;

export const Reporting = () => {
    const breadcrumbs: Breadcrumb[] = [
        { name: 'Dashboard', path: '/app/dashboard' },
        { name: 'Reporting', path: window.location.href },
    ];

    const [date, setDate] = React.useState<{ startDate: string; endDate: string }>({} as any);

    const { reportings } = useSelector((state: ApplicationState) => state.reportings);
    const dispatch = useDispatch();

    const handleGetReportingStats = React.useCallback(() => {
        dispatch(ReportinThunk.getReportings());
    }, [dispatch]);

    React.useEffect(() => {
        handleGetReportingStats();
    }, [handleGetReportingStats]);

    const handleFilter = async (filter: { startDate: string; endDate: string }) => {
        // const start = new Date(filter.startDate).getDate();
        // const end = new Date(filter.endDate).getDate();
        // const date = end - start;
        setDate(filter);
        await dispatch(ReportinThunk.getReportings(filter));
    };

    return (
        <AuthLayout>
            <div style={{ height: '100vh' }}>
                <TopBar breadcrumbs={breadcrumbs} />
                <Formik
                    initialValues={{ startDate: '', endDate: '' }}
                    onSubmit={async (values) => {
                        ReactDOM.unstable_batchedUpdates(() => {
                            console.log('DATA', values);
                            // setSearch('page', 1);
                            handleFilter({ startDate: values.startDate, endDate: values.endDate });
                        });
                    }}
                >
                    {({ handleSubmit, values }) => {
                        return (
                            <HeaderStyleWrapper className="mb-3">
                                <form onSubmit={handleSubmit}>
                                    <h4>Filter By Date:</h4>
                                    <label className="label">
                                        Start Date:
                                        <Field className="input" type="date" name="startDate" />
                                    </label>
                                    <label className="label">
                                        End Date:
                                        <Field className="input" type="date" name="endDate" />
                                    </label>
                                    <Button
                                        style={{
                                            border: '1px solid currentColor',
                                            borderRadius: '5px',
                                            marginBottom: 8,
                                        }}
                                        disabled={!values.endDate.length || !values.startDate.length}
                                        type="submit"
                                    >
                                        Filter
                                    </Button>
                                </form>
                            </HeaderStyleWrapper>
                        );
                    }}
                </Formik>
                <div className="row justify-content-sm-center">
                    <div className="col-md-3 col-sm-6 ">
                        <RevenueCard amount={reportings?.totalRevenue} title="Total Revenue" money={1} />
                    </div>
                    <div className="col-md-3 col-sm-6 ">
                        <RevenueCard amount={reportings?.totalRevenueUsed} title="Revenue Used" money={1} />
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <RevenueCard amount={reportings?.bookings} title="Total Bookings" />
                    </div>
                    <div className="col-md-3 col-sm-6 ">
                        <RevenueCard amount={reportings?.passengers} title="Total Passenger" />
                    </div>
                </div>

                <ChartsTable
                    topClients={reportings.topClients}
                    dateFilter={date}
                    topClientsServiceRendered={reportings.topClientsServiceRendered}
                />
            </div>
        </AuthLayout>
    );
};
