import * as React from 'react';
import { Link } from 'react-router-dom';
import { Collapse, Container, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { AuthContext } from '../../context';
import { CustomButton } from '../index';
import './NavMenu.scss';

export default class NavMenu extends React.PureComponent<{}, { isOpen: boolean }> {
    public state = {
        isOpen: false,
    };

    public render() {
        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm mb-3" light>
                    <Container fluid={true} style={{ marginRight: '5%', marginLeft: '5%' }}>
                        <NavbarBrand className="mr-lg-5" to="/" tag={Link}>
                            <img alt="..." width={90} src={require('../../assets/images/logo_faan.png')} />
                        </NavbarBrand>
                        <NavbarToggler onClick={this.toggle} className="mr-2" />
                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={this.state.isOpen} navbar>
                            <Nav className="align-items-lg-center ml-lg-auto" navbar>
                                <AuthContext.Consumer>
                                    {({ user }) =>
                                        user ? (
                                            <React.Fragment>
                                                <NavItem>
                                                    <NavLink tag={Link} to="/app/dashboard">
                                                        Dashboard
                                                    </NavLink>
                                                </NavItem>
                                            </React.Fragment>
                                        ) : null
                                    }
                                </AuthContext.Consumer>
                            </Nav>
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
        );
    }

    private toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen,
        });
    };
}
