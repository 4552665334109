import { Formik } from 'formik';
import React from 'react';
import { Plus } from 'react-feather';
import { useSelector } from 'react-redux';
import { Col, ModalBody, ModalFooter } from 'reactstrap';
import { CustomPrimaryButton } from '../../../../components';
import { FormTextInput } from '../../../../components/form';
import { CustomModal } from '../../../../components/utilities/modal/modal';
import { CreateUserModel } from '../../../../models/request';
import { ApplicationState } from '../../../../store';
import { CreateUserRolesMap } from '../../../../util/constants';
import { validateFormValues } from '../../../../util/validation';

interface CreateUserModalProps {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    handleCreateUser: (userDetails: CreateUserModel) => void;
}

export const CreateUserModal: React.FC<CreateUserModalProps> = ({ isOpen, setIsOpen, handleCreateUser }) => {
    const { airports } = useSelector((state: ApplicationState) => state.airports);

    return (
        <CustomModal isOpen={isOpen} setIsOpen={setIsOpen} title="Create User">
            <Formik validate={validateFormValues} initialValues={new CreateUserModel()} onSubmit={handleCreateUser}>
                {({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <ModalBody>
                            <div className="form-row">
                                <div className="col-sm-6">
                                    <FormTextInput name="Firstname" label="First name " compact />
                                </div>
                                <div className="col-sm-6">
                                    <span style={{ fontSize: 14 }}>Middle Name</span>
                                    <FormTextInput name="MiddleName" compact />
                                </div>
                            </div>
                            <div className="form-row">
                                <Col sm="12">
                                    <FormTextInput name="LastName" label="Last name " compact />
                                </Col>
                            </div>
                            <div className="form-row">
                                <Col sm="12">
                                    <FormTextInput name="PhoneNumber" label="Phone Number " compact />
                                </Col>
                            </div>
                            <div className="form-row">
                                <Col sm="12">
                                    <FormTextInput name="Email" label="Email " type="email" compact />
                                </Col>
                            </div>
                            <div className="form-row">
                                <Col sm="6">
                                    <FormTextInput name="Role" label="Role" as="select" compact>
                                        <option value="">SELECT A ROLE </option>
                                        {Object.entries(CreateUserRolesMap).map(([role, value], i) => (
                                            <option value={role} key={i} disabled={!role}>
                                                {value}
                                            </option>
                                        ))}
                                    </FormTextInput>
                                </Col>
                                <Col sm="6">
                                    <FormTextInput name="AirportId" label="Airport" as="select" compact>
                                        <option value="">SELECT AN AIRPORT </option>
                                        {airports.map(({ id, name }) => (
                                            <option value={id} key={id}>
                                                {name}
                                            </option>
                                        ))}
                                    </FormTextInput>
                                </Col>
                            </div>
                        </ModalBody>
                        <ModalFooter className="d-flex justify-content-between align-items-center">
                            <CustomPrimaryButton type="submit">
                                <Plus size={14} strokeWidth={3} />
                                <span>Create user</span>
                            </CustomPrimaryButton>
                            <button
                                className="modal-footer__button"
                                type="button"
                                onClick={setIsOpen.bind(undefined, false)}
                            >
                                <span>Close</span>
                            </button>
                        </ModalFooter>
                    </form>
                )}
            </Formik>
        </CustomModal>
    );
};
