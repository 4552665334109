import { Formik } from 'formik';
import { isMatch } from 'lodash';
import React from 'react';
import { Plus } from 'react-feather';
import { useDispatch } from 'react-redux';
import { Col, ModalBody, ModalFooter } from 'reactstrap';
import { CustomPrimaryButton } from '../../../../components';
import { FormTextInput } from '../../../../components/form';
import { CustomModal } from '../../../../components/utilities/modal/modal';
import { UpdateServiceModel } from '../../../../models/request';
import { Service } from '../../../../models/response';
import { NotificationsThunk } from '../../../../store/notifications/notifications.thunk';
import { ServiceThunk } from '../../../../store/services/services.thunk';
import { ClientTypesMap, NotificationTypes } from '../../../../util/constants';
import { validateFormValues } from '../../../../util/validation';
import { FormCheckBox } from '../../../../components/form/form-check-box';

interface UpdateServiceModalProps {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    service: Service;
}

export const UpdateServiceModal: React.FC<UpdateServiceModalProps> = ({ isOpen, setIsOpen, service }) => {
    const dispatch = useDispatch();

    const handleUpdateService = React.useCallback(
        (serviceDetails: UpdateServiceModel) => {
            dispatch(ServiceThunk.updateService(serviceDetails, setIsOpen.bind(undefined, false)));
        },
        [dispatch, setIsOpen]
    );

    return (
        <CustomModal isOpen={isOpen} setIsOpen={setIsOpen} title="Update Service">
            <Formik
                validate={validateFormValues}
                enableReinitialize
                initialValues={new UpdateServiceModel(service)}
                onSubmit={(serviceDetails) => {
                    if (isMatch(new UpdateServiceModel(service), serviceDetails)) {
                        dispatch(NotificationsThunk.create(NotificationTypes.Info, 'No changes were made'));
                        return setIsOpen(false);
                    }

                    handleUpdateService(serviceDetails);
                }}
            >
                {({ handleSubmit, values }) => (
                    <form onSubmit={handleSubmit}>
                        <ModalBody>
                            <div className="form-row">
                                <div className="col-sm-6">
                                    <FormTextInput name="Name" label="Service name" compact />
                                </div>
                                <div className="col-6">
                                    <FormTextInput name="Price" label="Service price" type="number" compact />
                                </div>
                            </div>
                            <div className="form-row">
                                <Col sm="12">
                                    <FormTextInput name="Description" label="Description" as="textarea" compact />
                                </Col>
                            </div>
                            <div className="form-row">
                                <Col sm={6}>
                                    <FormTextInput name="ServiceTypeId" label="Service type" as="select" compact>
                                        <option value="" disabled>
                                            Service Type
                                        </option>
                                        {Object.entries(ClientTypesMap).map(([value, name]) => (
                                            <option value={value} key={value}>
                                                {name}
                                            </option>
                                        ))}
                                    </FormTextInput>
                                </Col>

                                <Col sm={6} className="d-flex align-items-center">
                                    <FormCheckBox className="form-checkbox__body" name="portharcourtService" />
                                    <div className="ml-2">
                                        <label className="form-check-label mb-0" htmlFor="CheckboxVariable">
                                            Porthacourt Annual Service
                                        </label>
                                    </div>
                                </Col>
                                {values.portharcourtService && (
                                    <div className="form-row">
                                        <Col sm="12">
                                            <FormTextInput
                                                name="defaultOdc"
                                                label="Default ODC"
                                                type="number"
                                                compact
                                            />
                                        </Col>
                                    </div>
                                )}
                            </div>
                        </ModalBody>
                        <ModalFooter className="d-flex justify-content-between align-items-center">
                            <CustomPrimaryButton type="submit">
                                <Plus size={14} strokeWidth={3} />
                                <span>Update service</span>
                            </CustomPrimaryButton>
                            <button
                                className="modal-footer__button"
                                type="button"
                                onClick={setIsOpen.bind(undefined, false)}
                            >
                                <span>Close</span>
                            </button>
                        </ModalFooter>
                    </form>
                )}
            </Formik>
        </CustomModal>
    );
};
