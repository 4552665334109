import queryString from 'query-string';
import React from 'react';
import { saveAs } from 'file-saver';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Col, TabContent, TabPane } from 'reactstrap';
import { AppLayout, AuthLayout, TabLink, TopBar } from '../../../components';
import { Breadcrumb } from '../../../components/breadcrumbs/breadcrumbs';
import { ChangeUserStatusModel, UpdateClientModel } from '../../../models/request';
import { User } from '../../../models/response';
import { ApplicationState, NotificationsThunk } from '../../../store';
import { UsersThunk } from '../../../store/users/users.thunk';
import { ClientStatuses, NotificationTypes } from '../../../util/constants';
import { ClientsTable } from './clients-table';
import { UpdateClientModal } from './modals/update-client-modal';
import { ApiClient } from '../../../util/api-client';

export const AllClientPage: React.FC = () => {
    const breadcrumbs: Breadcrumb[] = [
        { name: 'Dashboard', path: '/app/dashboard' },
        { name: 'All Clients', path: window.location.href },
    ];

    const [activeClient, setActiveClient] = React.useState<User>();
    const [updateModalOpen, setUpdateModalOpen] = React.useState(false);
    const [searchTerm, setSearchTem] = React.useState('');
    const { search } = useLocation();

    const links: TabLink[] = [
        { index: 1, name: 'All Clients', query: [] },
        { index: 2, name: 'Processing', query: ['filter', 'Processing'] },
        { index: 3, name: 'Active', query: ['filter', 'Active'] },
        { index: 4, name: 'New', query: ['filter', 'New'] },
        { index: 5, name: 'Deactivated', query: ['filter', 'Deactivated'] },
    ];

    const [activeTab, setActiveTab] = React.useState(1);
    const dispatch = useDispatch();
    const { clients, clientsMeta, clientStats } = useSelector((state: ApplicationState) => state.users);

    const handleUpdateClient = React.useCallback(
        (userDetails: UpdateClientModel) => {
            const type = activeClient!.organization_CategoryId;
            dispatch(UsersThunk.updateClient(userDetails, type, setUpdateModalOpen.bind(undefined, false)));
        },
        [dispatch, activeClient]
    );

    const pageNumber = React.useMemo(() => +(queryString.parse(search) as any).page || 1, [search]);
    const filter: ClientStatuses | undefined = React.useMemo(() => (queryString.parse(search) as any).filter, [search]);

    const fetchClients = React.useCallback(() => {
        dispatch(UsersThunk.getClients(filter, pageNumber, searchTerm));
    }, [dispatch, pageNumber, filter]);

    const handleSetStatus = React.useCallback(
        (client: User, status: boolean) => {
            if (client.account_Status !== status) {
                client.account_Status = status;
                dispatch(
                    UsersThunk.changeUserStatus(new ChangeUserStatusModel(client), () => {
                        fetchClients();
                    })
                );
            }
        },
        [dispatch, fetchClients]
    );

    React.useEffect(() => {
        fetchClients();
        dispatch(UsersThunk.getClientStats());

        switch (filter) {
            case ClientStatuses.Processing:
                setActiveTab(2);
                break;
            case ClientStatuses.Active:
                setActiveTab(3);
                break;
            case ClientStatuses.New:
                setActiveTab(4);
                break;
            case ClientStatuses.Deactivated:
                setActiveTab(5);
                break;
            default:
                setActiveTab(1);
        }
    }, [dispatch, filter, fetchClients]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTem(e.target.value);
    };

    const handleSearch = () => {
        dispatch(UsersThunk.getClients(filter, pageNumber, searchTerm));
    };

    return (
        <AuthLayout showSidebar={false}>
            <TopBar breadcrumbs={breadcrumbs} />
            {activeClient && (
                <UpdateClientModal
                    isOpen={updateModalOpen}
                    setIsOpen={setUpdateModalOpen}
                    client={activeClient}
                    handleUpdateClient={handleUpdateClient}
                />
            )}
            <AppLayout.Wrapper>
                <AppLayout.Header>
                    <Col lg={8}>
                        <h3 className="header__title">All FAAN APS Clients</h3>
                        <p>{clientStats?.total ?? 0} total Clients</p>
                    </Col>
                    <Col className="d-flex justify-content-end"></Col>
                </AppLayout.Header>
                <AppLayout.Body>
                    <AppLayout.TabHeader
                        links={links}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        handleChange={handleChange}
                        value={searchTerm}
                        handleSearch={handleSearch}
                        searchable
                        isSearchNotOnChange
                    />
                    <AppLayout.TabBody>
                        <TabContent activeTab={activeTab} className="tab__content">
                            <TabPane tabId={1}>
                                {clients.length ? (
                                    <ClientsTable
                                        clients={clients}
                                        meta={clientsMeta}
                                        setActiveClient={setActiveClient}
                                        handleSetStatus={handleSetStatus}
                                        setUpdateModalOpen={setUpdateModalOpen}
                                    />
                                ) : (
                                    <p>There are no clients</p>
                                )}
                            </TabPane>
                            <TabPane tabId={2}>
                                {clients.length ? (
                                    <ClientsTable
                                        clients={clients}
                                        meta={clientsMeta}
                                        setActiveClient={setActiveClient}
                                        handleSetStatus={handleSetStatus}
                                        setUpdateModalOpen={setUpdateModalOpen}
                                    />
                                ) : (
                                    <p>There are no processing clients</p>
                                )}
                            </TabPane>
                            <TabPane tabId={3}>
                                {clients.length ? (
                                    <ClientsTable
                                        clients={clients}
                                        meta={clientsMeta}
                                        setActiveClient={setActiveClient}
                                        handleSetStatus={handleSetStatus}
                                        setUpdateModalOpen={setUpdateModalOpen}
                                    />
                                ) : (
                                    <p>There are no active clients</p>
                                )}
                            </TabPane>
                            <TabPane tabId={4}>
                                {clients.length ? (
                                    <ClientsTable
                                        clients={clients}
                                        meta={clientsMeta}
                                        setActiveClient={setActiveClient}
                                        handleSetStatus={handleSetStatus}
                                        setUpdateModalOpen={setUpdateModalOpen}
                                    />
                                ) : (
                                    <p>There are no new clients</p>
                                )}
                            </TabPane>
                            <TabPane tabId={5}>
                                {clients.length ? (
                                    <ClientsTable
                                        clients={clients}
                                        meta={clientsMeta}
                                        setActiveClient={setActiveClient}
                                        handleSetStatus={handleSetStatus}
                                        setUpdateModalOpen={setUpdateModalOpen}
                                    />
                                ) : (
                                    <p>There are no deactivated clients</p>
                                )}
                            </TabPane>
                        </TabContent>
                    </AppLayout.TabBody>
                </AppLayout.Body>
            </AppLayout.Wrapper>
        </AuthLayout>
    );
};
