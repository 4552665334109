import React from 'react';
import { useSelector } from 'react-redux';
import { AuthenticatedUser } from '../models/response';
import { ApplicationState } from '../store';
import { ClientTypes, Roles } from '../util/constants';

type AccessTypes = 'view' | 'manage';

interface AuthContextState {
    user?: AuthenticatedUser;
    canAccess: (role: Roles | Roles[], strict?: boolean) => boolean;
    isInAirport: (airportId: number, access: AccessTypes) => boolean;
    isClientOfType: (clientType: ClientTypes) => boolean;
}

const initialState: AuthContextState = {
    canAccess: () => false,
    isInAirport: () => false,
    isClientOfType: () => false,
};

const Context = React.createContext<AuthContextState>(initialState);

const Provider: React.FC = ({ children }) => {
    const { authUser: user } = useSelector((state: ApplicationState) => state.auth);

    const returnAccessLevel = React.useCallback(
        (role: Roles, strict?: boolean) => {
            if (strict) {
                // In case you need an exact match
                return user?.role === role;
            }

            switch (user?.role) {
                case Roles.Admin:
                    return true;
                case Roles.Client:
                    return role === Roles.Client;
                case Roles.AirportProtocolCoordinator:
                    return role === Roles.AirportProtocolCoordinator;
                case Roles.AirportManager:
                    return role === Roles.AirportManager;
                case Roles.Accountant:
                case Roles.Auditor:
                case Roles.Commercial:
                    return role === Roles.Accountant || role === Roles.Auditor || role === Roles.Commercial;
                default:
                    return false;
            }
        },
        [user]
    );

    const canAccess = React.useCallback(
        (role: Roles | Roles[], strict?: boolean) => {
            if (!user) {
                return false;
            }

            if (Array.isArray(role)) {
                return role.some((_role) => returnAccessLevel(_role, strict));
            }

            return returnAccessLevel(role, strict);
        },
        [user, returnAccessLevel]
    );

    const isClientOfType = React.useCallback(
        (clientType: ClientTypes) => {
            return (
                canAccess(Roles.Admin) ||
                (canAccess(Roles.Client) && user?.clientTypeId === ClientTypes.International) ||
                user?.clientTypeId === clientType
            );
        },
        [user, canAccess]
    );

    // Airport managers can view resources whether or not they are from
    // their airports but cannot manage them
    // Airport protocol coordinators can see and manage resources in their
    // airports ONLY
    const isInAirport = React.useCallback(
        (airportId: number, access: AccessTypes = 'view') => {
            switch (user?.role) {
                case Roles.Admin:
                    return true;
                case Roles.AirportProtocolCoordinator:
                    return user.airportId === airportId;
                case Roles.AirportManager: {
                    if (access === 'manage') return false;
                    return true;
                }
                case Roles.Accountant:
                case Roles.Auditor:
                case Roles.Commercial:
                    return true;
                default:
                    return false;
            }
        },
        [user]
    );

    const contextState = React.useMemo(
        () => ({ user, canAccess, isClientOfType, isInAirport }),
        [user, canAccess, isClientOfType, isInAirport]
    );
    return <Context.Provider value={contextState}>{children}</Context.Provider>;
};

export const AuthContext = {
    Context,
    Provider,
    Consumer: Context.Consumer,
};
